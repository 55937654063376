/* Toolbar area */

.title-area{
  margin-left: 1.6rem !important;

  .logo {
    width: rem-calc(520);
    height: 100%;
    display: inline-block;
    background: url(/assets/logo.png) right center no-repeat;
    background-size: auto 100%;
    position: absolute;
    top: 1;
    left: rem-calc(-370);
  }
}

header.sticky {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 12;

  -webkit-transition: background-color .2s ease-in-out;
  transition: background-color .2s ease-in-out;

  border-bottom: $topbar-border-style;

  &.darkened {
    background: $topbar-bg-color_darkened;
  }
}

@media  #{$xsmall-only}, #{$small-only} {
  .top-bar-section li:not(.has-form) a:not(.button) {
    padding: 0 10px;
  }
}

.top-bar-section li.disabled a:hover {
  background-color: $grey !important;
}

.top-bar-section .has-dropdown > a {
  padding-right: 1.152rem !important;

  &:after {
    border: none !important;
  }
}

.top-bar-section {
  .offline i {
    color: $red;
    font-weight: bold;
  }
}

@media #{$large-up} {
  .title-area{
    margin-left: 0rem !important;
  }
}

@media #{$screen} and (max-width:#{upper-bound($medium-range)}) {
  .top-bar {
    margin: 0 auto 0 32px !important;
  }

  .topmenuelist{
    display: none;
  }

  header.sticky {
    background: $topbar-bg-color_sticky !important;
  }


}

.tab-bar {
  .menu-icon {
    text-align: center;
    text-indent: 0;
  }

  .font-icon {
    font-size: rem-calc(20);
  }
}

.top-bar {
  background: none;
	padding: 0px 5px;
	height: auto;

	.button {
		top: 3px;
		font-size: 16px;
		margin-left: 5px;
	}

  section > ul > li:not(.blank) {
    position: relative;

    &.has-dropdown {
      &:hover:before {
        content: "";
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 3px 4px 3px;
        position: absolute;
        bottom: 0;
        left: 50%;
        margin-left: -2px;
        border-color: transparent transparent #ffffff transparent;
      }
    }

    .dropdown {
      border-radius: 3px;
      overflow: hidden !important;
      // right: -50%;
      box-shadow: 0px 1px 6px -1px rgba(0, 0, 0, 0.4);

      a {
        line-height: 35px;
        font-size: 13px;
        color: $secondary;
        &:hover {
          background: $dropdown-link-bg-hover-important-overwrite !important;
        }
      }

      li.disabled > a {
        background-color: $grey !important;
      }

      img {
        max-height: 18px;
        max-width: 18px;
        margin-right: 8px;
      }

      .font-icon {
        color: $icon-color-dark;

        &.icon-euro {
          color: #44B007;
        }
      }
    }

    &:hover {
      &.submenue:before {
        content: '';
        position: absolute;
        bottom: -1px;
        width: 100%;
        border-bottom: 1px solid #004262;
      }

      .overlay-menue {
        display: block;
        border-bottom: 1px solid #002a3f;
      }
    }
  }

  .font-icon {
    font-size: rem-calc(20);
  }
  .account-menu {
    .font-icon.account {
      font-size: rem-calc(10);
    }
  }
}

.aside-toggle {
  z-index: 2;
}

ul.off-canvas-list {
  padding: 64px 0px 0px;

  a {
    font-size: 15px;
  }

  li {
    border-bottom: 1px solid #09435A;
  }

  .font-icon {
    font-size: rem-calc(24);
    margin-right: rem-calc(18);
  }
}

.tab-bar,
.top-bar,
.off-canvas-list {
  .font-icon {
    color: $icon-color-menu;
  }
}
