.cockpit_steuerfrei-wrapper {

  .component-col {
    label {
      display: inline-block;
    }
    a {
      float: right;
      line-height: 2.5rem;
    }
  }
  .margin-top {
    margin-top: 16px;
  }
}

.cockpit_steuerfrei-dialog {

  .component-col {
    > .double-label-switch,
    > label {
      display: inline-block;
    }
  }
}

.reg-btn-container > button{
  margin-left: .5rem;

  &:first-child{
    margin-left: 0;
  }
}
input#customer-code{
  height: 2.8rem;
}
// QR modal dialog
.qr-code-container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .ngdialog-close {
    position: absolute;
    right: 1rem;
    font-size: 1.5rem;
  }

  .qr-code-header{
    width: 100%;
    height: 2.3rem;
    text-align: center;
    font-size: 1.6rem;
    border-bottom: .1rem solid #003b55;
  }
  .qr-code-body{
    width: 100%;
    padding: .8rem;
    border-bottom: .1rem solid #003b55;
    img{
      display: block;
      width: 100%;
      height: 300px;
    }
  }
  .qr-code-footer{
    padding: .3rem;
    height: 3.6rem;

    a.button{
      display: block;
    }
  }
  .ngdialog-content{
    display: flex;
    flex-direction: column;

    z-index: 1;
  }
}
.register-button{
  width: 100%;
}
@media #{$xsmall-only}, #{$small-only} {
  .cockpit_steuerfrei-wrapper {
    .component-col {
      label {
        width: 100%;

        i,
        .component-name {
          vertical-align: top;
        }

        .component-name {
          display: inline-block;
          font-size: 1rem;
          width: 85%;
        }
      }
    }
  }
}
