@mixin cockpit_toggable_content {
  $green: #008700;
  $indent-left: 180;
  $indent-right: 200;

  .list-box {
    @include toggable-content('.list-box__item__content', '.toggable-content-open .list-box__item__content', 1500);
  }

  .add-baustein {
    cursor: pointer;

    span {
      height: rem-calc(28);
      line-height: rem-calc(28);
      display: inline-block;
    }

    .icon-plus {
      color: $hellblau;
      font-size: 125%;
      vertical-align: middle;
      margin-right: rem-calc(10);
    }
  }

  input[type="checkbox"] + label {
    padding-left: 40px;
    line-height: rem-calc(16);
    margin-top: rem-calc(-1);
  }

  input[type="checkbox"] + label:before {
    margin-top: rem-calc(3);
    margin-right: rem-calc(5);
    margin-left: -32px;
    margin-bottom: 0;
  }

  .cockpit_toggable-content {
    .value-container {
      text-align: right;
    }

    .small-input {
      text-align: right;
      padding-left: rem-calc(10);
      padding-right: rem-calc(10);
    }

    &__row {
      padding: rem-calc(6) 0;
    }

    .inner-toggable-open {
      .inner-toggable-content__content {
        max-height: rem-calc(600);
      }
    }

    .inner-toggable-content {
      .small-input {
        width: 50%;
      }
    }

    .summary {
      margin-top: rem-calc(20);
      color: $green;
    }
  }

  .inner-toggable-content {
    $inner-padding-left: 40;
    $inner-padding-right: 10;

    margin-left: rem-calc(- $inner-padding-left);
    margin-right: rem-calc(- $inner-padding-right);

    &__content {
      // because of angular's ng-transclude
      > * > div {
        padding-left: rem-calc($inner-padding-left);
        padding-right: rem-calc($inner-padding-right);
        padding-top: rem-calc(25);
        padding-bottom: rem-calc(25);
        background: $lightgrey3;
      }
    }

    &__toggler {
      > div {
        border-top: 1px solid $lightgrey2;
        text-align: right;
        cursor: pointer;
        color: $link-color;
      }

      .indent-left {
        margin-left: rem-calc($indent-left + $inner-padding-left);
      }

      .indent-right {
        margin-right: rem-calc($indent-right + $inner-padding-right);
      }
    }
  }

  .toggler-mehr {
    display: inline-block;
  }
  .toggler-weniger {
    display: none;
  }

  .inner-toggable-open {
    .inner-toggable-content__toggler {
      color: darken($grey, 3);
    }

    .toggler-mehr {
      display: none;
    }
    .toggler-weniger {
      display: inline-block;
    }
  }

  .toggler-arrow {
    font-size: rem-calc(8);
    margin-left: rem-calc(5);
    margin-right: 0;
    // margin-top: rem-calc(-2);
    vertical-align: middle;
  }

  .padded-row {
    margin: rem-calc(10) 0;
  }

  .indent-left {
    margin-left: rem-calc($indent-left);
  }

  .indent-right {
    margin-right: rem-calc($indent-right);
  }

  .mehr-bausteine {
  }

  .mehr-settings {
    .small-input {
      // width: 40%;
    }
  }

  @include rotating-toggler-arrow('.toggler-arrow', '.inner-toggable-open', 0.6s);
}
