// .product-main,
// .price-box {
//   height: 1px;
//   overflow: hidden;
// }

.detail-wrapper {
  $gutter: 13.1px;
  $rem-gutter: rem-calc($gutter);

  @media #{$medium-up} {
    .left-side {
      padding-right: rem-calc(16px);
    }

    .right-side {
      padding-left: rem-calc(16px);
    }
  }

  .box-title {
    margin-bottom: rem-calc(1px);
  }

  .back-container {
    margin: 0 0 0 rem-calc($gutter);
  }

  .product-main {
    background-color: #ffffff;
    // margin: rem-calc($gutter);
    padding: rem-calc(25px);

    h3 {
      margin: 0;
    }

    .main-image {
      margin-bottom: rem-calc(20px);
    }

    .left-panel {
      text-align: center;
      padding-right: rem-calc(20px);

      .images-container {
        margin-top: $rem-gutter;
        background-color: #ffffff;
        text-align: center;

        img {
          cursor: pointer;
        }
      }
    }

    .right-panel {
      .content {
        margin-top: rem-calc(20px);
      }

      h3.product-title {
        color: $link-color;
        font-size: rem-calc(26px);
        font-weight: $font-weight-bold;
      }

      ul.product-description {
        margin-top: rem-calc(40px);
        li {
          margin-left: rem-calc(5px);
          color: #606060;
        }
      }
    }
  }

  .product-details {
    margin-top: rem-calc(20);
    padding-bottom: rem-calc(15px);

    .product-details-title {
      background-color: rgb(222,231,232);
      padding: rem-calc(10px);
    }

    .product-details-body {
      background-color: #ffffff;
      padding: rem-calc(25px);

      .detail-images {
        .img-container {
          margin-left: rem-calc(50px);
          margin-bottom: rem-calc(20px);
          width: rem-calc(60px);
          height: rem-calc(60px);
          background-size: rem-calc(60px);
          background-position: 0px 0px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }

      .detail-rows {
        // margin: rem-calc(20px);
        @extend .bigger-font;
        border-bottom: rem-calc(1px) solid rgba(213, 213, 213, 0.5);

        .detail-row {

          &:first-child {
            .detail-title {
              border-top: none;
            }

            @media #{$medium-up} {
              .detail-value {
                border-top: none;
              }
            }
          }

          > * {
            border-bottom: rem-calc(1px) solid rgba(187, 187, 187, 0.5);
            border-top: rem-calc(1px) solid rgba(213, 213, 213, 0.5);
            @media #{$retina} {
              border-bottom-color: rgba(187, 187, 187, 1);
              border-top-color: rgba(213, 213, 213, 1);
            }
            line-height: rem-calc(46px);
          }

          .detail-title {

          }

          .detail-value {
            background: $lightgrey3;
            padding: 0;

            @media #{$medium-up} {
              padding: 0 rem-calc(35px);
            }
          }
        }
      }

      .button-container {
        text-align: center;

        button {
          margin: rem-calc(25px) $rem-gutter 0 $rem-gutter;
        }
      }
    }
  }
}
