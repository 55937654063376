%proto-font-icon {
  font-family: 'fonticons';
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  text-align: center;
  speak: none;
  margin-right: rem-calc(4);
  margin-left: rem-calc(4);
  vertical-align: middle;


  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.font-icon {
  @extend %proto-font-icon;
  color: $icon-color-default;
}


.font-icon-alt {
  // @extend %proto-font-icon;
  color: $icon-color-alt;
}

$icons: (
  check: "\e650",
  close: "\e60a",
  plus: "\e634",
  minus: "\e62b",
  calculator: "\e607",
  play: "\e633",
  info: "\e622",
  others: "\e631",
  telephone: "\e63e",
  statistics: "\e63b",
  support: "\e63c",
  euro: "\e618",
  laws: "\e627",
  contact: "\e60f",
  verguetung: "\e645",
  login: "\e629",
  logout: "\e62a",
  cockpit: "\e60b",
  tick: "\e609",
  arrow-left: "\e602",
  arrow-right: "\e60c",
  arrow-up: "\e623",
  arrow-down: "\e603",
  left: "\e62c",
  right: "\e615",
  up: "\e641",
  down: "\e64d",
  download: "\e616",
  upload: "\e642",
  cashback: "\e64a",
  design: "\e64b",
  work-clothes: "\e649",
  company: "\e60d",
  entgelterhoehung: "\e64c",
  advertising: "\e600",
  aid-holidays: "\e601",
  garage: "\e61b",
  auto: "\e604",
  bike: "\e605",
  boat: "\e606",
  coupons: "\e610",
  dailydeals: "\e611",
  dog: "\e614",
  horse: "\e621",
  food: "\e61a",
  garden: "\e61c",
  hobby: "\e61e",
  home: "\e61f",
  homework: "\e620",
  internet: "\e624",
  kindergarden: "\e625",
  location: "\e628",
  lock: "\e902",
  mode: "\e62d",
  module: "\e630",
  olitank: "\e632",
  search: "\e636",
  services: "\e637",
  trips: "\e63f",
  versicherungen: "\e646",
  vorsorge: "\e647",
  shopping: "\e63a",
  sachbezug: "\e635",
  users: "\e644",
  user: "\e643",
  consulent: "\e60e",
  chat: "\e608",
  fitness: "\e619",
  document: "\e613",
  tablet: "\e63d",
  mobile: "\e640",
  laptop: "\e626",
  webinar: "\e648",
  tv: "\e64e",
  creditcard: "\e61d",
  hamburger: "\e64f",
  delete: "\e612",
  notification: "\e62e",
  phone: "\e62f",
  email: "\e617",
  settings: "\e638",
  settings2: "\e639",
  waitwatch: "\e901",
  pending: "\e900",
  lock-solid: "\e903",
  200-_1_: "\e904",
  200: "\e905",
  exit: "\ea14"
  
  
);

@each $icon, $hashVal in $icons {
  .icon-#{$icon}:before {
    content: '#{$hashVal}';
  }
}
