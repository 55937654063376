.expand {
  width: 90% !important;
  margin: 5%;
  display: inline-block;
}

.expandside {
  width: 90% !important;
  margin-right: 5%;
  margin-left: 5%;
  display: inline-block;
}
.main {
  background: rgba(#fafafa, 0.8) !important;
}

.contactbox {
  .avatar {
    margin: 0;

    .picture {
      width: rem-calc(120);
      height: rem-calc(120);
      border-radius: rem-calc(120);
      border: rem-calc(1) solid rgba(0,0,0,.4);
      overflow: hidden;
      display: inline-block;
    }

    .image {
      //max-height: 100%;
      // Reseting inherited styles from _side.scss...
      position: static;
      margin: 0;
      // Reseting inherited styles from _global.scss...
      width: 100%;
    }

    .name {
      font-size: 1.2rem;
      margin: 1rem 0 .6rem;
    }
  }
}


#bonusSide {
  .buttons {
    padding: 15px;
  }

  .deletable {
    li.blue:hover {
      span {
        color: #f00;
      }
    }
  }
}
