.documents-category-wrapper {
  margin-bottom: 20px;
  .category-title {
    border-bottom: 1px solid black;
  }
}


.meineakte-wrapper {
  padding: rem-calc(15px);
  padding-top: rem-calc(25px);

  .toggable-content {
    @media #{$xsmall-only}, #{$small-only} {
      .tabs dd {
        @include kategorie-menu-element;
        float: none;

        > a {
          font-size: inherit;
          // padding: inherit;
          // padding-left: 15px;
        }
      }
    }

    .toggable-content-content {
      @media #{$medium-up} {
        max-height: none;
      }
    }
  }

  .toggable-content-toggler > div {
    background: white;
    @include kategorie-menu-element;
  }

  @media #{$xsmall-only}, #{$small-only} {
    .main .mainWrapper {
      padding: 0;
    }

    .bausteine {
      .main > *:last-child .vn-baustein {
        margin-bottom: 0;
      }

      .vn-baustein {
        padding: 0;
        display: block;
        margin-bottom: rem-calc(10);

        &:last-child {
        }
      }
    }
  }

  .document-row {
    display: block;
    padding: 0 15%;
    line-height: rem-calc(27px);

    .download-image {
      display: inline-block;
      margin-top: rem-calc(-3);
      background-color: $primary;
      padding: rem-calc(5);
      width: rem-calc(38);
      border-radius: $border-radius;
      text-align: center;

      .font-icon {
        color: $icon-color-menu;
      }
    }

    img {
      // float: left;
    }

    .created-date {
      color: $grey;
      margin-left: rem-calc(10px);
      margin-right: rem-calc(10px);
    }

    .title {
    }

    .tag {
      color: #000000;
      font-size: rem-calc(10px);
      margin-left: rem-calc(10px);
      padding: rem-calc(3px);
      border-radius: rem-calc(3px);

      &.extension {
        background-color: rgba(4, 150, 216, 0.16);
      }

      &.size {
        background-color: rgba(210, 221, 227, 0.69);
      }
    }
  }

  .securityLogs {
    margin-top: 4rem;
    hr {
      margin-bottom: 2rem;
    }
  }
}

#meineakte-dokumente {
  .upload {
    min-height: 200px;
    button {
      margin-top: 30px;
    }
  }
}

@mixin mobile-table {
  table {
    td {
      display: block;

      &:first-child {
        padding: rem-calc(15px) 0 rem-calc(5) 0;
        border: 0;
      }

      &:last-child {
        width: 100%;
        border-top: 0;
        border-bottom: 1px solid $border-color-dark;
      }
    }
  }
}

#table-wrapper {
  display: flex;

}
#desc {
  //width: 15em;
  border-collapse: collapse;
  text-align: center;

  & th {
    background-color: whitesmoke;
    padding: 1em;
    text-align: left;
    border: 1px solid black;
    padding: 1em;
  }

  & td {
    border: 1px solid black;
    //padding: 1em;
  }
}

.warning-background {
  background-color: $warning !important;
}

.list-box__item-content-options {
  list-style: none ;
}

.list-box__item-content-options li:before {
  content: '✓';
  margin-right: 5px;
}

.list-box__item-maku-decisions {
  display: flex;
  justify-content: space-between;
  width: 65vh;
  margin: 10px;
  padding-top: 20px;
}


.list-box__item-maku-decisions button {
  text-align:center;
	font-weight:bold;
	border-radius: 15px;
  border: 1px solid black;
}

.list-box__item-maku-decisions--yes {
  width: 220px;
}

.list-box__item-maku-decisions--no {
  width: 260px;
  background-color: #676666;
  padding: 2px;
  &:hover {
    background-color: #5c5c5c;
  }
  &:focus {
    background-color: #5c5c5c;
  }
}



@import 'steuerfrei/meineakte_steuerfrei',
  'versicherungen/meineakte_versicherungen',
  'uebersicht/meineakte_uebersicht',
  'mpp/meineakteMpp',
  'vorteilskonto/meineakte_vorteilskonto';
