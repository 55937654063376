.bonago_home-wrapper {
  .overview_map {
    margin-left: 10px;
  }

  #retailers_full_map {
    height: 100%;
  }
}

.retailer-template-content {
  display: flex;
  flex-direction: column;
  width: 200px;
  height: 200px;
  line-height: 20px;
  margin: 5px;
  overflow: hidden;

  .info-box-heading {
    margin-bottom: 20px;
    font-weight: bold;
  }

  .info-text {
    word-break: break-word
  }

  .go-to-retailer {
    margin-top: 40px;

    a {
      color:  rgba(110, 204, 57, 0.6);
    }
  }
}
