#dashboard-box {

  .margin-top {
    margin-top: 1rem;
  }

  .progress-chart {
    height: 20px;
    width: 100%;
    position: relative;
    div {
      width: 0px;
      height: 20px;
      float: left;
    }
    .radio-0 {
      background-color: $primary;
    }
    .radio-1 {
      background-color: $secondary;
    }
    .radio-2 {
      background-color: $neutral;
    }
    .text {
      background-color: #000;
    }
  }

  .filter-select {
      max-width: rem-calc(100);
      color: $primary;
      border-color: $primary;
      border-width: 2px;
      margin: 0;
      background-color: transparent;

      &.isFilled,
      &:focus,
      &:hover {
        border-color: lighten($primary, $btn-bg-color-difference);
        background-color: transparent;
        outline: 0;
      }

      &:active {
        color: #fff;
        background-color: $primary;
        border-color: $primary;
      }
  }

  .padding {
    padding: 15px;
  }

  .question {
    margin-bottom: 25px;
    .circletext {
      margin-top: rem-calc(10);
    }
  }

  &.arbeitnehmer {
    margin-top: rem-calc(-64);
  }

  fieldset {
    margin: 0;
    padding: 0;
  }

  .section-bg {
    min-height: rem-calc(410);
    padding-top: rem-calc(64);
    background: url(/assets/img/dashboard/bg_dashboard.jpg) no-repeat right center;
    background-size: cover;
    position: relative;

    &.datev-page-header {
      background: url(/assets/img/datev/bg_datev.jpg) no-repeat top center;
      background-size: cover;
    }

/*
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      background: -webkit-linear-gradient(
        90deg,
        rgba(255,255,255,0) 30%,
        rgba(255,255,255,1) 100%
      );
      background: linear-gradient(
        90deg,
        rgba(255,255,255,0) 30% ,
        rgba(255,255,255,1) 100%
      );
    }
*/
  }
  .double-label-switch > label {
    display: block;
    line-height: inherit;
    vertical-align: top;
  }

  .section-title {
    margin: rem-calc(80) 0 rem-calc(40);
    font-size: 1.4rem;
    text-align: center;
  }

  .video-container {

    .video {
      position: relative;
      padding-bottom: 55%;
      height: 0;
      margin: rem-calc(6) 0;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    .video-link {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
  }

  .bausteine-summary {
    min-height: rem-calc(175);
    display: block;
    white-space: nowrap;
    overflow: hidden;
    position: relative;

    .toLeft,
    .toRight {
      width: rem-calc(60);
      height: 100%;
      position: absolute;
      top: 0;
      z-index: 2;
      // padding: rem-calc(10);
      cursor: pointer;
      text-align: center;
      transition: all .2s ease-in-out;

      .font-icon {
        font-size: 2.3rem;
        position: absolute;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
        transition: font-size .2s ease-in-out;
      }

      &:hover .font-icon {
        font-size: 2.5rem;
      }
    }

    .toLeft {
      left: 0;
      background: linear-gradient(90deg, #fff, rgba(255,255,255,0));

      &.hidden {
        left: rem-calc(-60);
      }
    }

    .toRight {
      right: 0;
      background: linear-gradient(-90deg, #fff, rgba(255,255,255,0));

      &.hidden {
        right: rem-calc(-60);
      }
    }

    .carouselCanvas {
      position: absolute;
      top: 0;
      left: 0;
      transition: transform .2s ease-in-out;
    }

    .bausteinWrapper {
      width: 220px;
      margin-top: 0;
      display: inline-block;
      float: none;

      &:first-child {
        margin-left: rem-calc(-10);
      }
    }
  }

  .row.boxes {
    margin-top: rem-calc(15);

    .box {
      padding: rem-calc(13);

      h3 {
        margin-bottom: rem-calc(25);
      }

      .more {
        margin-top: rem-calc(45);
      }
    }
  }

  .feedback {
    padding: 0;
    max-height: 0;
    overflow: hidden;

    .circletext {
      margin-top: rem-calc(10);
    }

    &.show {
      max-height: rem-calc(100);
      padding: rem-calc(20);
      -webkit-transition: max-height .3s ease-in-out;
      transition: max-height .3s ease-in-out;
    }

    &.success{
      background: #B9E68A;

      .icon-tick {
        color: #497C11;
        font-size: 1.5rem;
        margin: 0 rem-calc(15);
      }
    }

    a {
      font-weight: 400;
      vertical-align: sub;
      line-height: 0;
      color: $primary;

      &:hover {
        color: $link-color;
      }

      &.go:after {
        color: $link-color;
        font-size: 1.5rem;
      }
    }
  }

  .contact {

    &.box .main {
      padding: 0;
    }

    .detail {
      padding: rem-calc(10) 0;
      border-bottom: rem-calc(1) solid #d2dde3;
      font-size: rem-calc(13px);

      &.email {
        border: none;
        text-align: left;
        padding-left: rem-calc(10);
        padding-bottom: 0;
      }

      &.phone {
        text-align: left;
        padding-left: rem-calc(10);
      }

      &.buttons {
        .button {
          margin: 0;
        }
      }

      &:last-child {
        border-bottom: 0;
      }

      button {
        margin-bottom: 0;
      }
    }

    .font-icon {
      font-size: 1.4rem;
      margin-right: rem-calc(20);
      position: relative;
      top: rem-calc(-2);
    }
  }

  .avatar {
    margin: 0;

    .picture {
      width: rem-calc(120);
      height: rem-calc(120);
      border-radius: rem-calc(120);
      border: rem-calc(1) solid rgba(0,0,0,.4);
      overflow: hidden;
      display: inline-block;
    }

    .image {
      //max-height: 100%;
      // Reseting inherited styles from _side.scss...
      position: static;
      margin: 0;
      // Reseting inherited styles from _global.scss...
      width: 100%;
    }

    .name {
      font-size: 1.2rem;
      margin: 1rem 0 .6rem;
    }
  }

  .arrow-icon-container {
    background-color: rgba($primary, 70%);
    color: #ffffff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: rem-calc(30);
    font-size: rem-calc(20);
    z-index: 1;

    &.right {
      right: 0;
      padding-left: rem-calc(5);
      border-radius: rem-calc(3) 0 0 rem-calc(3);
    }
    &.left {
      left: 0;
      padding-right: rem-calc(5);
      border-radius: 0 rem-calc(3) rem-calc(3) 0;
    }

    .font-icon {
      position: relative;
      top: rem-calc(-3);
      color: $icon-color-menu;
    }
  }

  .rn-carousel-indicator {
    position: absolute;
    top: rem-calc(15);
    right: rem-calc(15);
    background-color: rgba(128,128,128,0.5);
    padding: rem-calc(5);
    border-radius: rem-calc(5);

    span {
      margin-right: rem-calc(10);

      &:last-child {
        margin-right: rem-calc(0);
      }
    }
  }

  #bigAdvertizement {
    list-style: none;
    background: #fff;
    position: relative;
    height: rem-calc(355);
    overflow: hidden;
    text-align: center;
    margin: 0;
    border-radius: rem-calc(3);

    li {
      img {
        top: 50%;
        transform: translateY(-50%);
        position: relative;
      }
    }
    .priceTag{
      position: absolute;
      right: 0;
      bottom: rem-calc(20);
      background: $primary;
      color: #fff;
      text-transform: uppercase;
      border-radius: rem-calc(3) 0 0 rem-calc(3);

      span {
        display: block;

        &.price {
          font-size: 1.2em;
        }
      }

      &.big {
        padding: rem-calc(22)rem-calc(20);
      }
    }
  }

  .vn-progressbar {
    width: 100%;
    margin: 0 auto;
    padding: rem-calc(30) 0 rem-calc(10);

    .progress {
      height: rem-calc(15);
      border-radius: rem-calc(3);
      overflow: hidden;
    }

    .meter {
      background-color: $link-color;
    }
  }

  .saving {
    text-align: center;
    padding-bottom: rem-calc(15);

    .big {
      font-size: 3.5rem;
      font-weight: 500;
      color: $link-color;
      margin-bottom: rem-calc(-15);
    }
  }

  .big.font-icon {
    font-size: rem-calc(45);
    color: $icon-color-dark;
  }

  .actions {
    .main {
      padding: rem-calc(15);
    }

    .button {
      text-align: left;
      display: block;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  #side-view .box {
    margin: 0 rem-calc(7) rem-calc(14);

    .main {
      padding: rem-calc(15);
    }
  }

  .tabbable {
    .tabs {
      padding-bottom: 0;

      .bigtab a {
        font-size: 1rem;
        padding: 1.0rem 2.2rem;
      }

      dd + dd {
        // margin-left: rem-calc(2);
        margin-left: 0px;
        padding-left: 2px;
      }
    }

    .tabs-content {
      margin-top: rem-calc(2);
    }
  }

  .tabpage-header {
    padding: rem-calc(10);

    .button {
      margin: 0;
    }
  }

  .tabpage-content {
    background-color: #fff;
    margin: rem-calc(13) rem-calc(-13) rem-calc(-13);

    &.transparent {
      background: transparent;
      span.subtitle,
      span.subsubtitle {
        color: $primary;
      }
    }

    .sr-only {
      display: none;
    }

    .bigbox {
      margin-bottom: 0;
    }
  }

  .tabs-content,
  .tabs-content .content {
    margin-bottom: 0;
  }

  .module-list {
    padding: rem-calc(10);

    .module {
      height: rem-calc(120);
      background-color: #F7F9FA;
      margin: rem-calc(10);
      @include clearfix();
    }

    .module-icon {
      height: 100%;
      float: left;
      padding: rem-calc(25);
    }

    .font-icon {
      font-size: 3rem;
    }

    .module-name {
      font-size: .9rem;
      line-height: 1.2rem;
      padding-top: rem-calc(10);
      height: rem-calc(40);
      vertical-align: bottom;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .module-usage {
      font-size: 2rem;
      font-weight: 400;
      color: $font-primary;
    }
    .module-total {
      font-size: 1rem;
      color: $font-primary;
    }
  }

  #side-view {
    position: static;
  }

  .chart-base {
    padding: rem-calc(10);
  }

  .calc-panel {
    overflow: visible;
    border: 0;
    min-height: rem-calc(341);
    padding: rem-calc(28) rem-calc(28) 0;
    background-size: contain;
    margin-bottom: rem-calc(15);
    background: #fff right top no-repeat;

    h3 {
      color: $secondary;
      font-weight: bold;
      margin-top: rem-calc(20);
      text-transform: uppercase;
    }

    .button {
      margin-top: rem-calc(18);
    }

    .help-block {
      display: none;
    }

    p {
      line-height: 1.5rem;
    }

    .switch__label {
      border: rem-calc(1) solid $primary;

      &:after {
        top: rem-calc(3);
      }
    }

    label {
      font-weight: 500;
      color: $primary;
    }
  }

  .panel.semitransparent {
    color: $dashboard-welcome-txt-color;
    background-color: rgba($dashboard-welcome-bg, $dashboard-welcome-opacity);
    margin: rem-calc(60) rem-calc(15) 0;
    border: 0;
    position: relative;
    z-index: 2;

    h2 {
      font-size: 2rem;
    }
  }

  .row.hl {
    background: $primary;
    margin-left: rem-calc(-28);
    margin-right: rem-calc(-28);
    margin-top: rem-calc(17);
    color: #fff;
    height: rem-calc(84);
    border-radius: 0 rem-calc(4) rem-calc(4) 0;
    position: relative;
    font-size: rem-calc(18);
    font-weight: 300;
    display: table;

    .resultLabel span {
      margin-top: rem-calc(10);
      margin-left: rem-calc(10);
      display: inline-block;
    }

    .resultValue span {
      margin: rem-calc(10);
      display: inline-block;
    }

    .go {
      background: linear-gradient($secondary, darken($secondary, 8%));
      border-radius: 0 rem-calc(4) rem-calc(2) 0;
      position: relative;

      @media #{$medium-up} {
        height: 100%;
      }

      &:before {
        content: '';
        display: none;
        position: absolute;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: rem-calc(42) 0 rem-calc(42) rem-calc(25);
        border-color: transparent transparent transparent $primary;
      }

      span {
        margin: rem-calc(10);
        display: inline-block;
      }
    }

    .border-row {
      position: absolute;
      width: 100%;
      height: 100%;
      border-bottom: rem-calc(2) solid rgba($primary, 0.7);
      border-radius: rem-calc(3);
    }
  }

  .light-circles .circletext > span {
    color: $primary;
  }

  .circletext {
    margin-bottom: rem-calc(15);
  }

  @media #{$xsmall-only}, #{$small-only} {
    .circletext {
      width: 100%;
    }
  }

  @media #{$medium-up} {
    .calc-panel {
      padding: rem-calc(45);
      background-image: url(/assets/img/dashboard/bg_valuenet_potential_calculator.jpg);
      background-size: cover;
    }

    .row.hl {

      &.hidden {
        visibility: hidden;
      }

      margin-left: rem-calc(-45);

      .resultLabel span {
        margin-top: rem-calc(16);
        margin-left: rem-calc(48);
      }

      .resultValue span {
        margin-top: rem-calc(16);
      }

      .go {
        cursor: pointer;
        &:before {
          display: block;
        }

        span {
          margin-top: rem-calc(28);
          margin-left: rem-calc(53);
        }
      }
    }
  }

  @media #{$large-up} {

    .row.boxes {

      .box p {
        min-height: 13rem;
        text-align: justify;
      }

      .columns:first-child {
        padding-right: rem-calc(4);
      }

      .columns:nth-child(2) {
        padding-left: rem-calc(2);
        padding-right: rem-calc(2);
      }

      .columns:last-child {
        padding-left: rem-calc(4);
      }
    }
  }
}

.welcomeVideo {
  .ngdialog-overlay {
    background: rgba(0,0,0,.8);
  }

  .ngdialog-content {
    background-color: transparent;
    position: relative;
    top: 50%;
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
  }

  .ngdialog-close {
    position: absolute;
    top: rem-calc(-40);
    right: 0;
    color: #fff;
    font-size: 2rem;
  }

  .ngdialog-content > .ngdialog-close {
    display: none;
  }

  .modal-content-wrapper {
    background-color: #eee;
    padding: rem-calc(20);

    .video {
      position: relative;
      padding-top: rem-calc(34);
      padding-bottom: 55%;
      height: 0;
      margin: rem-calc(6) 0;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }
}
