@import '../cockpit/cockpit.scss',
        './mpp/cockpit_mpp.scss',
        './datev/administration_datev.scss',
        './bonus_history/bonus_history_dialog';

.word-wrap {
  word-break: break-word;
  padding-right: 10px;
}

.preend-datepicker ._720kb-datepicker-calendar-day {
  background-color: #31ea31 !important;
}

.preend-datepicker ._720kb-datepicker-calendar-day._720kb-datepicker-disabled {
    color: rgba(0, 0, 0, 0.2);
    background: rgba(25, 2, 0, 0.02) !important;
    cursor: default;
}

.control_dialog {

  .cockpit-panel__title {
    background: transparent;
  }

  .cockpit-panel__body {
    padding: 30px !important;
    position: relative;
  }

  #meineakte-dokumente {
    width: 100% !important;
  }

  .control-dialog-box {
    position: relative;
    padding: 15px;
    margin-left: 0px;
    margin-right: 5px;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  hr {
    margin: 0px !important;
  }

  h1 {
    text-align: left;
    margin-bottom: 10px;
  }

  .user-info {
    text-align: left;
    margin-bottom: 20px;
  }

  .strong {
    font-weight: bold;
  }

  .info_area {
    margin: 20px 10px 0px 10px;
  }

  .right {
    margin-left: 40px !important;
  }


  .checkbox {
    margin: 0;

    label::before {
      margin: 0;
    }
  }
  .locked-warning {
    background-color: red;
    color:white;
    padding: 10px;
  }
}

@import "yearly/administration_yearly";

.hide-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
