@import '../shared/cockpit_toggable_content';

.cockpit_projekte-wrapper {
  @include cockpit_toggable_content;

  .cockpit__projectSection {
    border-bottom: rem-calc(1) solid rgba($primary, .7);
    padding: rem-calc(10) 0 rem-calc(20);
    margin: 0 0 rem-calc(10);

    h3 {
      font-size: 1rem;
      margin-bottom: rem-calc(15);
    }
  }
  
  .align-labels {
    .row {
      min-height: rem-calc(53);

      label {
        line-height: rem-calc(40);
      }
    }
  }

  .newproject {
    &__title {
      margin: rem-calc(8) 0;
    }
    
    &__kind {
      margin-top: rem-calc(16);
    }
  }
  
  .list-box__item {
    &.highlight {
      background-color: rgba(217, 218, 213, 0.9);;
    }
  }
}
