.main-section > .row:first-child {
  height: 100%;
}

#main-view {
  padding: 13px;

  float: left;
  width: calc(100% - 297px);
  min-height: 500px;

  .box {
    margin-bottom: 15px;
    background: #fff;
  }
}

#side-view
.main
ul
li
.key {
  max-width: 150px !important ;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

#side-view {
  margin-top: 13px;
  z-index: 1;

  width: 294px;
  padding-right: 0px;
  position: fixed;
  right: calc((100% - 78.13333rem) / 2);
  max-height: calc(90% - 60px);
  overflow-y: auto;

  border: $side-component-border-width solid $side-component-border-color;

  &.bonus {
    position: static;
  }
  .custom {
    .title {
      padding: 8px 22px;
      label {
        margin-bottom: 12px;
      }
      .progressWrapper {
        position: relative;
        .progress {
          width: calc(100% - 70px);
        }
        .display {
          position: absolute;
          right: 15px;
          top: -9px;
        }
      }
      &.linable {
        position: relative;
        a.go.big {
          position: absolute;
          right: 4px;
          top: 50%;
          margin-top: -12px;
          &:after {
            color: $primary;
          }
        }
      }
    }
    .main {
      background: #fff;
      padding: 12px 16px;
      .name {
        margin-bottom: 10px;
        span {
          font-weight: 400;
        }
      }
      .profile {
        span {
          display: inline-block;
          line-height: 15px;
          font-size: 12px;
        }
      }
    }
  }

  .title {
    background: rgba(255, 255, 255, 0.9);
    border-radius: $border-radius 0 0 0;
    // border-bottom: rem-calc(1) solid rgba(0,0,0,.1);

    span.mobile {
      display: none;
    }
    .spinner {
      position: relative;
      display: inline-block;
      float: right;
      height: 18px;
    }
  }
  .box {
    -webkit-transition: margin-top 0.6s; /* Safari */
    transition: margin-top 0.6s;
    margin-bottom: 9px;

    &:last-child {
      margin-bottom: 0px;
    }

    &:not(.custom) {
      .title {
        font-size: 12px;
        font-weight: 500;
        text-transform: uppercase;
        text-align: center;
        padding: 8px 0;
        letter-spacing: 1px;
      }
      .main {
        background: rgba(#fafafa, 0.8);
        border-radius: 0 0 0 $border-radius;
        &.align {
          padding: 8px 16px;
          hr {
            margin: 16px -16px;
            border: solid $border-color;
            border-width: 1px 0 0;
          }
        }
      }
    }
  }

  // background: #fff no-repeat;
  // background-image:
  //       -webkit-linear-gradient(rgba(0,0,0,1), rgba(0,0,0,0.5), rgba(0,0,0,0)),
  //       -webkit-linear-gradient(rgba(0,0,0,1), rgba(0,0,0,0.5), rgba(0,0,0,0));
  //   background-image:
  //       -moz-linear-gradient(rgba(0,0,0,1), rgba(0,0,0,0.5), rgba(0,0,0,0)),
  //       -moz-linear-gradient(rgba(0,0,0,1), rgba(0,0,0,0.5), rgba(0,0,0,0));
  //   background-image:
  //       linear-gradient(rgba(0,0,0,1), rgba(0,0,0,0.5), rgba(0,0,0,0)),
  //       linear-gradient(rgba(0,0,0,1), rgba(0,0,0,0.5), rgba(0,0,0,0));
  //   background-position: 0 0, 0 100%;
  //   background-size: 100% 30px;
}

// #side-view:before,
// #side-view:after {
//     content: "";
//     position: relative;
//     z-index: -1;
//     display: block;
//     height: 30px;
//     margin: 0 0 -30px;
//     background: -webkit-linear-gradient(top,#FFF,#FFF 30%,rgba(255,255,255,0));
//     background:    -moz-linear-gradient(top,#FFF,#FFF 30%,rgba(255,255,255,0));
//     background:   linear-gradient(to bottom,#FFF,#FFF 30%,rgba(255,255,255,0));
//     }
// #side-view:after {
//     // margin: 0;
//     margin: -30px 0 0;
//     background: -webkit-linear-gradient(top,rgba(255,255,255,0),#FFF 70%,#FFF);
//     background:    -moz-linear-gradient(top,rgba(255,255,255,0),#FFF 70%,#FFF);
//     background:   linear-gradient(to bottom,rgba(255,255,255,0),#FFF 70%,#FFF);
//     // background: linear-gradient(rgba(#fafafa, .4), rgba(#fafafa, .8), rgba(#fafafa, 0));
//     }

#submoduleIndicator {
  .main {
    width: 100%;
    height: 294px;
    position: relative;
    text-align: center;
  }

  .font-icon {
    font-size: rem-calc(130);
    line-height: 2.1;
  }
}

@media #{$screen} and (max-width:#{upper-bound($medium-range)}) {
  #side-view:not(.columns) {
    position: fixed;
    right: 0px;
  }
}

#side-view .title {
  .font-icon {
    display: none;
  }
}

@media #{$small-only}, #{$xsmall-only} {
  #main-view {
    width: 100%;
  }

  #side-view {
    pointer-events: none;
    width: 100%;
    position: fixed;
    padding: 0 rem-calc(3);
    top: 10rem;
    left: 0;
    bottom: 0;
    z-index: 100;

    #currentFinances {
      pointer-events: all;
      height: auto;
      position: absolute;
      z-index: 1000;
      width: calc(100% - 6px);
      top: calc(100% - 53px);
      box-shadow: 0 0 rem-calc(3) rem-calc(-1) rgba(0, 0, 0, 0.4);
      opacity: 1;
      -webkit-transition: top 0.6s ease-in-out, opacity 0.3s ease-in-out;
      transition: top 0.6s ease-in-out, opacity 0.3s ease-in-out;

      &.open {
        top: 0;
        bottom: rem-calc(-10);

        .title {
          background: rgba(255, 255, 255, 1);
          padding: 0 rem-calc(15);
        }

        .main {
          height: 100%;
          overflow: auto;
          background: rgba(250, 250, 250, 1);

          & ~ .main {
            height: auto;
          }
        }
      }

      .title {
        background: rgba(255, 255, 255, 0.95);
        border-radius: 3px 3px 0 0;
        text-transform: capitalize;
        font-size: 16px;
        color: $secondary;
        line-height: 50px;
        text-align: left;
        padding: 0 rem-calc(15);

        .error-title {
          color: $red;
        }

        span.plus {
          float: right;
          color: $green;
        }
        span.mobile {
          display: inline-block;
        }
        span:not(.mobile) {
          display: none;
        }

        .font-icon {
          display: inline-block;
        }
      }

      &.fade-out {
        top: 100%;
        opacity: 0;
        // -webkit-transition-duration: 0;
        // transition-duration: 0;
      }
    }
  }

  #submoduleIndicator {
    display: none;
  }
}

#side-view .main {
  &.video-container {
    padding: 1rem;

    .video {
      position: relative;
      padding: 80px 0;
      height: 0;
      margin: 0;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      .uc-embedding-container {
        min-height: 160px;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;

        .uc-embedding-wrapper {
          min-height: calc(100% - 12px);
        }
      }
    }

    .video-link {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }
  }

  .center {
    text-align: center;
  }
  ul {
    margin: 0 0px 13px 0px;
    &.no-bottom-margin {
      margin-bottom: 0px;
    }
    padding: 0 0 0 0;
    list-style: none;

    &:not(.submenue) {
      border-bottom: 1px solid #d2dde3;
    }

    &.submenue li {
      margin-left: 10px;
      font-size: 12px;
      font-weight: 400;
      span.key.bold {
        font-weight: 600;
      }
    }
    li {
      margin-left: 43px;
      position: relative;
      padding: 8px 0 5px 0px;
      &:not(:last-child):not(.blank) {
        border-bottom: 1px solid #d2dde3;
      }
      &.subtext {
        padding-bottom: 10px;
      }
      &.bausteine {
        padding: 11px 0;
        img,
        .img {
          width: 1.6em;
          height: 1.6em;
        }
        .icon-minus {
          color: #f00;
        }
      }
      .font-icon {
        position: absolute;
        margin-left: -34px;
        margin-top: -6px;
        font-size: 1.6rem;
      }
      &.blue {
        color: #0496d8;
      }
      .key {
        max-width: 170px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-block;
        &.light {
          font-weight: 200;
        }
        &.bold {
          font-weight: 400;
        }
        &.plus {
        }
      }
      .value {
        text-align: right;
        float: right;
        margin-right: 10px;
        display: inline-block;
        &.green {
          color: $green;
        }
        &.red {
          color: $red;
        }


      }
      .no-left-margin {
        text-align: right;
        margin-right: 20px;
        display: inline-block;
      }
      .no-right-margin{
        text-align: right;
        margin: 0px;
        padding-bottom: 5;
        display: inline-block;
      }
      .normal {
        font-style: normal !important;
        margin-left: 5;
      }
      // @TODO: purge this!
      a.openable {
        &:before {
          content: "\e6ce";
          position: absolute;
          left: -27px;
          top: 11px;
          font-family: "entypo";
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
        }
        &.selected:before {
          content: "\e6cf";
        }
      }

      ul {
        &:not(.blank) {
          border-top: 1px solid #d2dcdf;
          margin: 0;
          margin-top: 4px;
        }
        > li:not(.blue) {
          padding: 0px 0 0px 0px;
          border-bottom: none !important;
        }
        > li.blue {
          margin-left: 0px;
          .key {
            padding-left: 0px;
          }
        }
      }
    }
  }
}

#finishProfile {
  .main {
    a.down,
    a.go {
      float: right;
      font-size: 12px;
    }
    .profile {
      position: relative;
      a.go {
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }
  }
}

#recomendations {
  a.infoBtn {
    float: right;
  }
}
