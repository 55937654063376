.flex-column{
  @include flexbox();
  @include flex-direction(column)
}

.price-box {
  $gutter: 13.1px;
  $rem-gutter: rem-calc($gutter);

  margin-top: rem-calc(15px);

  @media #{$medium-up} {
    margin-top: 0;
  }

  .price-box-title {
    background-color: rgba(255, 255, 255, 0.5);
    text-align: center;
    padding: rem-calc(4px);
  }

  .price-background-wrapper {
    background-color: #ffffff;
    padding: 0 $rem-gutter 0 $rem-gutter;

    .price-row {
      margin-right: - $rem-gutter;

      .price {
        @extend .bigger-font;
        line-height: rem-calc(50px);
        float: left;
        display: inline-block;
      }
    }

    .button-row {
      border-top: 1px solid $separator-color;
      padding-top: $rem-gutter;
      padding-bottom: $rem-gutter;
      margin-left: -$rem-gutter;
      margin-right: -$rem-gutter;
      text-align: center;

      .button {
        width: 80%;
        margin-bottom: 0;
      }
    }
  }
}

.box-list-box {
  position: relative;
  background-color: #FFFFFF;
  box-shadow: 0 rem-calc(3px) rem-calc(3px) rgba(0,0,0,0.07);
  border-bottom-right-radius: rem-calc(3px);
  border-bottom-left-radius: rem-calc(3px);

  border-box: content-box;
  padding-bottom: 80%;

  @media #{$medium} {
    padding-bottom: 80%;
  }

  @media #{$large} {
    padding-bottom: 80%;
  }

  &__content {
    position: relative;
    overflow: hidden;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 2.6rem;
    left: 0;
    right: 0;

    img {
      width: 100%;
    }
    .offerlabel {
      position: absolute;
      bottom: 0px;
      right: 0px;
      background: $primary;
      color: #fff;
      padding-right: 10px;
      padding: 10px;
    }
  }

  &__title {
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    // position: relative;

    // padding: rem-calc(10px);
    height: rem-calc(38px);

    .title-content {
      max-height: rem-calc(38px);
      overflow: hidden;
      // height: 100%;
      font-size: rem-calc(14px);
      padding: 0 15px 0 rem-calc(15px);
      line-height: rem-calc(15px);
      // height: rem-calc(51px);
      position: relative;
      top: 50%;
      transform: translateY(-50%);
    }
  } // .box-list__box__title
}

.video-row {
  padding: 13px;

  .pagetitle {
    padding-left: 5px;
    font-size: 18px;
  }
}

.submenu-balance {
  position: absolute;
  right: rem-calc(25);
  top: rem-calc(13);

  &__value {
    margin-right: rem-calc(3);
  }

  &__sign {
  }
}

@mixin rotating-toggler-arrow($class, $toggler-class, $speed) {
  #{$class} {
    transition: transform $speed;
    transform: rotateX(0deg);
    display: inline-block;
  }

  #{$toggler-class} #{$class} {
    transition: transform $speed;
    transform: rotateX(-180deg);
  }
}

.ql-editor strong {
  font-weight: bold;
}

.camera {
  border: 2px dashed rgba(191, 191, 191, 0.7);
  padding: 25px;
  margin: 20px 0 30px 0;
  position: relative;
  height: 300px;
  background-color: #fafbfb;
  text-align: center;
  box-sizing: content-box;
  display: flex;
  justify-content: center;
  align-items: center;

  button, span {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
  }

  button {
      bottom: -32px;
  }

  span {
      top: -11px;
      padding: 1px 10px;
      box-shadow: 0 2px 5px #ccc;
      
      &.warning {
          background-color: yellow;
      }

      &.hint {
          background-color: #bed4cf;
      }
  }

  video, canvas {
      display: none;
  }

  video, img {
      width: 400px;
  }

  &.on {
      video {
          display: inline-block;
      }

      img {
          display: none;
      }
  }
}