.benefit-amazon-wrapper {
  .retailer-logo {
    width: 60%;
  }

  .voucher-value-wrapper {
    background: #ffa135;
    text-align: center;

    .voucher-value,
    label {
      color: white;
    }

    .voucher-value {
      display: inline-block;
      text-align: right;
      width: rem-calc(70);
      background: transparent;
      border: 0;
    }

    input {
      margin: 0;
    }
  }

  .benefit-bonago-list-wrapper {
    .box-list-box {
      margin-right: rem-calc(15px);
      margin-bottom: rem-calc(15px);
    }
  }
}
