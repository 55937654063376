// Adding new breakpoint
$xsmall-breakpoint: em-calc(380);

// Adding new ranges
$xsmall-range: (0, $xsmall-breakpoint);
$small-range: ($xsmall-breakpoint - em-calc(1), $small-breakpoint);

// Adding new media query boundaries
$xsmall-up: $screen;
$xsmall-only: "#{$screen} and (max-width: #{upper-bound($xsmall-range)})";
$small-up: "#{$screen} and (min-width:#{lower-bound($small-range)})";
$small-only: "#{$screen} and (min-width:#{lower-bound($small-range)}) and (max-width:#{upper-bound($small-range)})";

// Adding new classes
@media #{$xsmall-up} {
  @include grid-html-classes($size:xsmall);
}

// Redefine media queries, otherwise xsmall overwrites them (as it is defined later)
@media #{$small-up} {
  @include grid-html-classes($size:small);
}
@media #{$medium-up} {
  @include grid-html-classes($size:medium);
}
@media #{$large-up} {
  @include grid-html-classes($size:large);
}
// @media #{$xlarge-up} {
//   @include grid-html-classes($size:xlarge);
// }
// @media #{$xxlarge-up} {
//   @include grid-html-classes($size:xxlarge);
// }