.cockpit_arbeitnmv-wrapper {
  .date {
    ._720kb-datepicker-calendar._720kb-datepicker-open {
      position: relative;
    }
  }
}

button {
  .button {
    margin: 5;
  }
}

._720kb-datepicker-calendar._720kb-datepicker-open {
  position: unset;
}

.newBasketInputsGroup {
  padding: 5px;
}


.mpp_basket_list_container {
  .list-box__itemTitle {
    padding: 0px !important;
  }
}
