////////////////////////////////////////////
//
// Better global button styling
// @NOTE:
//   still depends partially on Foundation
//
////////////////////////////////////////////
// Component config
////////////////////////////////////////////

  $btn-border-radius: rem-calc(3);
  $btn-border-style: solid;
  $btn-border-width: rem-calc(2);
  $btn-border-bottom-opacity: .4;
  $btn-transition-property: all;
  $btn-transition-timing: .3s;
  $btn-transition-function: ease-in-out;
  $btn-bg-color-difference: 10%;

////////////////////////////////////////////
// Prototyping component
////////////////////////////////////////////

%proto-button {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-weight: 400;
  border-radius: $btn-border-radius;
  border-bottom:
    $btn-border-width
    $btn-border-style
    rgba(0,0,0, $btn-border-bottom-opacity);
  -webkit-transition:
    $btn-transition-property
    $btn-transition-timing
    $btn-transition-function;
  transition:
    $btn-transition-property
    $btn-transition-timing
    $btn-transition-function;
  padding-top: rem-calc(14);
  padding-bottom: rem-calc(12);

  &:focus,
  &:hover {
    outline: 0;
    box-shadow: 0 0 5px 0 rgba(0,0,0,.4);
  }

  &:active {
    box-shadow: none;
    border-bottom-color: transparent;
  }

  &[disabled] {
    opacity: 40%;
    border-bottom-color: transparent;
    cursor: not-allowed;
  }

  &.isOutline {
    padding-top: rem-calc(5);
    padding-bottom: rem-calc(6);
    background-color: transparent;
    border:
      $btn-border-width
      $btn-border-style
      transparent;

    &:focus,
    &:hover {
      box-shadow: none;
    }

    .font-icon {
      font-size: rem-calc(13);
      -webkit-transition:
        $btn-transition-property
        $btn-transition-timing
        $btn-transition-function;
      transition:
        $btn-transition-property
        $btn-transition-timing
        $btn-transition-function;
    }
  }

  .font-icon {
    font-size: rem-calc(20);

    &:first-child {
      margin-left: rem-calc(-5);
      margin-right: rem-calc(10);
    }

    &:last-child {
      margin-left: rem-calc(10);
      margin-right: rem-calc(-5);
    }
  }
}

////////////////////////////////////////////
// Creating component
////////////////////////////////////////////

.button {
  @extend %proto-button;
  // Take over Foundation btn
  // background-color: $neutral;

  // &:focus,
  // &:hover {
  //   background-color: lighten($neutral, $btn-bg-color-difference);
  //   border-color: lighten($neutral, $btn-bg-color-difference);
  // }

  // .font-icon {
  //   color: #fff;
  // }

  display: block;
  width: 100%;

  .loader-wrapper {
    > * {
      margin: 0 5px !important;
  
      &:last-child {
        margin-left: 5px !important;
      }
    }
  }

  @media #{$medium-up} {
    width: auto;
    display: inline-block;
  }

  &.validator {
    margin-top: 20px;
    padding: 0.62rem 17px;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

.button-primary {
  background-color: $primary;

  &:focus,
  &:hover {
    background-color: lighten($primary, $btn-bg-color-difference);
    border-bottom-color: rgba($primary, $btn-border-bottom-opacity);
  }

  &:active {
    background-color: darken($primary, $btn-bg-color-difference);
  }

  &.isOutline {
    color: $primary;
    border-color: $primary;

    &.isFilled,
    &:focus,
    &:hover {
      color: #fff;
      background-color: lighten($primary, $btn-bg-color-difference);
      border-color: lighten($primary, $btn-bg-color-difference);

      .font-icon {
        color: inherit;
      }
    }

    &:active {
      color: #fff;
      background-color: $primary;
      border-color: $primary;

      .font-icon {
        color: inherit;
      }
    }

    .font-icon {
      color: $primary;
    }
  }

  .font-icon {
    color: #fff;
  }
}

.button-secondary {
  background-color: $secondary;

  &:focus,
  &:hover {
    background-color: lighten($secondary, $btn-bg-color-difference);
    border-bottom-color: rgba($secondary, $btn-border-bottom-opacity);
  }

  &:active {
    background-color: darken($secondary, $btn-bg-color-difference);
  }

  &.isOutline {
    color: $secondary;
    border-color: $secondary;

    &.isFilled,
    &:focus,
    &:hover {
      color: $font-primary;
      background-color: lighten($secondary, $btn-bg-color-difference);
      border-color: lighten($secondary, $btn-bg-color-difference);

      .font-icon {
        color: inherit;
      }
    }

    &:active {
      background-color: $secondary;
      border-color: $secondary;
    }

    .font-icon {
      color: $primary;
    }
  }

  .font-icon {
    color: $font-primary;
  }
}

.button-cta {
  color: $font-primary;  // can be removed if Foundation is removed
  background-color: $call-to-action;

  &:focus,
  &:hover {
    color: $font-primary;  // can be removed if Foundation is removed
    background-color: lighten($call-to-action, $btn-bg-color-difference);
    border-bottom-color: rgba($call-to-action, $btn-border-bottom-opacity);
  }

  &:active {
    background-color: darken($call-to-action, $btn-bg-color-difference);
  }

  &.isOutline {
    color: $call-to-action;
    border-color: $call-to-action;

    &.isFilled,
    &:focus,
    &:hover {
      color: $font-primary;
      background-color: lighten($call-to-action, $btn-bg-color-difference);
      border-color: lighten($call-to-action, $btn-bg-color-difference);

      .font-icon {
        color: inherit;
      }
    }

    &:active {
      background-color: $call-to-action;
      border:
        $btn-border-width
        $btn-border-style
        rgba($call-to-action, $btn-border-bottom-opacity);
    }

    .font-icon {
      color: $primary;
    }
  }

  .font-icon {
    color: $font-primary;
  }
}

.button-link {
  background-color: transparent;
  color: $link-color;
  border: 0;
  box-shadow: none;
}

.button.button-small-header {
  margin-bottom: 0;
  padding-top: .2rem;
  padding-bottom: .2rem;
  position: relative;
  top: -.2rem;
}

.button.button-really-small-header {
  margin-bottom: 0;
  margin-left: 0;
  margin-right: 0;
  padding-left: 1px;
  padding-right: 1px;
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  top: -.2rem;
}

.button.button-primary.button-active {
  color: #fff;
  background-color: lighten($primary, $btn-bg-color-difference);
  border-color: lighten($primary, $btn-bg-color-difference);
}

button.mat-button-disabled {
  color: #979797 !important;
  cursor: no-drop !important;
}