$baustein-sizer: 338 / 552 * 100%;
$baustein-btn-bar-height: 0;

.bausteineRow {
  margin-top: -13px;
  margin-left: -10px !important;
}

.bausteinWrapper {
  margin-top: 13px;
  padding-left: 10px;


  .bausteinDescription {
    padding: 1.333rem;
    background-color: #fff;
    text-align: left;

    p {
      margin: 0;
    }
  }

  .btns {
    @extend .clearfix;
    border-top: 1px solid $lightgrey2;
    background-color: #fff;
    border-radius: 0 0 3px 3px;

    .restriction_text{
      height: 41px;
      line-height: 41px;
      span{
        vertical-align: middle;
        line-height: normal;
      }
    }

    a {
      display: inline-block;
      text-align: center;
      width: 50%;
      &.no-video {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
      }

      &.disabled {
        .font-icon {
          opacity: 0;
        }
      }
    }

    .font-icon {
      font-size: 1.8rem;
    }
  }

  .disturber {
    display: none;
    position: absolute;
    top: 44%;
    left: 55%;
    z-index: 3;
    transform: rotateZ(-30deg);
    background-color: #fff;
    padding: .5rem;
    border-radius: 2px;
    color: black;
    border: 1px solid #0496d8;
    max-width: 40%;

    @media #{$medium-up} {
      display: block;
    }
  }
}

.bausteineMainView {
  .bausteinWrapper {
    .modulstein {
      @include shadow();
      overflow: hidden;
      border-radius: 3px;
    }
  }
}

.baustein {
  position: relative;
  padding-top: $baustein-sizer;
  padding-bottom: $baustein-btn-bar-height;
}

.bausteinContainer {
  border-radius: 3px 3px 0 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: #fff;
  border: $side-component-border-width solid $side-component-border-color;

  .title {
    font-size: rem-calc(10);
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
    line-height: 3;
    color: $icon-color-default;
    position: absolute;
    left: 0;
    right: 0;
    bottom: $baustein-btn-bar-height;
    background-color: rgba(255,255,255,.75);

    &.selected {
      background-color: rgb(22, 41, 92);
      opacity: 0.6;
      color: #fff;
    }

    &.component_selected {
      background: red !important;
    }

    &.used {
      background-color: rgba(58, 55, 55, 1) !important;
      opacity: 1 !important;
      color: white;
    }

    &.checkout_restricted{
      background-color: rgba(58, 55, 55, 1) !important;
      opacity: 0.2 !important;
      color: white;
    }
  }

  .head {
    font-size: 12px;
    height: 38px;
    line-height: 38px;
    background: rgba(255,255,255,0.77);
    border-bottom: 1px solid #b9c8c9;

    img {
      margin-top: -3px;
      margin-left: 1em;

      &.info {
        float: right;
        margin-top: 6px;
        text-align: right;
      }
    }
  }

  .imgWrapper {
    display: block;
    text-align: center;
    height: 100%;

  }

  .imgContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: $baustein-btn-bar-height;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    &.icon {
      background-size: 30%;
    }

    &.icon-large {
      background-size: 80%;
    }
    &.used {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      opacity: 1;
    }

    &.checkout_restricted {
      -webkit-filter: grayscale(100%);
      filter: grayscale(100%);
      opacity: 0.2;
    }
    // &.used {
    //   opacity: 0.2;
    // }


  }

}

.modulsteineTitle {
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  width: 100%;
  display: inline-block;
  padding: 18px 38.5px 15px 38.5px;
  position: relative;

  a.logout {
    font-size: 12px;
    margin-top: 8px;
    position: absolute;
    right: 38.5px;
    top: 18px;

    // img {
    //   max-width: 1.4em;
    //   margin-right: 8px;
    // }
  }
}

.modulstein {
  .head {
    font-size: 12px;
    height: 38px;
    line-height: 38px;
    background: rgba(255,255,255,0.77);
    border-bottom: 1px solid #b9c8c9;
    text-align: center;

    span {
      font-size: 15px;
      text-transform: uppercase;
    }

    .font-icon {
      margin-top: rem-calc(-3);
      margin-left: 1em;

      &.icon-info {
        right: rem-calc(12);
        position: absolute;
        margin-top: 0;
        text-align: right;
        font-size: 1.5rem;
      }
    }
  }

  .imgContainer {
    height: rem-calc(230);
    background: #fff center center no-repeat;
    text-align: center;
    position: relative;

    .font-icon {
      font-size: rem-calc(50);
      margin-top: rem-calc(80);
      display: inline-block;
    }
  }

  .btns {
    height: 50px;
    background: #fff;
    text-align: center;
  }
}

#auswahl .pagetitle {
  padding-left: 5px;
  font-size: 18px;
}

.morebausteine {
  font-size: 12px;
  text-align: right;
  font-weight: 200;
  vertical-align: bottom;
  line-height: 22px;

  a {
    color: #fff;
    vertical-align: -webkit-baseline-middle;

    span {
      vertical-align: middle;
    }
  }
}


@media #{$xsmall-only}, #{$small-only} {

  .modulsteineTitle {
    .titletext {
      display: block;
      width: 75%;
    }
    padding-bottom: 0px!important;
  }

  .modulstein {
    .imgContainer {
      height: rem-calc(100);

      .font-icon {
        margin-top: rem-calc(15);
      }
    }
  }

  .bausteineMainView {
    height: 100%;
  }

}
