.checkout_zusammenfassung-wrapper {

  .form-group.schema-form-submit.continue-margin-top {
    float: none;
  }

  table {
    td.red {
      color: $red;
    }
    td.green {
      color: $green;
    }
  }

  .button-default.red {
    background-color: $red;
    border-color: $red;
  }

  .error-text {
    color: $red;
  }
}
