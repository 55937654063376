.consent-dialog-wrapper {
  .asana-consent-dialog {
    .uc-embedding-wrapper {
      position: relative;
      margin-top: 240px;
    }
  }
}

@media screen and (max-width: 720px) {
  .consent-dialog-wrapper {
    .asana-consent-dialog {
      .uc-embedding-wrapper {
        margin-top: 160px;
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .consent-dialog-wrapper {
    .asana-consent-dialog {
      .uc-embedding-wrapper {
        margin-top: 200px;
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .consent-dialog-wrapper {
    .asana-consent-dialog {
      .uc-embedding-wrapper {
        margin-top: 240px;
      }
    }
  }
}
