// Everything which belongs to the base layout (without branding) and cannot be configurated in _settings.scss goes here

.form-padding {
  .row .columns {
    padding-right: 3px;
    padding-left: 3px;
  }

  input:not([type]), #{text-inputs(all, 'input')}, select {
    margin: 0px;
  }
  .form-group {
    margin: 0 0 $form-spacing 0;
  }
}

@media #{$medium-up} {
  .form-padding .row .columns {
    padding-right: 5px;
    padding-left: 5px;
  }
}

.helpText {
  margin-top: 3px;
  color: $red;
  font-size: smaller;
}

#root-view {
  min-height: 100%;
  overflow-x: hidden;
}

.red {
  color: red;
}
.green {
  color: green;
}
.yellow {
  color: rgb(255, 162, 0);
}
.box {
  @include shadow();
}

.bigbox {
  margin-bottom: 20px;
  overflow: hidden;
  background-color: rgb(238 242 241);

  @include shadow();

  .main {
    background-color: rgb(238 242 241) !important;
  }

  &.overflow-show {
    overflow: visible;
  }

  &.blue {
    .main {
      background: #2a586c;
      text-align: center;
    }
  }
  &.transparent {
    .main {
      background: transparent;
      text-align: center;
    }
  }

  &.centered {
    position: relative;

    .mainWrapper {
      padding: 0 15%;
    }
  }

  .header {
    padding: 8px 5px 8px 20px;
    background: rgba(255,255,255,0.77);
    position: relative;
    border-radius: $border-radius $border-radius 0 0;

    > span {
      text-transform: uppercase;
      font-size: 10px;
      font-weight: 500;
    }

    a.go {
      font-size: 12px;
    }
  }

  .main {
    background: #fff;
    padding: 13px;
  }
}

.main-section {
  height: 100%;
  padding-top: rem-calc(64px);
}


.button {
  border-radius: $border-radius;
}

/* all */
::-webkit-input-placeholder {
  color: #9B9B9B;
  font-weight: 300;
}

::-moz-placeholder {
  color: #9B9B9B;
  font-weight: 300;
} /* firefox 19+ */

:-ms-input-placeholder {
  font-weight: 300;
  color: #9B9B9B;
} /* ie */

input:-moz-placeholder {
  font-weight: 300;
  color: #9B9B9B;
}

.progress {
  padding: 0;
}

.off-canvas-wrap {

  &.move-right {
    height: 100%;
  }
}

.overlay-menue {
  position: fixed;
  top: 65px;
  left: 0;
  z-index: 10;
  width: 100%;
  color: #fff;
  display: none;

  > .row {
    background: #004262;
    padding: 35px 0;
  }

  a {
    color: #fff;
  }

  ul {
    display: block;
    margin-left: 45px;

    li {
      width: 100%;
      display: block;
      margin: 8px 0;
      background: none !important;

      a {
        background: none !important;
        line-height: inherit !important;
        padding: inherit !important;
      }

      &.title {
        text-transform: uppercase;
        margin-bottom: 20px;
        position: relative;
        &:before {
          content: '';
          width: 112px;
          position: absolute;
          bottom: -6px;
          border-bottom: 2px solid #002a3f;
        }
      }

      &.adv {
        img {
          width: 90%;
          border-radius: $border-radius;
        }
      }
    }
  }
}

.tabs-content .content {
  margin-bottom: 3em;
  background: $whitetransparent;
  padding: 13px;
  height: calc(100% - 134px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.tabbable .tabs {
  padding-bottom: 2px;
  dd+dd {
    margin-left: 2px;
  }
}

@mixin display_a_b($a,$b) {
  $sum: $a+$b;
  $sa: ($a/$sum) * 100%;
  $sb: ($b/$sum) * 100%;
  > sf-decorator {
    &:nth-child(1) {
      width: $sa;
      padding-right: 10px;
    }
    &:nth-child(2) {
      width: $sb;
      padding-left: 10px;
    }
  }
}

@mixin display_a_b_c($a,$b,$c) {
  $sum: $a+$b+$c;
  $sa: ($a/$sum) * 100%;
  $sb: ($b/$sum) * 100%;
  $sc: ($c/$sum) * 100%;
  > sf-decorator {
    &:nth-child(1) {
      width: $sa;
      padding-right: 10px;
    }
    &:nth-child(2) {
      width: $sb;
      padding-right: 10px;
      padding-left: 10px;
    }
    &:nth-child(3) {
      width: $sc;
      padding-left: 10px;
    }
  }
}


// Form layout
.display_1_1_1,
.display_1_2_2,
.display_2_2_1,
.display_1_3_3,
.display_1_1,
.display_1_2,
.display_1_3,
.display_1_4,
.display_1_5,
.display_2_1,
.display_3_1,
.display_1_12,
.display_4_1,
.display_5_1 {
  > sf-decorator {
    display: inline-block;
  }
  // sf-decorator + sf-decorator {
  //   padding-left: 20px;
  // }
}


.display_1_1_1 {
  @include display_a_b_c(1,1,1);
}
.display_1_2_2 {
  @include display_a_b_c(1,2,2);
}
.display_2_2_1 {
  @include display_a_b_c(2,1,1);
}
.display_1_3_3 {
  @include display_a_b_c(1,3,3);
}
.display_1_1 {
  @include display_a_b(1,1);
}
.display_1_2 {
  @include display_a_b(1,2);
}
.display_2_1 {
  @include display_a_b(2,1);
}
.display_1_3 {
  @include display_a_b(1,3);
}
.display_3_1 {
  @include display_a_b(3,1);
}
.display_1_4 {
  @include display_a_b(1,4);
}
.display_4_1 {
  @include display_a_b(4,1);
}
.display_1_5 {
  @include display_a_b(1,5);
}
.display_5_1 {
  @include display_a_b(5,1);
}
.display_1_12 {
  @include display_a_b(1,12);
}

.form-control-feedback {
  display: none;
}

.sf-no-padding > sf-decorator {
  &:nth-child(1),
  &:nth-child(2),
  &:nth-child(3) {
    padding-left: 0px;
    padding-right: 0px;
  }
};

.sf-no-help-blocks .help-block {
  display: none;
}

.center {
  text-align: center;
}

#footer {
  clear: both;
  padding-top: 6px;
  width: 100%;
  padding-left: 38.5px;
  padding-right: 38.5px;
  // margin-bottom: 19px;

  .main-section & {
    padding-left: 0;
    padding-right: 0;
  }

  img {
    max-width: 100px;
    float: left;

    @media #{$xsmall-only}, #{$small-only} {
      width: 100%;
      display: block;
      margin-bottom: 13.5px;
      margin-top: 6.5px;
    }
  }

  span#copyright {
    float: right;

    @media #{$xsmall-only}, #{$small-only} {
      display: block;
      width: 100%;
      text-align: center;
    }

    a {
      color: $primary;
      &:hover {
        color: $secondary;
      }
    }
  }
}

.moduleSelect {
  margin-right: auto;
  margin-left: auto;
  padding-right: 10px;
}

.noscroll {
  position: fixed;
  overflow: hidden;
  left: 0;
  right: 0;
}

@media #{$xsmall-only}, #{$small-only} {
  #beraterView {
    .tabbable .tabs dd>a {
      padding: .8rem;
    }
  }

  .bigbox{
    .header {
      > span {
        font-size: 11px;
      }
    }

    .centered .mainWrapper {
      padding: 0 5%;
    }
  }

  #footer {
    padding-bottom: 12px;

    img {
      position: relative;
      display: block;
      left: 50%;
      margin-left: -50px;
    }
  }
}

.footer-links {
  .items {
    display: inline-block;
    margin-left: 1rem;

    &:first-child {
      margin-left: 0;
    }
  }
}

p.error {
  color: red;
}
