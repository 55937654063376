// This style will take effect on benefit_details_layout in the end
.benefit-vouchers-wrapper {

}

.benefit-bonago-wrapper {


  .overview_map {
    margin-left: 10px;
  }
  
  #retailer_map{
    height: 100%;
  }

  .benefit-bonago-info-wrapper{

  }

  .benefit-bonago-vouchers-wrapper{
    background: rgb(233, 233, 233);
  }

  .toggable-content-content {
    max-height: none !important;
  }
  .voucher-value-wrapper {
    position: relative;
    background: $secondary;
    text-align: center;
    padding: 0;

    // @include toggable-content('.voucher-selector-content', '.voucher-selector-open .voucher-selector-content', 500);


    .voucher-selector {
      display: block;
      cursor: pointer;

      label {
        width: 50%;
        text-align: right;
      }

      .voucher-value {
        text-align: left;
        width: 50%;
      }
    }

    .voucher-selector-toggler {
      padding: rem-calc(15) 0;
    }

    .voucher-selector-content {
      .euro-after,
      .voucher-value,
      label {
        color: rgba(255, 255, 255, 0.7);
      }

      .voucher-selector-options:hover {
        .euro-after,
        .voucher-value,
        label {
          color: white;
        }
      }

      .voucher-selector-options:last-child {
        margin-bottom: rem-calc(15);
      }
    }

    @include rotating-toggler-arrow('.voucher-selector-arrow', '.voucher-selector-open', 0.3s);
    .voucher-selector-arrow {
      color: white;
      position: absolute;
      right: rem-calc(15);
      top: rem-calc(22);
    }
  }
}
