$header-background-image-height: 237px;
$base-header-height: 64px;

header .vcpHeader {
  // &:after{
  //   content: ' ';
  //   width: 100%;
  //   // height: $header-background-image-height;
  //   background: url($bg-img) no-repeat center center fixed !important;
  //   -webkit-background-size: cover !important;
  //   -moz-background-size: cover !important;
  //   -o-background-size: cover !important;
  //   background-size: cover !important;
  //   height: $base-header-height;
  //   background-size: cover;
    display: block;
  //   position: absolute;
    z-index: -2;
  //   border-bottom: 2px solid rgba(0, 59, 85, 0.25);
  // }
  // margin-bottom: $header-background-image-height - $base-header-height;
  height: $base-header-height;


}
.vcpHeader.top-bar ul.title-area img {
  height: 1.33333rem;
}


#vcp {
  height: 100%;
  overflow: scroll;
  .row {
    height: 100%;
  }
  &:before {
    content: ' ';
    width: 100%;
    height: $header-background-image-height;
    background: rgba(0, 59, 85, 0.25) url('/assets/img/vcp_header_bg.jpg');
    background-position: center right;
    background-size: cover;
    display: block;
    // position: absolute;
    top: -$base-header-height;
    z-index: -1;
    border-bottom: 2px solid rgba(0, 59, 85, 0.25);
  }
  &:after {

  }
  // margin-top: $header-background-image-height - $base-header-height;
  #main-view {
    overflow: inherit;
  }
}

@mixin solid-list() {
  min-height: 45px;
  border-bottom: 1px solid rgba(0, 59, 85, 0.25);
  border-radius: 3px;
  line-height: 45px;
  margin: 4px 0;
  clear: both;
  > a {
    display:inline-block;
    float: left;
  }
}

.list-control {
  background: #fff;
  @include solid-list();
  margin: 0;
  span.title {
    font-size: 13px;
    margin-left: 20px;
    text-transform: uppercase;
  }
}

ul.biglist {
  list-style: none;
  margin: 4px 0;
  padding: 0;
  li {
    a, a:hover {
      color: #fff;
    }
    > .solid-list {
      font-size: 18px;
      color: #fff;
      background: rgba( $primary, 0.65);
      span {
        margin-right: 10px;
        margin-left: 15px;
      }
      .stats1, .stats2 {
        span {
          margin: 0px;
        }
      }
      &.freigegeben {
        background: rgba( $primary, 0.4);
      }
      &.white {
        background: rgba(255, 255, 255, 0.45);
        &.freigegeben {
          background: rgba(204, 204, 204, 0.75);
        }
        color: $primary;
        &.redbackground {
          background: rgba(255, 0, 0, 0.45);
        }
        &.red {
          color: #a00;
        }
        &.green {
          color: $green;
        }
      }
      @include solid-list();
    }

    &.location {
      span.city {
        // width: 480px;
      }
    }
    span {
      font-size: 15px;
      &.city {
        white-space: nowrap;
        // overflow: hidden;
        // text-overflow: ellipsis;
        display: inline-block;
        // width: 230px;
        height: 15px;
        line-height: 15px;
        vertical-align: middle;
      }
      &.city, &.stats1, &.stats2 {
        margin-right: 30px;
      }
      &.stats2 {
        color: $green-light;
      }
    }
    .right label {
      margin: 0 15px 0 10px;
    }

    img.expander {
      max-height: 0.4em;
      margin: 0px 18px 0 20px;
    }
    > .city {
      text-transform: uppercase;
      font-size: 13px;
    }
    ul li {
      clear: right;
      color: $primary;
      .right span, a span {
        color: $lightgrey;
        &.green {
          color: $green;
        }
      }


      &.expand {
        background: #fff;
        &.red {
          background: rgba(255, 0, 0, 0.45);
        }
        &.green {
          color: $green;
        }
        height: auto;
        .solid-list {
          border-bottom: none;
        }
      }
      .expanded {
        padding: 0 35px;
        .title {
          text-transform: uppercase;
          font-size: 12px;
        }
        .right {
          background: #f5f5f5;
        }
        .red .right {
          background: none;
        }
        .bonus-history {
          margin: 0 25px;
          .column {
            padding: 0 10px;
            &.red {
              // background: rgba(255, 0, 0, 0.45);
              color: #a00;
            }
            &.green {
              background: rgba(0, 255, 0, 0.45);
            }
            &.gray, &.gray span, &.gray .right span {
              color: #aaa;
            }
            .wrapper {
              border-bottom: 1px $grey solid;
            }
            .right {
              background: none;
            }
          }
          span {
            line-height: 45px;
          }
        }
        table {
          width: 100%;
          padding: 0 25px;
          input { margin: 0; }

          td {
            padding: 0 10px;
          }

          td:first-child{
            width: 40px;
          }
          td.input {
            width: 190px;
          }

          textarea {
            height: 100px;
          }
        }
      }
    }
  }
}

.list-control {
  .has-dropdown {
    position: relative;
    float: left;
    margin: 0 10px;

    &:hover:before {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 3px 4px 3px;
      position: absolute;
      bottom: 0;
      left: 50%;
      margin-left: -2px;
      border-color: transparent transparent #ffffff transparent;
    }
    &:hover {
      .dropdown {
        display: block;
      }
    }

    .dropdown {
      border-radius: 3px;
      overflow: hidden !important;
      display: none;
      background: #fff;
      position: absolute;
      width: 110px;
      padding: 10px;
      text-align: center;
      list-style: none;
      margin: 0;

      -webkit-box-shadow: 0px 1px 6px -1px rgba(0, 0, 0, 0.4);
      -moz-box-shadow: 0px 1px 6px -1px rgba(0, 0, 0, 0.4);
      box-shadow: 0px 1px 6px -1px rgba(0, 0, 0, 0.4);

      a {
        line-height: 35px;
        font-size: 13px;
        color: $secondary;
      }
      img {
        max-height: 18px;
        max-width: 18px;
        margin-right: 8px;
      }
    }

  }


}

