.cockpit-datev-list {
  .datev-view {
    margin-bottom: 2px;
  }

  .cockpit_toggable-content {
    padding: .66667rem;
    border: 0;
    border-radius: 5px;
    margin: 0;
    background: #fff;
  }

  .cockpit__form-content {
    padding: 0;
  }

  .top-bar-section {
    ul {
      display: block;
      text-align: right;

      li {
        float: none;

        & > a.button {
          top: 0;
          display: inline-block;
          width: auto;
          height: auto;
        }
      }
    }
  }
}
