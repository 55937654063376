@import 'kinder/assistant/assistant';

p.small {
  font-size: .8rem;
  margin-top: .4rem;
}

.zebra {
  tbody {
    td {
      text-align: right;
    }

    tr:nth-child(even) {
      th, td {
        background-color: #f9f9f9;
      }
    }
  }
}
