/* You can add global styles to this file, and also import other style files */
@import "app-ajs/branding/standard/branding";
@import "app-ajs/app";

#app-loader {
  display: block !important;
  width: 100%;
  height: 100%;
  text-align: center;
}

.error-snackbar {
  min-width: 400px;
  background: #e65441;
  color: white;
}

.blue-snackbar {
  min-width: 500px;
  background: #0496d8;
  color: white;
}

.warning-snackbar {
  min-width: 500px;
  background: #d8b504;
  color: white;
}

.mat-menu-item:hover:not([disabled]),
.mat-menu-item.cdk-program-focused:not([disabled]),
.mat-menu-item.cdk-keyboard-focused:not([disabled]),
.mat-menu-item-highlighted:not([disabled]) {
  background: #005980;
}

.userPreferencesDialogResponsive {
  width: 60%;
}

@media only screen and (max-width: 760px) {
  .userPreferencesDialogResponsive {
      width: 100%;
  }
}
@media only screen and (min-width: 2560px) {
  .userPreferencesDialogResponsive {
      width: 30%;
  }
}
