@import
  'scss/settings',
  './node_modules/foundation-sites/scss/foundation',
  'scss/foundation-extension';

@import
  'scss/default';

@import
  'scss/fonts',
  'scss/fonticon';

@import "../assets/fontawesome/scss/fontawesome";
@import "../assets/fontawesome/scss/solid";
@import "../assets/fontawesome/scss/brands";

@import 'scss/quill.snow';

@import 'components/styles';

@import
  './node_modules/material-icons/iconfont/material-icons',
  './node_modules/select2/select2',
  'scss/flexbox',
  'scss/magnific-popup-theme',
  './node_modules/ng-dialog/css/ngDialog',
  './node_modules/ng-dialog/css/ngDialog-theme-default',
  './node_modules/angular-awesome-slider/src/css/angular-awesome-slider',
  './node_modules/angular-carousel/src/css/angular-carousel',
  './node_modules/angular-wizard/dist/angular-wizard.min',
  './node_modules/ui-select/dist/select',
  // '../node_modules/angularjs-datepicker/src/css/angular-datepicker',
  './node_modules/@angular/material/prebuilt-themes/deeppurple-amber',
  'scss/plugins/datepicker',
  'scss/breadcrumb',
  'scss/dialog',
  'dialogs/contactAdvisor/contactAdvisor',
  'scss/buttons',
  'scss/shared',
  'scss/header',
  'scss/notification',
  'scss/calendar',
  'scss/statistics',
  'directives/directives';



@import
  'scss/decorators';

@import
  'modules/modules',
  'helpers/blank.layout',
  'helpers/layout',
  'modules/authentication/login',
  'modules/vcp/moduleselect/bausteine',
  'modules/landing/_landing',
  'scss/animate';

@import './scss/car-leasing';

#appLoading {
  display: none;
}
.deferred-bootstrap-loading #appLoading {
  display: block !important;
  width: 100%;
  height: 100%;
  text-align: center;
}

#appError {
  display: none;
}
.deferred-bootstrap-error #appError {
  display: block !important;
  background: red;
}

iframe {
  border: none;
}

.video,
.video-wrap,
.ngdialog-content {

  iframe {
    margin: 0 auto;
    width: 100%;
    height: 100%;
  }
}

.uc-embedding-container {
  width: inherit !important;
  min-height: inherit !important;

  .uc-embedding-wrapper {
    min-width: calc(100% - 12px);

    /* Hide scrollbar */
    scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera */
    }
  }
}
