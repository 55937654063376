.kategorie-menu-wrapper {

  .title {
    @include kategorie-menu-element;

    display: none;

    &:first-child {
      border-bottom: 0;
    }
  }

  ul {
    list-style: none;
    margin: 0;
  }

  margin-bottom: rem-calc(15px);

  li {
    @include kategorie-menu-element;
    span {
      color: $primary;
    }

    &.active a {
      background-color: rgba($primary, .9);
      color: #fff;
      span {
        color: #fff;
      }
    }

    a:hover {
      span {
        color: $secondary;
      }
    }
  }

  @media #{$medium-up} {
    #shop-main-categories,
    .shop-categories {
      max-height: none;
    }
  }
}
