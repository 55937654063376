.home-wrapper {

  .arrow-icon-container {
    background-color: rgba(0, 60, 86, 0.51);
    color: #ffffff;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: rem-calc(30px);
    font-size: rem-calc(20px);
    z-index: 1;

    &.right {
      right: 0;
      padding-left: rem-calc(5px);
      border-radius: rem-calc(3px) 0 0 rem-calc(3px);
    }
    &.left {
      left: 0;
      padding-right: rem-calc(5px);
      border-radius: 0 rem-calc(3px) rem-calc(3px) 0;
    }
    .font-icon {
      vertical-align: top;
      color: $icon-color-menu;
    }
  }

  .rn-carousel-indicator {
    position: absolute;
    top: rem-calc(15);
    right: rem-calc(15);
    background-color: rgba(128,128,128,0.5);
    padding: rem-calc(5);
    border-radius: rem-calc(5);
    span {
      margin-right: rem-calc(10);
      &:last-child {
        margin-right: 0
      }
    }
  }

  #bigAdvertizement {
    list-style: none;
    background: #fff;
    position: relative;
    height: rem-calc(355);
    overflow: hidden;
    text-align: center;

    li {
      .img {
        height: 100%;
        width: 100%;
        background-size: cover;
      }
    }
    .priceTag{
      position: absolute;
      right: 0;
      bottom: rem-calc(20);
      background: $primary;
      color: #fff;
      text-transform: uppercase;
      border-radius: rem-calc(3px )0 0 rem-calc(3px);
      span {
        display: block;
        &.price {
          font-size: 1.2em;
        }
      }
      &.big {
        padding: rem-calc(22px )rem-calc(20px);
      }
    }
  }

  .categories {
    margin-left: rem-calc(-16)px !important;
    .category {
      padding-right: rem-calc(16px);
      position: relative;
      cursor: pointer;
      overflow: hidden;
      .heading {
        position: absolute;
        color: #fff;
        position: absolute;
        top: rem-calc(16px);
        left: rem-calc(32px);
        font-size: rem-calc(26px);
        // white-space: pre;
      }
    }
  }

  #currentOffer {
    float: right;
  }

  .customPadding {
    padding-top: rem-calc(20px);
    padding-right: rem-calc(16px);
  }

  #currentProducts {
    .main {
      background: #fff;
      height: rem-calc(220px);
      ul {
        list-style: none;
        li {
          width: rem-calc(164px);
          text-align: center;
          padding-top: rem-calc(16px);
          display: inline-block;
          vertical-align: top;

          &.tech-offer {
            width: 20%;
            .title {
              font-size: .75rem;
            }
          }
          img {
            width: rem-calc(85px);
            margin-bottom: rem-calc(10px);
            // max-height: 120px;
            display: inline-block;
          }
          span {
            white-space: pre;
            display: block;
            text-align: center;
            font-size: rem-calc(14px);
            line-height: rem-calc(16px);
            &.price {
              color: $secondary;
              margin-top: rem-calc(16px);
            }
          }
        }
      }
    }
  }
}
