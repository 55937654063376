@import 'home/benefit_bonago_home';
@import 'details/benefit_bonago_details';
@import 'maps/overview/maps_overview';

.benefit-bonago-wrapper {
  margin-top: 20px;

  #side-view .main ul li {
    &.blue.red a {
      color: $red;
    }

    li {
      margin-left: 0;

      .cart {
        &.left {
          width: 30%;
        }

        &.right {
          width: 50%;
          margin-right: 20px;
        }
      }

      .font-icon {
        position: static;
        margin-left: auto;
        margin-top: 18px;
        font-size: 1.6rem;
        display: inline-block;

        cursor: pointer;

        &.orange {
          color: $orange;
        }
      }

      .icon-delete {
        color: $red;
        margin-left: 10px;
      }
    }

    .key {
      max-width: 150px;
    }
  }

  .kategorie-wrapper {
    .box-list-box-wrapper {
      padding: 0 0 rem-calc(10) rem-calc(10);
    }
  }

  .benefit-bonago-list-wrapper {
    .box-list-wrapper {
      > .row {
        margin: 0;
        margin-right: rem-calc(-15);
      }
    }

    .box-list-box {
      margin-right: rem-calc(15px);
      margin-bottom: rem-calc(15px);

      &__content img {
        margin: auto;
        display: block;
        width: 60%;
        margin-top: 20%;
      }

      &__title {
        text-align: center;
        color: $font_primary;
      }
    }
  }
}
