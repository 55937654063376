$footer-height: rem-calc(30);
$footer-height-small-screen: rem-calc(75);

.allscroll {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

#blank-layout {
  height: 100%;
  margin-bottom: -$footer-height;

  & + #footer {
    height: $footer-height;
    clear: both;
    // padding-top: rem-calc(22);
    width: 100%;
    padding-left: rem-calc(38.5);
    padding-right: rem-calc(38.5);
    background: rgba(255,255,255,.25);

    hr {
      display: none;
    }
  }
}

// @media only screen and (max-width: 512px) {
//   #blank-layout {
//     margin-bottom: -$footer-height-small-screen;
//
//     & + #footer {
//       height: $footer-height-small-screen;
//     }
//   }
// }

@media #{$xsmall-only}, #{$small-only} {
  #blank-layout {
    height: auto;
    min-height: 100%;
    margin-bottom: -$footer-height-small-screen;

    & + #footer {
      padding-top: rem-calc(15);
      height: $footer-height-small-screen;
    }
  }
}

// @media only screen and (max-height: 479px) {
//   #blank-layout {
//     margin-bottom: -$footer-height;
//
//     & + #footer {
//       // padding-top: rem-calc(22);
//       height: $footer-height;
//     }
//   }
// }
//
// @media only screen and (max-height: 420px) and (max-width: 512px) {
//   #blank-layout {
//
//     & + #footer {
//       height: auto;
//     }
//   }
// }
//
// @media
//   only screen and (max-height: 320px),
//   only screen and (max-height: 375px) and (max-width: 667px) {
//     #blank-layout {
//       margin-bottom: rem-calc(20);
//     }
// }
