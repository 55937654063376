.breadcrumb {
  list-style: none;
  margin: rem-calc(30px) 0;

  li {
    display: inline;

    a, span {
      font-weight: 500;
    }
    span {
      color: #818388;
    }

    &:before {
      content: '➜';
      margin: 0 rem-calc(10px);
      color: #818388;
    }

    &:first-child:before {
      display: none;
    }
  }



}
