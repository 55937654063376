.kategorie-wrapper {
  .sub-category-wrapper {
    padding: 0;
    padding-bottom: rem-calc(15px);

    @media #{$medium-up} {
      padding-left: rem-calc(15px);
    }
  } // .sub-category-wrapper
}
