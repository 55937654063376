@mixin kategorie-menu-element {
  margin-bottom: rem-calc(2);

  > span,
  > a {
    display: block;
    background: #fff;
    height: 49px;
    line-height: 49px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 15px;
  }

  .icon-wrapper {
    float: left;
    width: 40px;
    line-height: 37px;
  }
}

@import
  'produkte/templates/kategorie_menu',
  'produkte/produkte';

.cursor-pointer {
  cursor: pointer;
}

#submenue {
  background: rgba(255, 255, 255, 0.88);
  text-align: center;
  border-bottom: 1px solid $border-color;
  margin-bottom: rem-calc(14px);

  ul {
    list-style: none;
    display: inline-block;
    padding: 0;
    margin: 0;
    line-height: rem-calc(45px);

    li {
      display: inline-block;
      margin: 0 rem-calc(16px);
      text-transform: uppercase;

      a {
        color: $secondary;
        &.disabled {
          color: $grey;
        }
      }

      &.active a {
        color: $primary;
      }
    }
  }
}
