@import 'home/vorsorge_home';

#vorsorge_main {
  // margin-top: 3.5rem;

  h1 {
    color: $primary;
    font-size: rem-calc(28px);
  }

  h2 {
    margin: 0 10px 20px;
    font-size: rem-calc(20px);
  }

  section {
    margin-bottom: rem-calc(15px);
  }

  .shadow {
    @include shadow();
  }

  .header {
    font-weight: 400;
    padding: rem-calc(12px) rem-calc(20px);
  }

  .footer,
  .header {
    background-color: $lightgrey3;
  }

  .footer {
    padding-top: rem-calc(10px);
    border-top: rem-calc(1px) solid #D0DADD;
  }

  .video {
    display: block;
    height: 0;
    position: relative;
    padding-bottom: rem-calc(360px);
    background-size: rem-calc(65px), cover;
    background-repeat: no-repeat;
    background-position: center center;
    text-indent: -100%;
    overflow: hidden;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &.responsive {
      padding-bottom: 64.25%;
    }
  }

  .detail-view {

    p.small {
      font-size: rem-calc(10px);
    }

    .right-gap {
      padding-right: rem-calc(40px);
    }

    ul {
      $left-padding: 30px;
      list-style: none;
      margin-left: rem-calc($left-padding);

      li {
        font-size: rem-calc(18px);
        position: relative;
        padding: rem-calc(10px) 0;

        &:last-child {
          border-bottom: 0;
        }

        .font-icon {
          position: absolute;
          top: rem-calc(14px);
          left: rem-calc($left-padding * -1 - 4);
        }
      }
    }
  }

  .blocks {
    h3 {
      min-height: rem-calc(40);
      font-size: 1.3rem;
      font-weight: 500;
      margin-bottom: rem-calc(20px);
    }

    .block {
      @include shadow();
      border-radius: rem-calc(3px);
      background-color: white;

      .video {
        padding-bottom: rem-calc(152px);
      }

      p {
        min-height: rem-calc(170);
      }
    }

    .columns {
      padding: rem-calc(10px);
    }

    .more-info {
      display: block;
      text-align: center;
    }
  }

  .upload_zone_wrapper {
    background-color: #E5F4FB;
    background-clip: content-box;
    padding: rem-calc(10px);
    border: rem-calc(2px) dashed #E5F4FB;
  }

  .upload_zone {
    padding: rem-calc(10px);

    h4 {
      font-size: rem-calc(18);
      margin-bottom: 0;
    }

    .icon {
      $size: 18px;
      width: rem-calc($size);
      height: rem-calc($size);
      margin-right: rem-calc(10px);
    }
  }

  .form-wrapper {
    background-color: white;
    padding: rem-calc(15px);

    p {
      font-size: rem-calc(12px);
      line-height: 1.4;
    }

    .small {
      font-size: rem-calc(9px);
    }
  }
}

@media #{$xsmall-only}, #{$small-only} {
  #vorsorge_main {

    section {
      padding-left: rem-calc(15px);
      padding-right: rem-calc(15px);
    }
  }
}
