._720kb-datepicker-calendar-header,
._720kb-datepicker-calendar-header a,
._720kb-datepicker-calendar-header ._720kb-datepicker-calendar-month span {
  color: white;
}

._720kb-datepicker-calendar-header:nth-child(odd) {
  background: $primary;
}
._720kb-datepicker-calendar-header:nth-child(even) {
  background: $primary;
}

._720kb-datepicker-calendar-days-header {
  background: $topbar-bg-color;
}

datepicker select, datepicker select:focus, datepicker select:hover,
.datepicker select, .datepicker select:focus, .datepicker select:hover,
[datepicker] select, [datepicker] select:focus, [datepicker] select:hover {
  background: $primary;
}
