/////////////////////////////////////////     BRAND

$isVolksbank: false;
$useWhiteHeader: false;

$white: #fff;

$brand: null !default;
$brand-logo: "logo" !default;

$primary: #003b55 !default;
$secondary: #0496d8 !default;
$neutral: #9b9b9b !default;
$call-to-action: #feca00 !default;
$font-primary: #111 !default;
$link-color: $secondary !default;
$body-bg-gradient-base: #b1cbc5 !default;
$body-bg-gradient-end: lighten($body-bg-gradient-base, 6.1%) !default;

// INDIVIDUAL VARIABLES NOT FOR EVERY BRANDING
$warning: #e20024 !default;

// $dropdown-link-bg-hover-important-overwrite: $white;
// BORDER for Sidebar and components within neo selfservice
// $side-component-border-color: transparent !default;
// $side-component-border-width: 0 !default;

// STICKY NAV if not primary color
$topbar-bg-color_sticky: $primary;
// $topbar-border-style: thin solid rgba($secondary, .3) !default; // optional border for topbar
$off-canvas-icon-color: $white; // should be changed if background is too bright

// OFF Canvas link color if background is to bright
$off-canvas-link-color: $white !default;

// No branding for these now => should be moved to/set in _settings.scss
$border-color: #729aa5 !default;
$border-color-dark: #004459 !default;
$separator-color: #a9bcc5 !default;
$link-color-hover: lighten($secondary, 10%) !default;

// Unrelated for branding as the names not serving a purpose, just a color alias!
$grey: #9b9b9b !default;
$lightgrey: #4e4e4e !default;
$lightgrey2: #d2dde3 !default;
$lightgrey3: #f5f5f5 !default; // for example: in product details background
$green: #34bd18 !default;
$green-light: #81d135 !default;
$red: #e20024 !default;
$whitetransparent: rgba($white, .25) !default;


// Volksbank
@if $isVolksbank {
  $primary: #004b9b;
  $secondary: #e64b00;
  $call-to-action: #1774e5;
  $font-primary: #004b9b;
  $link-color: $secondary;
  $body-bg-gradient-base: #3397e7;
  $body-bg-gradient-end: $white;

  $border-color: #49484c;
  $border-color-dark: #2f2e32;
}

/////////////////////////////////////////     COMPONENT BRANDING:

/////////////////////////////////////////     BUTTON

$button-function-factor: 10% !default;

/////////////////////////////////////////     TOP BAR

$topbar-bg-color: rgba($primary, .25) !default;
$topbar-bg-color_darkened: rgba($primary, .9) !default;
$topbar-link-bg: none !default;
$topbar-link-bg-color-hover: $primary !default;
$topbar-link-bg-hover: $primary !default;
$topbar-link-bg-active: none !default;
$topbar-link-bg-active-hover: $primary !default;
$topbar-dropdown-label-bg: none !default;
$topbar-dropdown-link-bg: none !default;
$topbar-dropdown-link-bg-hover: none !default;
$topbar-dropdown-bg: none !default;

$topbar-link-color: $white !default;
$topbar-link-color-hover: $white !default;
$topbar-link-color-active: $white !default;
$topbar-link-color-active-hover: $white !default;

/////////////////////////////////////////     OFF CANVAS

$off-canvas-bg-hover: #053549 !default;

/////////////////////////////////////////     DASHBOARD

$dashboard-welcome-bg: $white;
$dashboard-welcome-opacity: .85;
$dashboard-welcome-txt-color: $primary;

/////////////////////////////////////////     BACKGROUND

$body-img: linear-gradient($body-bg-gradient-base, $body-bg-gradient-end) !default;
$body-bg: #{$body-img} left top/cover no-repeat fixed !default;

/////////////////////////////////////////    FORMS

$checkbox_unchecked: "/assets/img/icon-check_unchecked.png" !default;
$checkbox_checked: "/assets/img/icon-check_checked.png" !default;
$checkbox_unchecked_white: "/assets/img/icon-check_unchecked_white.png" !default;
$checkbox_checked_white: "/assets/img/icon-check_checked_white.png" !default;

/////////////////////////////////////////     FONT ICONS

$icon-color-dark: $primary !default;
$icon-color-light: $secondary !default;
$icon-color-default: $icon-color-dark !default;
$icon-color-alt: $icon-color-light !default;
$icon-color-menu: $white !default;

@if $useWhiteHeader {
  $topbar-bg-color: rgba($white, .75);
  $topbar-bg-color_darkened: rgba($white, .9);
  $topbar-link-bg-color-hover: $primary;
  $topbar-link-bg-hover: $primary;
  $topbar-link-bg-active: $primary;
  $topbar-link-bg-active-hover: $primary;

  $topbar-link-color: $primary;
  $topbar-link-color-hover: $white;
  $topbar-link-color-active: $white;
  $topbar-link-color-active-hover: $white;

  $topbar-bg-color_sticky: $white;
  $off-canvas-link-color: $primary;
  $off-canvas-icon-color: $primary;
  $icon-color-menu: $primary;
}
