.meineakte-wrapper {
  .tabs-content {
    margin-top: 2px;
  }

  .allgemeine-info .mainWrapper {
    td:first-child {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .versicherungen .mainWrapper,
  .verguetung .mainWrapper {
    padding-top: rem-calc(15px);
    padding-bottom: rem-calc(15px);
  }

  .allgemeine-info .mainWrapper {
    .vn-table table {
      margin-top: rem-calc(5px);
    }
  }

  .weitere-services .title {
    padding-bottom: rem-calc(15px);
  }

  .service-boxes {
    margin: rem-calc(-7.5);
  }

  .service-box {
    width: 100%;
    padding-bottom: 75%;
    display: block;
    position: relative;
    // margin-right: rem-calc(10px);
    // margin-bottom: rem-calc(10px);
  }

  .service-box-content {
    margin: rem-calc(7.5);
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: white;

    $image-container-height: 70%;

    .image-container {
      height: $image-container-height;
      padding: 0 20%;

      img {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .text-container {
      text-align: center;
      height: 100% - $image-container-height;
      color: #222;

      > * {
        position: relative;
        display: inline-block;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .name {
      margin-right: rem-calc(4px);
    }

    .value {
      font-weight: 400;
    }
  }

  @media #{$xsmall-only}, #{$small-only} {
    .allgemeine-info .mainWrapper {
      @include mobile-table;
    }

    .versicherungen .mainWrapper,
    .verguetung .mainWrapper {
      .circletext {
        display: block;
        width: 100%;
        margin-bottom: rem-calc(28px);
      }
    }
  }

  .allgemeine-info,
  .verguetung,
  .versicherungen {
    // display: none;
  }
}


