#contactAdvisor {

  margin-top: 2rem;

  .padding-top {
    padding-top: 1rem;
  }

  .detail {

    &.margin-right {
      margin-right: 1rem;
    }
    // font-size: rem-calc(13px);

    &.email {
      text-align: left;
    }

    &.phone {
      text-align: left;
    }

    &.buttons {
      .button {
        margin: 0;
      }
    }

    &:last-child {
      border-bottom: 0;
    }

    button {
      margin-bottom: 0;
    }
  }

  .font-icon {
    font-size: 1.4rem;
    margin-right: rem-calc(20);
    position: relative;
    top: rem-calc(-2);
  }

  .avatar {
    margin: 0;

    .picture {
      width: rem-calc(120);
      height: rem-calc(120);
      border-radius: rem-calc(120);
      border: rem-calc(1) solid rgba(0,0,0,.4);
      overflow: hidden;
      display: inline-block;
    }

    .image {
      position: static;
      margin: 0;
      width: 100%;
    }

    .name {
      font-size: 1.2rem;
      margin: 1rem 0 .6rem;
    }
  }

  @media  #{$xsmall-only} {
    .xsmall-center {
      text-align: center;
    }
  }

}
