.basket-overview-checkout-wrapper {

  table {
    td.red {
      color: $red;
    }
    td.green {
      color: $green;
    }
  }

  .button-default.red {
    background-color: $red;
    border-color: $red;
  }

  .error-text {
    color: $red;
  }
}

.file-chooser-checkbox {
  div {
    display: inline;
  }
  a {
    display: inline;
  }
}


.contract_component {
  width:100%;
  height:600px;
  overflow-y:scroll;
  border: 1px solid black;
  .pdf-page-canvas {
    width: 100%;
  }
}
