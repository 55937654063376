$loginAuthWrapperColor: #fff !default;
$loginAuthWrapperTransparency: .85 !default;

video#bgvid {
    position: fixed;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -100;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    background: $body-bg;
}

.registrationWrapper,
.activationWrapper {
  background-color: rgba(255,255,255,.65);
  padding: rem-calc(20);
  margin-top: 45px;
  margin-bottom: 45px;
  z-index: 1;
}

.authWrapper {
  position: absolute;
  padding: rem-calc(20);
  width: rem-calc(420);
  text-align: center;
  display: inline-block;
  top: calc(45% - #{$footer-height}/4);
  left: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);

  background-color: rgba($loginAuthWrapperColor, $loginAuthWrapperTransparency);

  &.leftalign {
    text-align: left;
  }

  .passwordWrapper {
    position: relative;

    .passwordInput {
      padding-right: rem-calc(80) !important;
    }

    .showButton {
      position: absolute;
      width: rem-calc(60);
      padding-right: rem-calc(10);
      height: rem-calc(45);
      right: 0;
      top: 0;
      line-height: rem-calc(45);
      font-size: rem-calc(12);
      color: #AAA;
      cursor: pointer;
    }
  }

  h1.mainform {
    font-size: rem-calc(27);
    height: rem-calc(74);
    color: $primary;
    margin-bottom: rem-calc(42);
    font-weight: 300;
    background: transparent url(/assets/img/logo.login.png) no-repeat center center;
    background-size: contain;
    padding-left: 10.5rem;
    padding-top: 0.4rem;
    display: block;
  }


  .forgotpw {
    text-align: right;
    margin-top: rem-calc(5);
    margin-bottom: rem-calc(26.5);

    a {
      font-size: rem-calc(12);
    }
    .error {
      font-size: rem-calc(12);
      color: $red;
    }
  }

  .account_inactive {
    text-align: center;
    margin-top: rem-calc(5);
    margin-bottom: rem-calc(26.5);
    font-size: rem-calc(12);
    color: $red;
  }

  .feedback {
    position: absolute;
    width: rem-calc(320);
    left: 0;
    top: rem-calc(-34.5);
    background: $primary;
    color: $secondary;
    height: rem-calc(34.5);
    line-height: rem-calc(34.5);
    font-size: rem-calc(12);
  }

  .video-container {
    padding-bottom: 12px;

    .video {
      height: 220px;

      .uc-embedding-container {

        .uc-embedding-wrapper {
          min-height: calc(100% - 16px);
        }
      }
    }
  }

  .loginform {
    position: relative;

    input {
      font-weight: 300;
      border-style: none;
      margin: 0;
      border-radius: rem-calc(3);
      font-size: rem-calc(18);
      height: 3rem;
      padding: rem-calc(10) rem-calc(18);
      border-color: #4e4e4e;
      color: $font-primary;

      opacity: .7;

      &[name=username] {
        margin-bottom: 0.3rem;
        border-bottom-left-radius: rem-cala(0);
        border-bottom-right-radius: rem-cala(0);
      }

      & + input {
        border-top-left-radius: rem-calc(0);
        border-top-right-radius: rem-calc(0);
      }
    }
  }
}

@media only screen and (max-width: 512px) {
  .authWrapper {
    width: 100%;
  }
}

@media only screen and (max-height: 480px) and (max-width: 320px) {
  .authWrapper {
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    top: auto;
    margin-top: rem-calc(10);
  }
}

@if ($brand) {
  .authWrapper {
    h1.mainform {
      text-indent: -999em;
      padding-top: 0;
    }
    button,
    button:focus,
    button:hover {
      color: $secondary;
    }
  }
}

//.form-padding .row .location-selection .columns {
//  padding: 0;
//}

span.linker {
  font-size: smaller;
  position: relative;
  top: 0;
  right: 0;
  left: auto;
  display: inline-block;
  width: auto;
  float: right;
  color: $link-color;
  cursor: pointer;
}

span.blue {
  color: $link-color;
}

span.reg-text {
  display: inline-block;
  margin-left: 45px;
  margin-top: -40px;
}
span.reg-text-2 {
  display: inline-block;
  margin-left: 18px;
  margin-top: -40px;
}
.code {
  margin: 0 0 1.06667rem 0;

  .code-fields {
    display: flex;
    align-items: flex-start;
    max-width: 300px;
  }

  .code-field {
    width: 100%;

    input {
      overflow: hidden;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .form-group {
      margin-bottom: 0;
    }

    &-small {
      max-width: 50px;
    }
  }

  .code-separator {
    flex-shrink: 0;
    margin: 8px 10px;
  }
}

/*.location-selection {
  position: relative;
  list-style: decimal;
  padding: 0 0 0 .9rem;
  margin-bottom: 1rem;
  margin-left: 0;
  max-height: 115px;
  overflow-y: scroll;
  font-size: .8rem;

  //&:before {
  //  content: '';
  //  position: absolute;
  //  bottom: 0;
  //  left: 0;
  //  width: 100%;
  //  height: 10px;
  //  background: -moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); !* FF3.6-15 *!
  //  background: -webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); !* Chrome10-25,Safari5.1-6 *!
  //  background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); !* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ *!
  //  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
  //}

  &:after {
    content: '';
    display: table;
    clear: both;
  }

  .card {
    cursor: pointer;
    padding: 0 .5rem .25rem .25rem;

    &:nth-child(even) {
      padding-right: 0;
    }
  }

  .address {
    padding: .25rem;

    .name {
      font-size: .7rem;
      font-weight: bold;
    }

    .street,
    .city {
      font-size: .6rem;
    }

    &:hover {
      background: lighten($secondary, 45);
    }

    &.active {
      background: lighten($secondary, 45);
      color: complement($secondary);
    }
  }
}*/

//
// [ui-view].ng-enter, [ui-view].ng-leave {
//   position: absolute;
//   left: 0;
//   right: 0;
//   -webkit-transition:all .5s ease-in-out;
//     -moz-transition:all .5s ease-in-out;
//     -o-transition:all .5s ease-in-out;
//     transition:all .5s ease-in-out;
// }
//
// [ui-view].ng-enter {
//   opacity: 0;
//   -webkit-transform:scale3d(0.5, 0.5, 0.5);
//   -moz-transform:scale3d(0.5, 0.5, 0.5);
//   transform:scale3d(0.5, 0.5, 0.5);
// }
//
// [ui-view].ng-enter-active {
//   opacity: 1;
//   -webkit-transform:scale3d(1, 1, 1);
//   -moz-transform:scale3d(1, 1, 1);
//   transform:scale3d(1, 1, 1);
// }
//
// [ui-view].ng-leave {
//   opacity: 1;
//   -webkit-transform:translate3d(0, 0, 0);
//   -moz-transform:translate3d(0, 0, 0);
//   transform:translate3d(0, 0, 0);
// }
//
// [ui-view].ng-leave-active {
//   opacity: 0;
//   -webkit-transform:translate3d(100px, 0, 0);
//   -moz-transform:translate3d(100px, 0, 0);
//   transform:translate3d(100px, 0, 0);
// }
