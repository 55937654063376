$border-radius: rem-calc(3px);

@mixin shadow() {
  box-shadow: 0px 1px 6px -1px rgba(0,0,0,0.4);
}

.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

.clearfix {
  zoom: 1; /* ie 6/7 */
}

@mixin aico() {
  font-family: 'entypo';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  font-size: rem-calc(15px);
  vertical-align: middle;
  margin-left: rem-calc(5px);
}

h1 {
  color: $secondary;
  font-size: 1.70em;
  font-weight: 500;
}
h3 {
  font-size: rem-calc(18px);
  line-height: 1.2;
  margin-bottom: rem-calc(3px);
}

span.b {
  font-weight: 500;
}

span.bold {
  font-weight: 500;
}

span.small {
  font-size: rem-calc(12px);
}

span.green {
  color: $green;
}
span.red {
  color: $red;
}

.red-background {
  background-color: red !important;
}


.bigger-font {
  font-size: rem-calc(18px);
}

span.link,
a {
  color: $link-color;

  &:hover {
    color: $link-color-hover;
  }
}

a.infoBtn {
  background: url(/assets/img/info.png);
  background-size: contain;
  width: 2em;
  height: 2em;
  display: block;
}

a.down {
  &:after {
    @include aico();
    content: "\e6ca";
  }
}

a.go{
  &:after {
    @include aico();
    content: "\e6d0";
  }
  &.big:after {
    font-size: rem-calc(24px);
  }
}

a.highlight {
  color: #0563C1;
  font-weight: bold;
  background-color: #eee;

  &:hover{
    background-color: rgba(250, 250, 250, 0.8);
    color: $link-color-hover;
  }
}

hr {
  border: solid $grey;
  border-width: rem-calc(1px) 0 0;
}


img.small{
  max-width: 1em;
  max-height: 1em;
  margin-right: 0.5em;
  margin-left: 0.5em;
}
img.middle{
  max-width: 1.6em;
  max-height: 1.6em;
  margin-right: 1em;
}
img.big{
  max-width: 2.2em;
  max-height: 2.2em;
  margin-right: 1em;
}

input[type=submit] {
  border-radius: rem-calc(3px);
}

input, select  {
  border-radius: rem-calc(3px) !important;
  &.has-error {
    border: rem-calc(1px) #f00 solid !important;
    &+label {
      color: #f00;
    }
  }
  &.not-known {
    border: rem-calc(1px) #00f solid !important;
    &+label {
      color: #00f;
    }
  }
  &.valid {
    background-color: #00ff0012;
  }
  &.notvalid {
    background-color: #ff000012;
  }
}

input, input:focus, select {
  background-color: #fff;
}
.checkbox{
  input[type=checkbox], input[type=radio] {
      display: none;
  }

  input[type=checkbox] + label, input[type=radio] + label{
    margin: 0;
  }

  input[type=checkbox] + label:before, input[type=radio] + label:before{
    content: ' ';
    width: 2em;
    height: 2em;
    display: inline-block;
    background: url($checkbox_unchecked) center center no-repeat;
    background-size: contain;
    vertical-align: middle;
    margin-bottom: 1em;
    margin-top: rem-calc(9px);
  }

  input[type=checkbox][disabled=disabled] + label:before,
  input[type=radio][disabled=disabled] + label:before {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
  }

  input[type=checkbox]:checked + label:before, input[type=radio]:checked + label:before {
    background: url($checkbox_checked) center center no-repeat;
    background-size: contain;
  }

  input[type=checkbox].white + label:before, input[type=radio].white + label:before{
    content: ' ';
    width: 2em;
    height: 2em;
    display: inline-block;
    background: url($checkbox_unchecked_white) center center no-repeat;
    background-size: contain;
    vertical-align: middle;
    margin-bottom: 1em;
    margin-top: rem-calc(9px);
  }

  input[type=checkbox].white:checked + label:before, input[type=radio].white:checked + label:before {
    background: url($checkbox_checked_white) center center no-repeat;
    background-size: contain;
  }

}
.row.collapse {
  .columns {
    input {
      border-radius: rem-calc(3px) 0 0 rem-calc(3px) !important;
    }
    .button.postfix {
      background: $primary url(/assets/img/icon-search-w.png) center center no-repeat;
      background-size: 50%;
      height: rem-calc(37px);
      border-radius: 0 rem-calc(3px) rem-calc(3px) 0 !important;
    }
  }
}
.control-label.withsymbol {
  margin-top: -20px;

  .postfix {
    background: #FFFFFF;
    color: #333333;
    border-color: #003B55;
    border-left: 0;
    margin-left: -2px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}

.solid-list.red,
.solid-list .red {
  // background: rgba(255, 0, 0, 0.45);
  color: #a00;
}

input.prefix-euro {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='36px' width='16px'><text x='5' y='23' fill='gray' font-size='13' font-family='arial'>€</text></svg>");
  background-repeat: no-repeat;
  padding-left: 16px;
}

input.prefix-percent, input.prefix-percent:focus {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' height='36px' width='16px'><text x='5' y='23' fill='gray' font-size='13' font-family='arial'>%</text></svg>");
  background-repeat: no-repeat;
  padding-left: 20px;
}

.use-gutter {
  .columns {
    padding: rem-calc(15) rem-calc(5);
  }
}

.no-label,
form .row .row .column.no-label,
form .row .row .columns.no-label {
  padding-top: 1rem;
}


.no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.table-wrapper {
  width: 100%;
  overflow-x: auto;
}

.switchbox-float-label {
  float: left;
  margin-top: 7px;
}

.vn-error {
  color: $red !important;
}

.password-indicator {


  p {
    font-size: .8rem;
    margin-bottom: .2rem;
    color: #999999 !important;

    &.active {
      color: #64c04d !important;
    }
  }


}
