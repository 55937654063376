@import "../../cockpit/shared/cockpit_toggable_content";

.cockpit_arbeitnmv-wrapper {
  @include cockpit_toggable_content;

  .reset-line-height {
    line-height: normal;
    button:first-child {
      margin-top: 1rem;
    }
    button:last-child {
      margin-top: .5rem;
      margin-bottom: 1rem;
    }
  }

  .last-status-change{
    &.red {
      color: #E20024 !important;
    }
  }


  .status-container{
    display: inline-block;
    float: right;


    .neo-status{
      border-radius: .3rem;
      border: 2px solid #000;
      width: 227px;
      text-align: center;
      display: inline-block;
      padding: 0 1.8rem;
      text-transform: capitalize;
      font-size: 1rem;
      margin-right: .5rem;
      cursor: pointer;

      &.status-red{
        color: #e20024;
      }
      &.status-green{
        color: #34BD18;
      }
      &.status-yellow{
        color: #fcce00;
      }
      &.status-black{
        color: #000;
      }
      &.status-grey{
        color: #6E6E6E;
      }
    }

    .access-state {

      &.none {
        color: transparent;
      }
      &.red {
        color: #E20024 !important;
      }
      &.yellow {
        color: #fcce00;
      }
      &.green {
        color: #34BD18;
      }
      &.black {
        color: #000;
      }
      &.blue {
        color: #2e2ef0;
      }
      &.grey {
        color: #6E6E6E;
      }
    }
  }

  .padding-left {
    padding-left: 10px;
  }

  .padding-right {
    padding-right: 10px;
  }

  .second-row {
    display: none;
  }

  .toggable-content-open {
    .second-row {
      display: block;
      button {
        margin-bottom: 2px;
      }
    }

    .stacked-toggable-content:not(.toggable-content-open) {
      .second-row {
        display: none;
        button {
          margin-bottom: 0;
        }
      }
    }
  }

  button.margin-top {
    margin-top: 20px;
  }

  .an-verwaltung-padding {
    padding: 10px;
  }
}

.control_process_btn {
  width: 200px;
}

.list-box-white {
  .list-box__item {
    background: #fff;
  }
}

.state-icon {
  font-size: xx-large;
  line-height: 0;
  font-weight: bold;
}

@media #{$small-only} {
  .cockpit_arbeitnmv-wrapper {
    .padding-left { padding-left: 5px; }
    .padding-right { padding-right: 5px; }
  }
}

@media #{$xsmall-only} {
  .cockpit_arbeitnmv-wrapper {
    .padding-left { padding-left: 0; }
    .padding-right { padding-right: 0; }
  }
}

.employee-contracts-list {
  .row {
    margin: 5px 0px;
    .contract-data {
      padding-top: .2rem;
      padding-bottom: .2rem;
      position: relative;
    }
    .button {
      top: 0px;
    }
  }
  .row.updated-contract {
    background-color: #ccc;
  }
}

.pc-leasing-icon {
  position: relative;
  overflow: hidden;

  &.blocked {
    cursor: no-drop;

    &:after {
      content: " ";
      position: absolute;
      background-color: red;
      width: 4px;
      height: 100%;
      transform: rotate(45deg);
      left: 30%;
    }
  }
}

.flex-end {
  display: flex;
  align-items: end;
}
