.notification {
  $notificationTopBg: #0496d8 !default;
  $notificationBottomBg: #003b55 !default;
  $notificationColor: #fff !default;

  &__top {

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    line-height: 2.5;

    z-index: 99999;

    padding-left: 5px;
    padding-right: 30px;

    background: $notificationTopBg;
    color: $notificationColor;

    text-align: center;
    -webkit-box-shadow: 0 3px 2px -2px $notificationTopBg;
       -moz-box-shadow: 0 3px 2px -2px $notificationTopBg;
            box-shadow: 0 3px 2px -2px $notificationTopBg;
    transition: max-height 0.5s;

    max-height: 500px;

    &.closed {
      max-height: 0;
    }

    &.error {
      background-color: #f00;
    }

    a {
      color: $notificationColor;
    }

  }

  &__bottom {

    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    line-height: 4;

    z-index: 99999;

    padding-left: 5px;
    padding-right: 30px;

    background: $notificationBottomBg;
    color: $notificationColor;

    text-align: center;
    -webkit-box-shadow: 0 -3px 2px -2px $notificationBottomBg;
       -moz-box-shadow: 0 -3px 2px -2px $notificationBottomBg;
            box-shadow: 0 -3px 2px -2px $notificationBottomBg;
    transition: max-height 0.5s;

    max-height: 500px;

    &.closed {
      max-height: 0;
    }

    &.error {
      background-color: #f00;
    }

  }

  &__close {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
  }

  &__close:before {
    background: transparent;
    color: #bbb;
    content: '\00D7';
    font-size: 26px;
    font-weight: 400;
    height: 30px;
    line-height: 26px;
    position: absolute;
    right: 3px;
    text-align: center;
    top: 3px;
    width: 30px;
  }

  &__bottom.closed.message .notification__open {
    display: block;
  }


  &__open {
    display: none;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
  }

  &__open:before {
    background: $notificationBottomBg;
    color: #bbb;
    content: 'i';
    font-size: 15px;
    font-weight: 400;
    height: 30px;
    line-height: 22px;
    position: absolute;
    right: 3px;
    text-align: center;
    top: -20px;
    width: 30px;
  }
}
