#versicherung_main {
    @media (min-width: 768px) {
      .container {
        width: 750px;
      }
    }

    @media (min-width: 992px) {
      .container {
        width: 970px;
      }
    }

    @media (min-width: 1200px) {
      .container {
        width: 1170px;
      }
    }

    .container {
      margin-top: 20px;
      padding-right: 15px;
      padding-left: 15px;
      margin-right: auto;
      margin-left: auto;
    }

  .iframe {
    margin-top: 5px;
    height: 800px;
  }

  hr {
      margin-bottom: 0;
  }

  .icon-versicherungen {
      font-size: rem-calc(40);
  }
}
