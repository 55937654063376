@mixin toggable-content($closed-rule, $opened-rule, $max-height, $media-filter: $medium-up) {
  #{$closed-rule} {
    overflow: hidden;
    max-height: 0;
    // margin-top: rem-calc(-1);
    transition: max-height 0.3s;
  }

  #{$opened-rule} {
    overflow-y: scroll;
    max-height: rem-calc($max-height);
    overflow-y: scroll;
    transition: max-height 0.5s;

    @media #{$media-filter} {
      // max-height: none;
    }
  }
};

.toggable-content-open {
  .toggable-state-feedback {
    transition: transform 0.4s;
    transform: rotate(-180deg);
  }

  .stacked-toggable-content:not(.toggable-content-open) {
    .toggable-state-feedback {
      transform: rotate(0);
    }
  }
}

.toggable-content {
  .toggable-content-toggler {
    cursor: pointer;
    display: none;

    .toggable-state-feedback,
    img {
      transition: transform 0.4s;
      transform: rotateX(0deg);
    }

    @media #{$xsmall-only}, #{$small-only} {
      display: block;
    }
  }

  @include toggable-content('.toggable-content-content', '&.toggable-content-open .toggable-content-content', 500);

  .toggable-content-content {
    @media #{$medium-up} {
      margin-top: 0;

      dd {
        margin-left: rem-calc(2);

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  &.toggable-content-open {

    .toggable-state-feedback  {
      transition: transform 0.4s;
      transform: rotateX(-180deg);
      // transform: rotateY(90deg);
    }

    .toggable-content-toggler {
      display: none;


      @media #{$xsmall-only}, #{$small-only} {
        display: block;
      }
    }

    .toggable-content-content {
      img,
      .toggable-state-feedback  {
        transform: rotateX(0deg);
      }
    }
  }
}

.toggable-state-feedback {
  width: rem-calc(24px);
  height: rem-calc(16px);
  background: url(/assets/img/svg/icon-down.svg) no-repeat center center;
  background-size: contain;
  float: left;
  display: block;
  height: rem-calc(49px);
  margin-left: rem-calc(15px);
  transition: transform 0.4s;
}
