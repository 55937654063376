@import 'bonago/benefit_bonago',
        'amazon/benefit_amazon';

.benefit-wrapper {
  .benefit-sidebar-wrapper {
    margin-left: rem-calc(25);
  }

  .benefit-detail-content-wrapper {
    background: #fff;
    padding: rem-calc(50);


  }
}
