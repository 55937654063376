// @import 'checkout/summary/bonus_summary';
@import 'bonus_side/bonus_side';
@import 'assistant/assistant_dialogue/bonusAssistantDialog';


.retailers {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    gap: 10px;
  }

  .retailers__logo {
    max-width: 100px;
  }

.wizardbox .step1 img {
  width: unset;
}


.wizardbox.centered {
  z-index: 200;
}

.bonus-dashboard__components {
  z-index: 2000;
}
