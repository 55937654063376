.meineakte-mpp {
  .side-effect {
    &:after {
      position: absolute;
      content: ' ';
      top: 0;
      right: 0;
      height: 100%;
      width: 5px;

      background: -moz-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
    }
  }

  .side-scrollable {
    overflow-y: scroll;


  }

  p {
    &.small {
      font-size: 0.8rem;
      line-height: 1;
    }
  }


}
