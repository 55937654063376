@import
  'dokumente/checkout_dokumente',
  'einsatz/checkout_einsatz',
  'zusammenfassung/checkout_zusammenfassung',
  'zusatzvereinbarung/checkout_zusatzvereinbarung';

.checkout-wrapper {
  $green: #008700;

  .continue-margin-top {
    float: right;
    margin-top: 30px;
  }

  .bigbox {
    input.btn, button.btn {
      -webkit-appearance: none;
      -moz-appearance: none;
      border-radius: 0;
      border-style: solid;
      border-width: 0;
      cursor: pointer;
      font-family: $font-family-sans-serif;
      font-weight: 300;
      line-height: normal;
      margin: 0 0 0.73333rem;
      position: relative;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      padding: 0.73333rem 1.46667rem 0.8rem 1.46667rem;
      font-size: 1rem;
      background-color: $primary;
      border-color: #002f44;
      color: #FFFFFF;
      transition: background-color 300ms ease-out;
    }
  }


  .page-title-container {
    position: relative;

    .page-title {
      text-align: center;
      margin-bottom: 25px;
    }
    .back-link {
      position: absolute;
      top: 0px;
    }
  }

  .right-text {
    text-align: right;
  }

  .bold {
    font-weight: bold;
  }

  .green {
    color: $green;
  }

  .margin-top-medium {
    margin-top: 15px;
  }

  .margin-top-big {
    margin-top: 25px;
  }


}
