/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('../../assets/font/roboto-fonts/roboto-v30-latin-300.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../assets/font/roboto-fonts/roboto-v30-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../assets/font/roboto-fonts/roboto-v30-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/font/roboto-fonts/roboto-v30-latin-300.woff') format('woff'), /* Modern Browsers */
  url('../../assets/font/roboto-fonts/roboto-v30-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/font/roboto-fonts/roboto-v30-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 400;
  src: url('../../assets/font/roboto-fonts/roboto-v30-latin-italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../assets/font/roboto-fonts/roboto-v30-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../assets/font/roboto-fonts/roboto-v30-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/font/roboto-fonts/roboto-v30-latin-italic.woff') format('woff'), /* Modern Browsers */
  url('../../assets/font/roboto-fonts/roboto-v30-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/font/roboto-fonts/roboto-v30-latin-italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: url('../../assets/font/roboto-fonts/roboto-v30-latin-500.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../assets/font/roboto-fonts/roboto-v30-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../assets/font/roboto-fonts/roboto-v30-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/font/roboto-fonts/roboto-v30-latin-500.woff') format('woff'), /* Modern Browsers */
  url('../../assets/font/roboto-fonts/roboto-v30-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/font/roboto-fonts/roboto-v30-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 500;
  src: url('../../assets/font/roboto-fonts/roboto-v30-latin-500italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../assets/font/roboto-fonts/roboto-v30-latin-500italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../assets/font/roboto-fonts/roboto-v30-latin-500italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/font/roboto-fonts/roboto-v30-latin-500italic.woff') format('woff'), /* Modern Browsers */
  url('../../assets/font/roboto-fonts/roboto-v30-latin-500italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/font/roboto-fonts/roboto-v30-latin-500italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700italic - latin */
@font-face {
  font-family: 'Roboto';
  font-style: italic;
  font-weight: 700;
  src: url('../../assets/font/roboto-fonts/roboto-v30-latin-700italic.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../assets/font/roboto-fonts/roboto-v30-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../assets/font/roboto-fonts/roboto-v30-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/font/roboto-fonts/roboto-v30-latin-700italic.woff') format('woff'), /* Modern Browsers */
  url('../../assets/font/roboto-fonts/roboto-v30-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/font/roboto-fonts/roboto-v30-latin-700italic.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../../assets/font/roboto-fonts/roboto-v30-latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
  url('../../assets/font/roboto-fonts/roboto-v30-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../../assets/font/roboto-fonts/roboto-v30-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../../assets/font/roboto-fonts/roboto-v30-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../../assets/font/roboto-fonts/roboto-v30-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../../assets/font/roboto-fonts/roboto-v30-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: 'entypo';
	src:url('../../assets/font/entypo/fonts/entypo.woff?-xhplbe') format('woff'),
			url('../../assets/font/entypo/fonts/entypo.ttf?-xhplbe') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
  font-family: 'fonticons';
  src:url('../../assets/font/fonticons/fonticons.woff?23ec64') format('woff'),
      url('../../assets/font/fonticons/fonticons.ttf?23ec64') format('truetype');
  font-weight: normal;
  font-style: normal;
}


