.car-catalog {
    .filters {
        .form-group {
            max-width: 20%;
            float: left;
            margin-right: 10px;

            select {
                padding-right: 30px;

                option {
                    text-transform: capitalize;
                }
            }
        }

        datepicker {
            width: 200px;

            .form-group {
                max-width: unset;

                > div {
                    position: absolute;
                }
            }
        }
    }

    .car-card {
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        color: #3d3d3d;
        transition: all .1s;

        .tag {
            position: absolute;
            top: 10px;
            left: 10px;
            width: 80%;

            span {
            border: 1px solid rgb(88, 88, 88);
            background-color: rgb(255, 255, 255);
            padding: 2px 8px;
            min-width: 100px;
            border-radius: 2px;
            font-size: 95%;
            color: rgb(73, 73, 73);
            margin-right: 5px;

            &.green {
                background-color: #28998a;
                color: #fff;
            }
            &.blue {
                background-color: #3838dc;
                color: #fff;
            }
            }
        }

        .color {
            position: absolute;
            top: 0;
            right: 0;
            display: flex;
            width: 50%;
            margin: 13px 13px 0 0;
            align-items: center;
            justify-content: flex-end;

            div {
            font-size: 90%;
            margin-left: 5px;
            }

            span {
            width: 15px;
            height: 15px;
            border-radius: 50%;
            margin-left: 3px;
            float: right;
            border: 1px solid #eee;
            }
        }

        .img {
            background-color: #fff;
            height: 250px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            overflow: hidden;
        }

        .details {
            background-color: #fff;
            border: solid #e3e3e3;
            border-width: 1px 0 0 0                                                 ;
            padding: 10px 20px;

            strong {
            font-size: 150%;
            color: rgb(56, 56, 56);
            }

            .price {
            margin-top: 5px;

            span {
                &:nth-of-type(1) {
                color: rgb(0, 0, 196);
                font-weight: 400;
                padding-right: 5px;
                font-size: 150%;
                }
            }
            }

            .available {
            color: green;
            display: flex;
            align-items: center;
            opacity: .8;

            svg {
                width: 18px;
                margin-right: 5px;
            }
            }

            ul {
            width: 100%;
            margin: 15px 0 0 0;
            border-top: 1px solid #eee;
            padding: 15px 0 5px 0;

            li {
                display: inline-block;
                padding: 0 8px;
                border-right: 1px solid #eee;
                font-size: 75%;
                color: rgb(53, 53, 53);

                &:first-child {
                padding-left: 0;
                }

                &:last-child {
                border: none;
                }
            }
            }
        }

        &:hover {
            box-shadow: 0 0 20px 10px rgba(0, 0, 0, 0.1);
            transform: scale(1.01);
            z-index: 5;
        }
    }
}

.car-detail {
    margin-top: 30px !important;

    .nav {
        font-size: 80%;
        margin-top: 20px;
    }

    .col-l {
        margin-top: 20px;

        .thumbs {
            width: 75px;
            float: left;
            text-align: center;
            height: 406px;
            overflow: hidden;
            position: relative;

            span {
                width: 100%;
                height: 33px;
                position: absolute;
                left: 0;
                border: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;

                &.hide {
                    opacity: 0;
                    cursor: default;
                }

                svg {
                    width: 40px;
                    height: 40px;
                    opacity: .5;
                }

                &:first-of-type {
                    top: 0;

                    svg {
                        transform: rotate(-90deg);
                    }
                }

                &:last-of-type {
                    bottom: 0;

                    svg {
                        transform: rotate(90deg);
                    }
                }
            }

            > ul {
                margin: 0;
                position: absolute;
                top: 40px;
                overflow: hidden;
                height: 330px;
                width: 100%;

                li {
                    list-style: none;
                    cursor: pointer;
                    border: 1px solid #eee;
                    margin-bottom: 8px;
                    height: 59px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: border-color .5s, transform .5s;
                    background-color: rgba(255, 255, 255, 0.404);

                    &.active {
                        border-color: rgb(59, 59, 59);
                    }

                    img {
                        width: 100%;
                        background-color: rgba(255, 255, 255, 0.2);
                    }
                }
            }
        }

        .monitor {
           // background-color: #dae8e5;
            float: right;
            width: calc(100% - 85px);
          //  border: 1px solid #eee;
            cursor: pointer;
            overflow: hidden;
            display: flex;
            align-items: center;
            max-height: 400px;

            img {
                height: 100%;
                transition: transform .5s;
            }
        }

        .results {
            float: left;
            width: 100%;
            margin-top: 50px;

            table, thead, tbody {
                width: 100%;
            }

            table {
                border: 1px solid #9a9a9a8c;
                background: #c7d9d5;
            }

            thead {
                background-color: lighten(#73979e, 15%);

                th {
                    padding: 10px;
                }
            }

            tbody {
                tr {
                    background-color: lighten(#bad1cc, 5%);

                    &:nth-child(odd) {
                        background-color: lighten(#bad1cc, 8%);
                    }

                    td {
                        &:nth-child(4) {
                            background-color: transparentize(green, .95);
                            font-weight: bold;
                        }
                    }
                }
            }
        }

        .data {
            background-color: #f3f3f399;
            padding: 50px;
            margin-top: 30px;
            float: left;

            h2 {
                color: #252525;
                font-size: 230%;
            }

            > span {
                width: 33.333%;
                padding: 10px;
                float: left;

                small {
                    display: block;
                    margin-bottom: 2px;
                    font-size: 80%;
                    opacity: .7;
                }

                svg {
                    width: 40px;
                    height: 40px;
                    float: left;
                }

                span {
                    font-weight: 400;
                }
            }
        }

        .furnish {
            padding: 50px 0 30px 0;
            margin-top: 30px;
            float: left;

            h2 {
                color: #252525;
                font-size: 230%;
            }

            strong {
                margin-right: 10px;
            }

            p {
                margin-top: 10px;
            }

            > span {
                > div {
                    border: solid #afbab8;
                    border-width: 0 0 1px 0;
                    padding: 0;
                    position: relative;

                    &:last-child {
                        li {
                            margin: 0;
                            list-style: none;

                            img {
                                float: left;
                                width: 30%;
                            }

                            span {
                                float: right;
                                width: 50%;
                                font-size: 110%;

                                strong {
                                    padding: 0;

                                    &:nth-of-type(2) {
                                        margin-top: 10px;
                                    }
                                }
                            }

                            p {
                                float: left;
                                margin-bottom: 15px;
                            }
                        }
                    }

                    &:first-child {
                        border-top-width: 1px;
                    }

                    strong {
                        width: 100%;
                        padding: 20px 0;
                        float: left;
                        cursor: pointer;
                        text-transform: capitalize;
                    }

                    &.open {
                        svg {
                            transform: rotate(-90deg);
                        }

                        ul {
                            height: auto;
                        }
                    }

                    svg {
                        width: 20px;
                        transform: rotate(90deg);
                        transition: transform .3s;
                        position: absolute;
                        top: 20px;
                        right: 0;
                    }

                    > ul {
                        margin: 0;
                        width: 100%;
                        overflow: hidden;
                        transition: height .5s;
                        height: 0;

                        > li {
                            padding-right: 5px;
                            font-size: 95%;
                            margin-left: 15px;

                            &:last-child {
                                padding-bottom: 20px;
                            }
                        }
                    }
                }
            }
        }

        a {
            margin-bottom: 50px;
            float: left;
        }
    }

    .col-r {
        > * {
            width: calc(100% - 30px);
            float: right;
            padding: 20px 0;
            border-bottom: 1px solid rgba(163, 163, 163, 0.514);

            &.color {
                > * {
                    width: 50%;
                }
            }

            h1 {
                color: rgb(37, 37, 37);
                font-size: 230%;

                img {
                    float: right;
                    width: 40px;
                }
            }

            h2 {
                color: rgb(37, 37, 37);
                font-size: 200%;
                font-weight: bold;
                line-height: initial;
                margin-bottom: 30px;
            }

            h3 {
                margin: 20px 0 7px 0;
                font-weight: 400;
                font-size: 110%;

                &:first-of-type {
                    margin-top: 0;
                }
            }

            span {
                display: inline-block;
            }

            svg {
                margin-left: 5px;
                position: relative;
                top: 3px;
                color: #0505bb;
                cursor: pointer;
                width: 20px;

                &.green {
                    color: green;
                }
            }

            p.green {
                display: inline-block;
                margin-left: 5px;
            }

            .space-top {
                margin-top: 5px;
            }

            .extra-space-top {
                margin-top: 10px;
            }

            .space-bottom {
                margin-bottom: 5px;
            }

            &:first-child {
                padding-top: 20px;
            }

            .color-bullet {
                float: right;
                margin: 0;
                text-align: right;

                li {
                    list-style: unset;
                    border-radius: 50%;
                    width: 21px;
                    height: 21px;
                    padding-top: 4px;
                    display: inline-block;
                    margin-left: 15px;
                    position: relative;
                    cursor: pointer;
                    border: 1px solid #b2b0b0;

                    &.active {
                        &:after {
                            border: 1px solid #313131a8;
                            content: " ";
                            position: absolute;
                            width: 29px;
                            height: 29px;
                            top: -5px;
                            left: -5px;
                            border-radius: 50%;
                        }
                    }
                }
            }

            .grid {

                &._33 {
                    grid-template-columns: calc(33.333% - 6.666px) calc(33.333% - 6.666px) calc(33.333% - 6.666px);
                    grid-template-rows: auto;
                    gap: 10px;
                    display: grid;
                }

                &._50 {
                    grid-template-columns: calc(50% - 5px) calc(50% - 5px);
                    grid-template-rows: auto;
                    gap: 10px;
                    display: grid;
                }

                .btn {
                    border: 1px solid #9a9a9a8c;
                    color: #3f3f3f;
                    padding: 5px 10px;
                    text-align: center;
                    color: rgb(36, 36, 36);
                    cursor: pointer;
                    background-color: rgba(255, 255, 255, 0.144);

                    &.active {
                        background-color: #272B3E;
                        border-color: #272B3E;
                        color: #fff;

                        small {
                            color: rgb(167, 167, 167);
                        }
                    }

                    small {
                        font-size: 75%;
                        display: block;
                        color: rgb(85, 85, 85);
                    }
                }
            }

            .price {
                small {
                    font-size: 70%;
                }

                span {
                    &:first-child {
                        div {
                            font-weight: 400;
                            font-size: 300%;
                            line-height: 60%;
                        }

                        small {
                            color: rgb(61, 61, 61);
                        }
                    }

                    &:last-child {
                        * {
                            float: right;
                        }
                    }
                }

            }

            .button {
                width: 100%;
                box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
                margin-top: 10px;
            }

            iframe {
                position: relative;
                height: 20px;
                width: 250px;
                display: block;
                overflow: hidden;
            }

            .check-fix {
                margin: 0;

                li {
                    list-style: none;
                    width: 100%;
                    display: block;
                    font-size: 90%;
                    margin-bottom: 5px;

                    svg {
                        margin: 0 10px 0 0;
                        top: 5px;

                        path {
                            color: #003b557a;
                        }

                        circle {
                            fill: #f3f3f330;
                            stroke: #ffffff8f;
                        }
                    }
                }
            }
        }
    }

    .alert {
        background-color: red;
        border-radius: 5px;
        padding: 0 10px;
        color: white;
        margin: 0 0 10px 0;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        opacity: 1;
    }
}

.car-checkout {
    table {
        width: 100%;
        margin: 0;
        margin-bottom: 5px;

        th {
            white-space: nowrap;
            font-size: 80%;
            background-color: #cccccc42;
        }

        strong {
            font-size: 130%;
        }
    }

    .address {
        strong {
            font-size: 110%;
            margin-bottom: 10px;
            display: block;
        }
    }

    .license {
        h2 {
            font-size: 110%;
            font-weight: bold;
            margin-top: 20px;
        }

        .mainWrapper {
            > img {
                width: 45%;
                margin: 2.5%;
            }
        }
    }

    .camera {
        border: 2px dashed rgba(191, 191, 191, 0.7);
        padding: 25px;
        margin: 20px 0 30px 0;
        position: relative;
        height: 300px;
        background-color: #fafbfb;
        text-align: center;
        box-sizing: content-box;
        display: flex;
        justify-content: center;
        align-items: center;

        button, span {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
        }

        button {
            bottom: -32px;
        }

        span {
            top: -11px;
            padding: 1px 10px;
            box-shadow: 0 2px 5px #ccc;

            &.warning {
                background-color: yellow;
                width: 60%;
            }

            &.hint {
                background-color: #bed4cf;
                width: 40%;
            }
        }

        video, canvas {
            display: none;
        }

        video, img {
            width: 400px;
        }

        &.on {
            video {
                display: inline-block;
            }

            img {
                display: none;
            }
        }
    }
}
