.kids__assistant {
  .kids__head {
    ul {
      list-style-type: none;
      margin: 0;
      li {
        float: left;
        margin-right: 10px;
        a.selected {
          color: $font-primary;
        }
        .remove {
          display: inline-block;
          color: #bbb;
          text-align: center;
          width: 30px;
          font-family: Helvetica,Arial,sans-serif;
          cursor: pointer;
        }
      }
      li:last-child {
        margin-right: none;
      }
    }
  }
  .helpText {
    color: #a00;
  }
}
