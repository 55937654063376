.pc-leasing-icon {
    position: relative;
    overflow: hidden;
    
    &.blocked {
      cursor: no-drop;
      
      &:after {
        content: " ";
        position: absolute;
        background-color: red;
        width: 4px;
        height: 100%;
        transform: rotate(45deg);
        left: 30%;
      }
    }
  }