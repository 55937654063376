  .ngdialog.ngdialog-theme-vsp.mitarbeiter-anlegen .ngdialog-content li {
    background-color: transparent;
  }
  .ngdialog.ngdialog-theme-vsp.mitarbeiter-anlegen .ngdialog-content .solid-list {
    background-color: #fff;
  }
  .ngdialog.ngdialog-theme-vsp.mitarbeiter-anlegen .ngdialog-content .expand .solid-list {
    background-color: #09435a;
  }
  .ngdialog.ngdialog-theme-vsp.mitarbeiter-anlegen .ngdialog-content .solid-list>a {
    width:100%;
  }
  .ngdialog.ngdialog-theme-vsp.mitarbeiter-anlegen .ngdialog-content .expand .solid-list>a span {
    color: #fff;
  }
  .ngdialog.ngdialog-theme-vsp.mitarbeiter-anlegen .ngdialog-content .expanded {
    display: none;
    padding: 5px;
  }
  .ngdialog.ngdialog-theme-vsp.mitarbeiter-anlegen .ngdialog-content .expand .expanded {
    display: block;
  }
  .ngdialog.ngdialog-theme-vsp.mitarbeiter-anlegen .ngdialog-content .darker {
    background-color:#e1e7e9;
  }
  .ngdialog.ngdialog-theme-vsp.mitarbeiter-anlegen .ngdialog-content .li-separator {
    text-align: center;
    margin: 5px 0px;
  }
  .ngdialog.ngdialog-theme-vsp.mitarbeiter-anlegen .ngdialog-content a.change {
    height: 12px;
    display: block;
  }
  .ngdialog.ngdialog-theme-vsp.mitarbeiter-anlegen .ngdialog-content a.change img {
    height: 100%;
    margin-right: 10px;
  }
  .ngdialog.ngdialog-theme-vsp.mitarbeiter-anlegen .ngdialog-content a.change span {
    color: #0496d8;
    font-size: 12px;
  }
  .ngdialog.ngdialog-theme-vsp.mitarbeiter-anlegen .ngdialog-content a.change:hover span {
    color: #14b3fb;
  }

.ngdialog.ngdialog-theme-vsp.mitarbeiter-anlegen .ngdialog-content {
  width: 66%;
  background-color: #fff;
}

.ngdialog.ngdialog-theme-vsp.question-dialog .ngdialog-content {
  width: 66%;
}


@media #{$xsmall-only}, #{$small-only} {
  .ngdialog.ngdialog-theme-vsp.mitarbeiter-anlegen .ngdialog-content {
    width: 100%;
  }
  .ngdialog.ngdialog-theme-vsp.question-dialog .ngdialog-content {
    width: 100%;
  }
}

.ngdialog.ngdialog-theme-vsp .ngdialog-content h1 {
  color: $lightgrey;
  text-align: center;
}

.ngdialog.ngdialog-theme-vsp {
  padding-bottom: 100px;
  padding-top: 20px;
}

.ngdialog.ngdialog-theme-cookies {
  padding-top: 160px;
}

.ngdialog.ngdialog-theme-vsp {
  .control-label {
    color: $font-primary;
    font-family: Roboto-Light, sans-serif;
  }
}

.ngdialog.ngdialog-theme-vsp .ngdialog-content {
  z-index: 1001;
  -webkit-transform: translatez(0);
  -moz-transform: translatez(0);
  -ms-transform: translatez(0);
  -o-transform: translatez(0);
  transform: translatez(0);
  background: #fff;
  // color: #444;
  // font-family: 'Helvetica Neue',sans-serif;
  // font-size: 1.1em;
  // line-height: 1.5em;
  margin: 0 auto;
  max-width: 100%;
  padding: 50px;
  position: relative;
  // width: 450px;
}

.ngdialog.ngdialog-theme-vsp .ngdialog-content h2,
.ngdialog.ngdialog-theme-vsp .ngdialog-content h3,
.ngdialog.ngdialog-theme-vsp .ngdialog-content h4,
.ngdialog.ngdialog-theme-vsp .ngdialog-content h5,
.ngdialog.ngdialog-theme-vsp .ngdialog-content h6,
.ngdialog.ngdialog-theme-vsp .ngdialog-content p,
.ngdialog.ngdialog-theme-vsp .ngdialog-content ul,
.ngdialog.ngdialog-theme-vsp .ngdialog-content li {
  color: inherit;
}

.ngdialog.ngdialog-theme-vsp .ngdialog-close {
  width: 20px;
  height: 20px;
  padding: 20px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ngdialog.ngdialog-theme-vsp .ngdialog-close:before {
  width: 30px;
  height: 30px;
  color: #eee;
  content: "\00D7";
  background: transparent;
  font-size: 26px;
  font-weight: 400;
  line-height: 26px;
  text-align: center;
}

.ngdialog.ngdialog-theme-vsp .ngdialog-close:hover:before,
.ngdialog.ngdialog-theme-vsp .ngdialog-close:active:before {
  color: #aaa;
}

.ngdialog.ngdialog-theme-vsp .ngdialog-message {
  margin-bottom: .5em;
}

.ngdialog.ngdialog-theme-vsp .ngdialog-input {
  margin-bottom: 1em;
}

.ngdialog.ngdialog-theme-vsp .ngdialog-input textarea,
.ngdialog.ngdialog-theme-vsp .ngdialog-input input[type="text"],
.ngdialog.ngdialog-theme-vsp .ngdialog-input input[type="password"],
.ngdialog.ngdialog-theme-vsp .ngdialog-input input[type="email"],
.ngdialog.ngdialog-theme-vsp .ngdialog-input input[type="url"] {
  background: #f0f0f0;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  margin: 0 0 .25em;
  min-height: 2.5em;
  padding: .25em .67em;
  width: 100%;
}

.ngdialog.ngdialog-theme-vsp .ngdialog-input textarea:focus,
.ngdialog.ngdialog-theme-vsp .ngdialog-input input[type="text"]:focus,
.ngdialog.ngdialog-theme-vsp .ngdialog-input input[type="password"]:focus,
.ngdialog.ngdialog-theme-vsp .ngdialog-input input[type="email"]:focus,
.ngdialog.ngdialog-theme-vsp .ngdialog-input input[type="url"]:focus {
  -webkit-box-shadow: inset 0 0 0 2px rgba(0,0,0,0.2);
  box-shadow: inset 0 0 0 2px rgba(0,0,0,0.2);
  outline: none;
}

.ngdialog.ngdialog-theme-vsp .ngdialog-buttons:after {
  clear: both;
  content: '';
  display: table;
}

.ngdialog.ngdialog-theme-vsp .ngdialog-button {
  border: 0;
  cursor: pointer;
  float: right;
  font-family: inherit;
  font-size: .8em;
  letter-spacing: .1em;
  line-height: 1em;
  margin: 0 0 0 .5em;
  padding: .75em 2em;
  text-transform: uppercase;
}

.ngdialog.ngdialog-theme-vsp .ngdialog-button:focus {
  -webkit-animation: ngdialog-pulse 1.1s infinite;
  animation: ngdialog-pulse 1.1s infinite;
  outline: none;
}

@media (max-width: 568px) {
  .ngdialog.ngdialog-theme-vsp .ngdialog-button:focus {
    -webkit-animation: none;
    animation: none;
  }
}

.ngdialog.ngdialog-theme-vsp .ngdialog-button.ngdialog-button-primary {
  background: #3288e6;
  color: #fff;
}

.ngdialog.ngdialog-theme-vsp .ngdialog-button.ngdialog-button-secondary {
  background: #e0e0e0;
  color: #777;
}

.ngdialog .dialog-content-wrapper {
  max-width: rem-calc(940);
  margin: 0 auto;
}

.ngdialog.ngdialog-theme-vsp.employee-integrity {
  padding: 25px;
  .ngdialog-content {
    height: 100%;
    overflow-y: auto;
  }
}

@media #{$xsmall-only}, #{$small-only} {
  .ngdialog.ngdialog-theme-vsp.employee-integrity {
    padding: 10px;
  }
}

