$playIcon: '/assets/img/icon-playvideo.png';

.inner-wrap {
  position: relative;
  height: 100%;
}

.vn-dialog-fullscreen {
  padding: 0px !important;
}

#essenchecks-types {
  background-color: unset;

  td {
    vertical-align: top;
    // text-align: justify;
    background-color: #fbfbfb;
    width: 50%;

    strong {
      font-size: 130%;
    }

    p {
      margin-bottom: 10px;
      line-height: 1.5;
    }
  }
}

.essenscheck-options {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

@import
  'dashboard/dashboard',
  'side/side',
  'neo/berater/berater',
  'shop/shop',
  'neo/checkout/checkout',
  'cashback/cashback',
  'vorsorge/vorsorge',
  'vcp/vcp',
  'meineakte/meineakte',
  'cockpit/cockpit',
  'benefit/benefit',
  'feedback/feedback',
  'neo/verguetung/verguetung',
  'versicherung/versicherung',
  'neo/components/components',
  'mpp/mpp',
  'cms/cms',
  'datev/datev',
  'administration/administration',
  'administration/employeeList/employeeList',
  'bonus/bonus',
  'yearly/info_validation/info_validation',
  'administration/bonus_administration/bonus_administration',
  'support/support_dialog/support_dialog'
;
