.row-info-validation {
  position: relative !important;
}

.weiter {
  float: right;
}

.strong {
  font-weight: bold;
}

.info-validation-selected {
  background-color: green !important;
  color: white !important;
}

.selectednegative {
  background-color: red !important;
  color: white !important;
}

.main {
  &.yearly {
    min-height: 850px;
  }

  .position-relative {
    position: relative;
  }

  ._720kb-datepicker-calendar{
    display: none;

    &._720kb-datepicker-open {
      display:inherit;
    }
  }
}

.bottom {
  position: relative;
  vertical-align: -90 !important;
}

ul {
  &.yearlyList {
    list-style: none;
  }
}
