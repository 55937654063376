@import '../../scss/quill.snow';

.white-popup {
  position: relative;
  background: white;
  margin: auto;

  .popup-content {}
}

.cockpit {
  $green: #008700;

  min-height: 500px;

  .white-popup {
    width: 607px;
    padding: rem-calc(15) rem-calc(30) rem-calc(30) rem-calc(30);

    h4 {
      padding-bottom: rem-calc(15);
    }
  }

  h4 {
    font-size: rem-calc(13);
    font-weight: bold;
    margin: 0;
    padding: rem-calc(6) 0 rem-calc(14) 0;
  }


  .component-col {
    .double-label-switch, label {
      display: inline-block;
    }

    a {
      float: right;
      line-height: 2.5rem;
    }
  }


  .small-input {
    height: 28px;
    line-height: 28px;
    margin: 0 0 0 rem-calc(5);
    font-size: 90%;
    display: inline-block;
    padding: 0;
  }

  .popup-content {
    font-size: 90%;

    .btn,
    input {
      font-size: 90%;
      margin: 0;
    }

    .small-input {
      text-align: right;
    }

    .euro-input-container {
      .small-input {
        padding-right: rem-calc(20);
      }
    }

    .small-input-euro {
      position: absolute;
      top: rem-calc(3);
      right: rem-calc(19);
    }

    .label-container {
      width: 1%;
      vertical-align: middle;
      padding-right: rem-calc(5);
    }

    .value-container {
      padding-right: rem-calc(10);
    }

    .label-container,
    .value-container {
      display: table-cell;
    }

    .button-container {
      text-align: right;
    }
  }

  &__menu {
    list-style: none;
    margin: 0 0 rem-calc(20);
    padding: 0;
    overflow: hidden;
    max-height: rem-calc(1200);
    -webkit-transition: max-height .3s ease-in-out;
    transition: max-height .3s ease-in-out;

    &-item {
      background-color: #fff;
      margin-bottom: rem-calc(2);

      &:first-child {
        border-top: 0;
      }

      &:hover,
      &.isActive {
        background-color: rgba($primary, .9);

        a,
        .font-icon {
          color: #fff;
        }
        &.disabled {
          background-color: #fff;
          a,
          .font-icon {
            color: $grey;
          }
        }
      }

      &.disabled {
        a,
        .font-icon {
          color: $grey;
        }
      }
    }

    &-active {
      color: #fff;
      display: none;
      background-color: rgba($primary, .9);

      .font-icon {
        color: #fff;
      }
    }

    &-indicator {
      display: none;
    }

    &-link {
      display: block;
      line-height: rem-calc(45);
      font-size: 91%;
      font-weight: 500;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &-icon {
      font-size: rem-calc(25);
      padding: 0 rem-calc(15);
      text-align: center;
      display: inline-block;
    }
  }

  &_toggable-content {
    border-top: rem-calc(1) solid $lightgrey2;
    padding: rem-calc(10) 0 rem-calc(20) 0;
    margin:  0 rem-calc(10) 0 rem-calc(40);
    // font-size: 90%;
  }

  &__form-title {
    position: relative;

    .btn {
      position: absolute;
      top: 0;
      right: 0;
    }
  }

  &__form-content {
    padding-top: rem-calc(5);
  }
}

.cockpit-main {
  margin: 0 20px;

  .button {
    margin: rem-calc(10) 0;

    @media #{$medium-up} {
      margin: 0 0 rem-calc(5) rem-calc(5);
    }
  }

  .list-box__right {
    width: 95%;
    margin-left: 2.5%;

    @media #{$medium-up} {
      width: auto;
      margin: 0;
    }

    .button {
      display: inline-block;
      margin: rem-calc(5) auto;

      @media #{$medium-up} {
        margin: 0 0 0 rem-calc(5);
      }
    }
  }
}

.search-box {
  padding: rem-calc(10);
  border-radius: rem-calc(3);
  background-color: rgba(#fff, .25);

  &__field {
    top: rem-calc(2);
  }

  &__input[type] {
    margin: 0;
    border: 0;
    border-radius: 0 rem-calc(3) rem-calc(3) 0 !important;
    background-color: transparent;
  }

  &__buttons {
    button.btn {
      margin: 0 rem-calc(5) 0 0;
    }
  }

  .prefix {
    background-color: transparent;
    border: 0;
    border-radius: rem-calc(3) 0 0 rem-calc(3);
    font-size: 1.3rem;
  }
}

.with__search {
  .search-box.row {
    margin: 0;
  }
  .search-box {
    padding: 0;
    .prefix {
      background-color: transparent;
      height: 4rem;
      line-height: 4rem;
    }
    .search-box__input {
      line-height: 4rem;
      height: 4rem;
      background-color: transparent;
    }
  }
}

.list-header,
.summary-box {
  background-color: rgba(255, 255, 255, 0.9);
  line-height: rem-calc(60);
  padding: 0 rem-calc(10);
  border-radius: rem-calc(3);
  margin: 0 0 rem-calc(10);

  &.isTransparent {
    background-color: rgba(#fff, .25);
  }

  &__title {
    display: block;
    text-overflow: ellipsis;
    line-height: 1;
    margin: rem-calc(10) 0 0;

    strong {
      display: block;
      line-height: 1.5;
    }

    @media #{$medium-up} {
      display: inline;
      white-space: nowrap;

      strong {
        display: inline;
      }
    }
  }
}

.list-header {
  margin: 0 0 rem-calc(10);
}


.simple-select {
  max-width: rem-calc(55);
  color: $link-color;
  border: 0;
  background-color: transparent;
  margin: 0;

  &:hover {
    background-color: transparent;
  }

  &:focus {
    outline: 0;
  }
}

.list-box {
  &__item {
    background-color: rgba(242, 244, 237, 0.9);
    border-radius: rem-calc(3);
    margin-top: rem-calc(2);
    width: auto;
    padding: 0;
    position: relative;

    -webkit-transition: 450ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
    -moz-transition: 450ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
    -ms-transition: 450ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
    -o-transition: 450ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
    transition: 450ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all;

    &.ng-enter {
      height: 0px;
      opacity: 0;
      overflow: hidden;
    }

    &.ng-enter.ng-enter-active {
      height: 50px;
      opacity: 1;
    }
  }

  &__row {
    -webkit-transition: 450ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
    -moz-transition: 450ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
    -ms-transition: 450ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
    -o-transition: 450ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
    transition: 450ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all;

    &.ng-enter {
      height: 0px;
      opacity: 0.25;
    }

    &.ng-enter.ng-enter-active {
      height: 50px;
      opacity: 1;
    }
  }

  &__itemTitle {
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    vertical-align: middle;
    line-height: 2;

    padding: rem-calc(10) rem-calc(10);
    display: block;
    cursor: pointer;

    .user-name {
      display: inline-block;
      white-space: normal;
      width: auto;
      max-width: 75%;
      line-height: 1.5;
    }

    .toggable-state-feedback {
      display: block;
      position: relative;
      width: rem-calc(12);
      height: rem-calc(12);
      margin: 0;
      margin-top: rem-calc(10);
      margin-right: rem-calc(10);
    }
  }

  &__right {
    display: inline-block;
    position: relative;


    // height: 30px;

    @media #{$medium-up} {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: rem-calc(10px);
    }

    .btn-bordered {
      // background: rgba(242, 244, 237, 0.8);
      color: inherit;
      border-color: inherit;

      &:hover {
      }
    }
  }

  &__item__content {
    background-color: #fff;
  }

  .toggable-content-open {
    .list-box__item {
      background: white;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    .stacked-toggable-content:not(.toggable-content-open) {
      .list-box__item {
        background-color: rgba(242, 244, 237, 0.9);
        border-radius: rem-calc(3);
      }
    }
  }

  &__buttons {
    display: inline-block;
  }
}

.cockpit-panel {
  margin-bottom: rem-calc(15);

  &__title {
    background-color: rgba(#fff, .5);
    padding: rem-calc(10);
  }

  &__body {
    background-color: #fff;
    padding: rem-calc(15);
    display: inline-block;
    width: 100%;

    &.red {
      background-color: #ff0300;

      label {
        color: #fff;
      }
    }

    @media #{$medium-up} {
      padding: rem-calc(50);
    }

    .separator {
      border-bottom: 1px solid rgba(153,153,153,0.3);
      margin: rem-calc(5) 0 rem-calc(15);
    }
  }
}

.module-select {
  margin-left: rem-calc(-10);
  margin-right: rem-calc(-10);

  .cockpit_allgemein-wrapper & {
    max-width: rem-calc(189*3);
    margin: 0 auto;
    display: block;
  }

  &__item {
    width: rem-calc(169);
    height: rem-calc(169);
    display: block;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    background-color: #FAFBFC;
    border-bottom: rem-calc(1) solid transparent;
    margin: rem-calc(10) auto;
    cursor: pointer;
    position: relative;

    @media #{$medium-up} {
      display: inline-block;
      margin: 0 rem-calc(10) rem-calc(15) rem-calc(10);
    }

    &.isSelected {
      background-color: #E4F4FB;
    }
    &.isSelected,
    &:hover {
      border-color: #B0C4CD;

      & .module-select__icon,
      & .module-select__title h5 {
        color: $link-color;
      }
    }
  }

  &__icon {
    height: 60%;
    width: 100%;
    display: table;
    text-align: center;
    vertical-align: middle;
    -webkit-transition: color .3s ease-in-out;
    transition: color .3s ease-in-out;

    &.shop {
      height: auto;
      margin-top: 1rem;
    }

    &:before {
      display: table-cell;
      vertical-align: bottom;
      font-size: rem-calc(40);
      color: $font_primary;
    }
  }

  &__title {
    width: 100%;
    height: 40%;
    text-transform: uppercase;
    text-align: center;
    display: table;
    vertical-align: middle;
    word-break: break-all;

    h5 {
      // display: table-cell;
      vertical-align: middle;
      font-size: 10px;
      font-weight: 500;
      color: $font_primary;
      -webkit-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;

      &.shop {
        position: absolute;
        width: 100%;
        bottom: 1rem;
      }
    }

    p {
      text-transform: none;
    }
  }

  &--small {

    .module-select__item {
      height: rem-calc(80);
    }

    .module-select__icon {
      width: 30%;
      height: 100%;
      display: inline-block;
      margin-right: 0;
      margin-left: 0;
    }

    .module-select__icon:before {
      font-size: rem-calc(30);
      margin: rem-calc(26) 0 0 rem-calc(5);
      display: inline-block;
    }

    .module-select__title {
      width: 70%;
      height: 100%;
      display: inline-block;
      padding: 0 rem-calc(5) 0 0;
      text-align: left;

      h5 {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        margin: rem-calc(36) 0 0;
        line-height: 1;
        font-size: .7rem;
      }
    }
  }
}

.double-label-switch {

  input[type=checkbox] + label:before {
    display: none;
  }

  .switch input:checked + label {
    background-color: $primary;
  }

  .switch {
    display: inline-block;
    margin: 0;
    vertical-align: middle;
    -webkit-transform: scale(.6);
    transform: scale(.6);
  }

  .switch__label {
    line-height: rem-calc(43);
    display: inline-block;
    vertical-align: middle;
  }

  // Foundation switch style fixes
  .switch.large {
    label {
      height: rem-calc(38);
    }

    input:checked + label:after {
      left: rem-calc(40);
    }
  }
}

.inner-toggable-content {
  @include toggable-content('.inner-toggable-content__content', '.inner-toggable-open .inner-toggable-content__content', 600);
}

@media #{$xsmall-only}, #{$small-only} {
  .cockpit .breadcrumb {
    margin-left: 20px;
  }
  .cockpit__menu {
    max-height: rem-calc(60);
    margin-left: rem-calc(20);
    margin-right: rem-calc(20);

    &.isOpen {
      max-height: rem-calc(800);
    }

    &-item {
      &.cockpit__menu-active {
        display: block;
      }
    }

    &-indicator {
      display: block;
      float: right;
      margin: rem-calc(10);
    }

    &-text {
      display: inline-block;
      float: left;
      margin: rem-calc(10);
    }
  }
}

.status-container {
  display: inline-block;
  float: right;

  .neo-status {
    border-radius: .3rem;
    border: 2px solid #000;
    width: 227px;
    text-align: center;
    display: inline-block;
    padding: 0 1.8rem;
    text-transform: capitalize;
    font-size: 1rem;
    margin-right: .5rem;
    cursor: pointer;

    &.status-red {
      color: #e20024;
    }

    &.status-green {
      color: #34BD18;
    }

    &.status-yellow {
      color: #fcce00;
    }

    &.status-black {
      color: #000;
    }
  }

  .access-state {

    &.none {
      color: transparent;
    }

    &.red {
      color: #E20024 !important;
    }

    &.yellow {
      color: #fcce00;
    }

    &.green {
      color: #34BD18;
    }

    &.black {
      color: #000;
    }

    &.blue {
      color: #2e2ef0;
    }
  }
}

@import
'./allgemein/cockpit_allgemein',
'./cashback/cockpit_cashback',
'./kundenauswahl/cockpit_kundenauswahl',
'./shop/cockpit_shop',
'./steuerfrei/cockpit_steuerfrei',
'./versicherungen/cockpit_versicherungen',
'./vorsorge/cockpit_vorsorge',
'arbeitnehmerverwaltung/cockpit_employeeadministration',
'./projekte/cockpit_projekte',
'./bonus/cockpit_bonus'
;
