.cockpit_bonus-wrapper {
    span.primary {
        color: $primary;
    }
}

.settings {
    padding: 2px;
}

.auto-resize {
  @include flexbox();
  @include flex(1 1 auto);
  @include flex-direction(column)
}

.bonus-budget-form-row {
    margin: 5px;
    input {
        margin: 0;
    }
    $margin: 0 5px;
    @include flexbox();
    @include align-items(center);
    .per_item_settings {
        @include flex(0 0 100px);
        @include flexbox();
        @include justify-content(space-between);
    }
    .field_label {
        @include flex(0 0 240px);
        font-size: 0.9em;
        margin: $margin;
    }
    .field_value {
        @include flex(0 0 100px);
        &.large {
            @include flex(0 0 200px);
        }
        margin: $margin;
    }
    .field_x {
        @include flex(0 0 10px);
        margin: $margin;
    }
    .field_per_item_price {
        @include flex(0 1 100px);
        margin: $margin;
    }
    .field_result {
        @include flex(1 0 100px);
        @include flexbox();
        @include justify-content(flex-end);
        margin: $margin;
        .fix_errors {
            color: red;
        }
        .result {}
    }
    &.employee {
        border-bottom: 1px solid #cccccc82;
        padding: 10px 0;
        margin: 0;
        position: relative;
        display: block;

        > div {
            &:first-child {
                display: flex;
                display: flex;
                align-items: center;

                &:hover {
                    background-color: #cccccc21;
                }

                > * {
                    flex: 1;
        
                    &:nth-child(1) {
                        flex: 2;
                    }
        
                    &:nth-child(2) {
                        flex: .5;
                    }
        
                    &:nth-child(3), &:nth-child(4) {
                        flex: 1.5;
                    }
        
                    &.checkbox {
                        label {
                            margin-top: 18px;
        
                            > span {
                                margin-left: 5px;
                            }
                        }
                    }
        
                    &.form-group {
                        display: initial !important;
                    }
        
                    .bonusCalcDataCol {
                        font-size: 0.9333333333rem;
                    }
                }

                select {
                    margin: 0;
                }
            }

            &:nth-child(2) {
                text-align: right;
            }
        }
    }
}

#stklClassForm {
    display: flex;
    margin: 5px;
}

.bonusCalcData, .sumRow {
    display: flex;
}
  
.bonusCalcDataCol {
    flex: 1;
    margin-left: 10px;
}
