@import '../shared/cockpit_toggable_content';

.ngdialog-content {
  padding-left: 35px !important;
  padding-right: 35px !important;

}

.right-text {
  text-align: right;
}
.cockpit_arbeitnmv-wrapper {
  @include cockpit_toggable_content;

  .reset-line-height {
    line-height: normal;

    button:first-child {
      margin-top: 1rem;
    }

    button:last-child {
      margin-top: .5rem;
      margin-bottom: 1rem;
    }
  }

  .padding-left {
    padding-left: 10px;
  }

  .padding-right {
    padding-right: 10px;
  }

  .second-row {
    display: none;
  }

  .toggable-content-open {
    .second-row {
      display: block;

      button {
        margin-bottom: 2px;
      }
    }

    .stacked-toggable-content:not(.toggable-content-open) {
      .second-row {
        display: none;

        button {
          margin-bottom: 0;
        }
      }
    }
  }

  button.margin-top {
    margin-top: 20px;
  }

  .an-verwaltung-padding {
    padding: 10px;
  }
}

.list-box-white {
  .list-box__item {
    background: #fff;
  }
}

@media #{$small-only} {
  .cockpit_arbeitnmv-wrapper {
    .padding-left {
      padding-left: 5px;
    }

    .padding-right {
      padding-right: 5px;
    }
  }
}

@media #{$xsmall-only} {
  .cockpit_arbeitnmv-wrapper {
    .padding-left {
      padding-left: 0;
    }

    .padding-right {
      padding-right: 0;
    }
  }
}

.list-box__itemTitle {
  img {
    width: 30px;
    height: 34px;
    margin-right: 1.3rem;
    float: right;
  }
}

.employee-contracts-list {
  .row {
    margin: 5px 0px;

    .contract-data {
      padding-top: .2rem;
      padding-bottom: .2rem;
      position: relative;
    }

    .button {
      top: 0px;
    }
  }

  .row.updated-contract {
    background-color: #ccc;
  }
}

.employee-filters-containter {
  .row {
    .filter-input {
      padding: 5px;
    }
  }

  .ui-select-container {
    .select2-choices {
      border-radius: .2rem !important;
    }
  }
}
