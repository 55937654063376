.inline-block {
  display: inline-block;
}

.datev-page-header {
  min-height: 20rem !important;
}

.dialog-padding {
  padding: 10px;
}

.flex{
  @include flexbox();
}

.horizontal_label{
  &>label {
    display:initial !important;
  }
}

.center {
  margin:auto;
  //@include align-items(center);
}

.controls-row {
  @include flexbox();
  @include justify-content(space-between);
  margin-top: 20px !important;
}

.budget-form-row {
  input {
    margin: 0;
  }
  $margin: 0 5px;
  @include flexbox();
  @include align-items(center);
  .per_item_settings {
    @include flex(0 0 100px);
    @include flexbox();
    @include justify-content(space-between);
  }
  .field_label {
    @include flex(0 0 240px);
    font-size: 0.9em;
    margin: $margin;
  }
  .field_value {
    @include flex(0 0 100px);
    &.large {
      @include flex(0 0 200px);
    }
    margin: $margin;
  }
  .field_x {
    @include flex(0 0 10px);
    margin: $margin;
  }
  .field_per_item_price {
    @include flex(0 1 100px);
    margin: $margin;
  }
  .field_result {
    @include flex(1 0 100px);
    @include flexbox();
    @include justify-content(flex-end);
    margin:$margin;
    .fix_errors {
      color: red;
    }
    .result {

    }
  }
}

table.betrachnung-table {
  border-collapse: collapse;
  td {
    text-align: center;
    border: 1px solid black;
  }
}

.blqt {
  &>.columns {
    height: 75px;
    display: flex;
    align-items: center;
    padding: 0 10px;

    &:nth-child(odd) {
      justify-content: flex-end;
    }
  }
}

// needed because in _dashboard.scss someone has modified the label element directly
label.clear {
  color: white !important;
  font-weight: initial !important;
}


.label-right-container {
  display: flex;

  label {
    margin-left: 10px;
    order: 2;
    word-break: break-word;
  }
}

.table-result-view {
  $border-style: .05rem solid #ccc;
  margin: 0;
  border-top: $border-style;

  .table-header,
  .table-row {
    list-style: none;


    &>div {
      &:nth-of-type(4n+1) {
        text-align: left;
        border-left: $border-style;
      }

      border-bottom: $border-style;
      border-right: $border-style;
      padding: .5rem;
      box-sizing: border-box;
    }
  }

  .table-row {
    &>div {
      &:nth-of-type(4n+1) {
        text-align: left !important;
      }
    }
  }

  .table-header {
    border-top: none;

    &>div {
      color: #000;
      text-transform: capitalize;
      font-weight: 400;
      background-color: #f3f3f3;
    }
  }
}

.c3-xgrid,
.c3-ygrid {
  stroke-dasharray: 0;
}

.no-shadow {
  box-shadow: none !important;
}


.pagination-container {
  margin-bottom: 18px;
  border: 0.05rem solid #ccc;
  padding: 1px;

  ul {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 0;

    &>li {
      text-decoration: none;
      display: inline-block;
      padding: 3px 16px;
      height: auto;
      flex-grow: 0;
      min-width: 8rem;

      background-color: #f1f1f1;
      cursor: pointer;
    }

    &>li.pagination-previous,
    &>li.pagination-next {

      &.disabled {
        pointer-events: none;
        opacity: .5;
        cursor: not-allowed;
      }

      &.pagination-previous {
        border-right: 0.05rem solid #ccc;
      }

      &.pagination-next {
        border-left: 0.05rem solid #ccc;
      }

      color: #000;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 20px;
      cursor: pointer;
    }

    &>li.pagination-title {
      background-color: transparent;
      flex-grow: 1;
      font-size: 20px;
      text-transform: capitalize;
    }
  }
}

.datev-intro-screen{
  .button{
    margin-bottom: 0;
  }
}
.datev-list-box{
  background-color: #fff;
  margin-bottom: 1rem;
  .potential-item{
    padding-top: .5rem;
    .row{
      margin-bottom: 1rem;
    }
    .potential-title{
      line-height: 2.5rem;
    }

    .button {
      font-size: 1rem;
      min-width: 12rem;
    }
  }


  .recalculate {
    
    .horizantal-center-flex label{
      vertical-align: baseline !important;
    }

    .button{
      &.crt-calc{
        background-color: #fff;
        border: .1rem solid #000;
        color: #000;
        min-width: 14rem;
        padding: 1rem;
      }
      min-width: 12rem;
      margin: .6rem;
    }
  }
}

.steps-indicator {
  display: flex;
  margin-bottom: 20px;
}

.row {
  .columns .form-group:last-child {
    input,
    select {
      margin-bottom: 0;
    }
  }
}

.columns {
  p:last-child {
    margin-bottom: 0;
  }
}

.use-gutter:last-of-type {
  .columns {
    padding-bottom: 0;
  }
}

.spacing-small {
  .columns {
    padding: .533335rem .33333rem;
  }
}

.no-gutter {
  margin: 0 !important;
}

.table {
  margin-bottom: 0;
}

ul[datev-table-view]{
  .positive-value{
    color: #3ed402 // #48FB00;
  }

  .negative-value{
    color: #f60000;
  }
}

.print-page {
  padding: 0 70px;

  .print-page-space {
    height: 70px;
  }

  .print-page-head {
    width: 100%;
    height: 70px;
    text-align: right;
  }

  table:not(.table-text) {
    width: 100%;
  }
}

@media screen {
  #printSection {
    display: none;
  }
}

@media print {
  body.printMode > *:not(#printSection) {
    display: none;
  }

  #printSection {
    display: block;

    .ng-hide {
      display: block !important;
    }
  }

  .print-page {
    .print-page-head {
      position: fixed;
      top: 0;
      right: 70px;
    }

    .print-page-body {
      > table {
        > thead {
          display: table-header-group !important;
        }
      }
    }
  }
}

.table-text {
  width: auto;

  td {
    padding: 0 10px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.bold {
  font-weight: 600;
}

/* ------------------------------------------------------------ *\
  Document
\* ------------------------------------------------------------ */

.document {
  .document-group {
    page-break-after: always;

    .document-entry {
      page-break-after: always;
    }
  }
  .document-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

/* ------------------------------------------------------------ *\
  Table
\* ------------------------------------------------------------ */

.table {
  width: 100%;
  border-spacing: 0;

  td {
    border: 0 !important;
    text-align: right !important;

    &:first-child {
      text-align: left !important;
    }
  }

  .positive-value {
    color: #3ed402 !important;
  }

  .negative-value {
    color: #f60000 !important;
  }

  &-striped {
    thead {
      td {
        background: #D9D9D9 !important;
      }
    }

    tbody {
      td {
        padding: .6rem 0.46667rem;
      }

      tr:nth-child(odd) {
        td {
          background: #F3F3F3 !important;
        }
      }
    }
  }
}

/* ------------------------------------------------------------ *\
  Slider
\* ------------------------------------------------------------ */

.slider {
  .slider-bar {
    display: flex;
    align-items: center;
  }

  .slider-min,
  .slider-max {
    font-size: .93333rem;
  }

  .slider-track {
    margin: 0 10px;
    line-height: 1;
  }
}

/* ------------------------------------------------------------ *\
  Buttons
\* ------------------------------------------------------------ */

.button-medium {
  font-size: 1.2rem;
}

.button-border {
  border: 2px solid #003b55;
  background: transparent;
  color: #003b55;
  transition: background .3s, color .3s;

  &:hover,
  &:focus,
  &.active {
    background: #003b55;
    color: #fff;
  }
}
