.cockpit_kundenauswahl-wrapper {
  span.primary {
    color: $primary;
  }
}

.testmode-dialog-content {
  .testmode-switch, .testmode-label {
    display: inline-block;
  }
}

.access-state {
  &.red {
    color: $red !important;
  }
  &.yellow {
    color: #fcce00;
  }
  &.green {
    color: $green;
  }
}

.changed-state {
  text-align: center;
  padding-top: rem-calc(8);
  line-height: rem-calc(10);
  font-size: rem-calc(12);
}

.hovered {
  display: none;
}
span:hover + .hovered {
  display: inline;
}