#beraterView {
  p {
    &.error {
      color: #f00;
    }

    &.small {
      font-size: .85rem;
    }
  }

  .bigbox.enable-error .header span:first-child {
    color: #f00;
  }

  .extra-error {
    margin-top:-1.06667rem;
    .error-msg {
      font-size:.8rem;
      color:#e60000;
    }
  }
}

#neo {
  height: 100%;
  > .row {
    height: 100%;
  }

  .tabs dd[disabled]>a, .tabs .tab-title[disabled]>a {
    // background-color: rgba(255,255,255,0.05);
    cursor: not-allowed;
    color: darken($tabs-navigation-font-color, 20%);
    background-color: darken($tabs-navigation-bg-color, 30%);
  }
}

.target-dialog .scrollbox {
  max-height: 300px;
  overflow-y: auto;
}

.currentFinances,
#currentFinances {
  .buttons {
    padding: 15px;
  }

  li.blue .icon-minus {
    display: none;
    cursor: pointer;
  }

  .deletable {
    li.blue:hover {
      .font-icon-alt.icon-check {
        display: none;
      }

      .icon-minus {
        display: inline-block;
      }

      span {
        color: #f00;
      }

      &.last span {
        color: $link-color;
      }
    }
  }
}

@media #{$xsmall-only}, #{$small-only} {
  #currentFinances {
    li.blue {
      .icon-minus {
        display: block;
      }

      .icon-check {
        display: none;
      }
    }
  }
}
