.cockpit_allgemein-wrapper {
  .active {
    border: 1px solid $primary-color;
  }

  .error-text {
    color: red;
    font-size: smaller;
  }
}
