.mpp-view {

  #side-view .main ul li {
    &.blue.red a {
      color: $red;
    }

    li {
      margin-left: 0;

      .cart {
        &.left {
          width: 30%;
        }

        &.right {
          width: 50%;
          margin-right: 20px;
        }
      }

      .font-icon {
        position: static;
        margin-left: auto;
        margin-top: 18px;
        font-size: 1.6rem;
        display: inline-block;

        cursor: pointer;

        &.orange {
          color: $orange;
        }
      }

      .icon-delete {
        color: $red;
        margin-left: 10px;
      }
    }

    .key {
      max-width: 150px;
    }
  }

  #side-view .main ul.vorteil li {
    margin-left: 1rem;
    margin-right: 1rem;

    .value {
      margin-right: 0;
    }
  }

  .box {
    margin-bottom: 15px;
    background: #fff;
  }

  .sorting {
    margin-top: .5rem;

    select {
      outline: 0;
    }
  }

  .top-bar {
    background: rgba(255,255,255, .4);
    border-radius: 5px;
    border: 1px solid #ccc;
    padding: .5rem;

    margin: 0 !important;

    .search-box__input {
      top: 0;
    }

    .dropdown.scrollable {
      overflow: scroll !important;
      max-height: 350px !important;
      z-index: 11;
    }
  }

  .top-bar-section {
    .left {
      width: 100% ;

      border-bottom: 1px solid $white;
      margin-bottom: 10px;

      > li {
        &:hover:not(.has-form) > a {
          background-color: transparent;
        }

        &:not(.has-form).has-dropdown > a {
          padding: .2rem 1.152rem .2rem 0;
          color: #4d4d4d;
        }


        &:not(.blank) .dropdown a {
          color: #4d4d4d;

          &:hover {
            background-color: rgba(77,77,77, .3) !important;
          }
        }

        .dropdown li:not(.has-form):not(.active):hover {
          background-color: #fff;
        }
      }


      .font-icon {
        font-size: 1rem;
        color: #4d4d4d;
      }
    }

    .right {
      width: 100%;
    }

    li:not(.has-form) a:not(.button) {
      line-height: 2rem;
      padding: .2rem 1.152rem;
    }
  }

  .search-box {
    padding: .2rem;
    background-color: #ffffff;

    width: 100%;

    .prefix {
      line-height: 2rem;
      height: 2rem;
    }

    .font-icon {
      color: #000000;
    }
  }

  .wizardbox {
    background: $white;

    > .header {
      padding-left: 0;
    }
  }

  .bigbox .header {
    font-size: 15px;
    background-color: rgba($primary, .1);
  }

  .to-top {
    position: fixed;
    bottom: 70px;
    right: 5px;

    width: 20px;
    height: 20px;

    padding-top: 2px;

    border-radius: 50%;
    border: 2px solid $primary;

    cursor: pointer;

    .font-icon {
      position: absolute;
      top: 50%;
      left: 50%;

      margin: 0;

      font-size: 10px;

      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }

  .breadcrumbs {
    margin: 1rem 0;

    >*::after {
      content: "|";
      margin: 0 .3rem;
    }

    >*:last-child::after {
      content: " ";
    }
  }

  p.small {
    font-size: .8rem;
    margin: .4rem 1rem 0 1rem;
    padding-bottom: 1rem;
  }

}

.article {
  position: relative;
  margin: 0 15px 15px 0;
  padding: 5px;
  cursor: pointer;

  h4,
  h6 {
    padding: 0 5px;
  }

  h4 {
    line-height: 1;
    overflow: hidden;
    height: 40px;
  }

  .price-wrapper {
    padding: 0 5px;

    p {
      margin-bottom: 0;
    }
  }

  .image-wrapper {
    position: relative;
    padding-top: 100%;
    margin-bottom: 5px;


    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: auto;
      display: block;

      &.used {
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        opacity: 1;
      }
    }
  }

  .button-wrap {
    position: relative;
    width: 95%;
    margin: 0 auto;
  }

  .icon-button {
    .font-icon {
      margin: 0;
      color: $white;
    }
  }
}

@media #{$small-only}, #{$xsmall-only} {
  .mpp-view {
    #sorting {
      margin-bottom: .6rem;
    }

    .article-details {
      line-height: 1.1;
    }

    .detail-price {
      margin-bottom: .8rem;
    }
  }
}

@media #{$medium-up} {
  .mpp-view {
    .top-bar-section {
      .left {
        > li:not(.has-form).has-dropdown > a {
          padding: .2rem 1.152rem
        }
      }
    }

    .to-top {
      right: auto;
      left: calc(50% + 20rem);

      width: 30px;
      height: 30px;

      .font-icon {
        font-size: 15px;
      }
    }
  }

  .article {
    h4 {
      height: 45px;
    }
  }
}

@media #{$large-up} {

  .mpp-view {
    .top-bar-section {
      .left {
        width: auto;
        border-bottom: 0;
        margin-bottom: 0;
      }

      .right {
        transition: width 1s ease-in-out;

        min-width: 200px;
        width: 200px;

        &.active {
          width: 40%;
        }
      }
    }
  }

}
