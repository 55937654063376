@import '../../scss/quill.snow';

.dropdown-with-search{
  width: 150px;
  .options {
    display: none;
  }
  
  .search-input:focus {
    + .options {
      display: block;
    }
  }
}

.cms-wrapper {
  margin-top: 2rem;

  .double-label-switch .switch {
    transform-origin: 0 0;
  }

  .update-info {
    text-align: right;
    font-size: .9em;
    margin-bottom: 0;
  }

  .tabs-content .content {
    overflow: visible;
  }
}

.background-white {
  background: white;
}

.cms-details {
  padding-top: 2rem;
  
  .button {
    margin-left: 1.5rem;
  }
}

.cms-list {
  padding: 2rem 0;
}

[ng-quill-editor] {
  display: block;
}

.back-link {
  display: block;
  margin-bottom: 1.75rem;

  &.bottom-back-link {
    margin-top: 1.75rem;
  }
}

.cms-article {
  margin-bottom: 1.75rem;
}

.quill-editor {
  border-radius: 0;
  background-color: $white;
  border: 1px solid $primary;
  box-shadow: none;
  color: #444;
  display: block;
  font-family: "Roboto", sans-serif;
  font-size: .93333rem;
  margin: 0 0 1.06667rem 0;
  width: 100%;
  box-sizing: border-box;
  transition: border-color .15s linear, background .15s linear;

  button {
    margin-bottom: 0;

    &.ql-active {
      background-color: lighten($primary, 10%);
    }
  }

  .quill-toolbar {
    display: block;
    border-bottom: 1px solid $primary;

    button {
      padding: .75rem 1rem;
    }

    svg {
      height: 1.25rem;

      .ql-stroke {
        fill: transparent;
        stroke: $white;
      }

      .ql-fill {
        fill: $white;
      }
    }
  }

  .ql-container {
    display: block;
    padding: .53333rem;

    input {
      display: none;
    }

    .ql-editor {
      &:focus {
        outline: none;
      }

      p {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .ql-clipboard {
      display: none;
    }
  }
}

.cms-wrapper {
  ._720kb-datepicker-calendar._720kb-datepicker-open {
    position: relative;
  }
}

.list-box .button {
  margin: 0 0 0 .33333rem;
}


