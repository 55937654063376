$containerHighlight: rgba(255, 255, 255, 0.7);
$containerHighlightAction: #fff;

.dropzone-container {
  border: 2px dashed $containerHighlight;
  padding: 15px;
  margin: 20px 0 30px 0;

  &.dragOver {
    border: 2px dashed $containerHighlightAction;

    .dropzone-inner-container {
      background-color: $containerHighlightAction;
    }
  }

  .dropzone-inner-container {
    background-color: $containerHighlight;
    text-align: center;
    padding: 20px;


    .drag-and-drop-label {
      min-height: 30px;

      .font-icon {
        color: $icon-color-light;
        font-size: 24px;
        margin-right: 15px;
      }

      span {
        line-height: 24px;
        font-size: 22px;
        vertical-align: middle;
      }
    }

    .divider {
      margin-top: 10px;
    }

    .file-input {

      min-height: 30px;

      .font-icon {
        color: $icon-color-light;
        font-size: 24px;
        margin-right: 15px;
      }

      label {
        color: #000;
        line-height: 24px;
        font-size: 22px;
        vertical-align: middle;
      }
    }

    .files {
      margin-top: 1rem;
    }
  }
}

.circletext {
  display: inline-block;

  round-progress {
    z-index: 9;
  }

  .circle {
    display: inline-block;
    height: 100px;
    width: 100px;
    overflow: hidden;
    border-radius: 65px;
    font-size: 20px;
    text-align: center;
    /* vertical-align: initial; */
    //line-height: 130px;

    line-height: 1;
    color: #fff;
    position: relative;
  }

  .circle-text {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;
    width: 100%;
    text-align: center;

    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  >span {
    display: block;
    text-align: center;
    color: #fff;
    width: 100%;
  }
}

.vn-table {
  width: 100%;

  table {
    border-spacing: 0px;
    width: 100%;

    tr+tr td {
      border-top: 1px solid $grey;
    }
    td:last-child {
      background: #f4f4f4;
      padding-left: 40px;
      width: 45%;
      text-align: right;
      padding-right: 20%;
    }

  }
}

.vn-table-70 {
  width: 100%;

  table {
    border-spacing: 0px;
    width: 100%;

    tr+tr td {
      border-top: 1px solid $grey;
    }

    td:first-child {
      width: 30%;
      text-align: left;
    }
    td:last-child {
      background: #f4f4f4;
      padding-left: 10px;
      text-align: left;
      padding-right: 10%;
    }

  }
}
.vn-field4 {
  margin-bottom: 20px;
}

.vn-baustein {
  padding: 0 2px;
  display: inline-block;

  .wrapper {
    background: #fff;
    padding: 20px;
    border-radius: 2px;
    overflow: hidden;
    text-align: center;
    height: 210px;

    &.inactive {
      background: rgba(#ffffff, 0.4)
    }

    .font-icon {
      font-size: 80px;
      height: 110px;
      display: inline-block;
    }

    .title {
      font-size: 10px;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 10px;
    }
  }
}

.vn-progressbar {
  .infoWrapper {
    background: #ebebeb;
    border-radius: 3px;
    overflow: hidden;
    width: 50px;
    text-align: center;
    display: inline-block;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }

  .progress {
    height: 20px;
    background: #ebebeb;

    .meter {
      background: $primary;
    }
  }
}

.slider_container {
  position: relative;


  span.desc {
    font-weight: 200;
  }

  .jslider {
    margin-top: 135px;
    margin-bottom: 25px;
  }

  .jslider-pointer {
    top: -8px;
    width: 20px !important;
    height: 20px;
    margin-left: -10px;
  }

  .jslider-label {
    top: -5px;
    left: -45px;
    opacity: inherit;

    &.jslider-label-to {
      right: -79px;
      left: auto;
    }

    span {
      font-size: 22px;
      color: $primary;
    }
  }

  .currency+.jslider .jslider-label span:after {
    content: ' €';
  }

  .jslider-value {
    // Sorry :( We just tried to overwrite the angular-awesome-slider plugin...
    margin-left: -27px !important;
    margin-right: -27px !important;
    background-color: transparent !important;

    span {
      background: #ffb500;
      line-height: 50px;
      width: 50px;
      height: 50px;
      overflow: hidden;
      border-radius: 50px;
      font-size: 22px;
      text-align: center;
      display: block;
      color: #fff;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    top: -75px !important;

    &:before {
      content: ' ';
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 25px 22px 0 22px;
      border-color: #ffb500 transparent transparent transparent;
      top: 38px;
      left: 5px;
    }
  }
}

.instantupdate {
  input {
    vertical-align: top;
    margin-bottom: 0;
    width: 120px;
    display: inline-block;
    margin-right: 10px;
  }
}

.cent_slider {
  .jslider-label.jslider-label-to  {
    right: -100px !important;
    left: auto !important;
  }
  //.jslider-label  {
  //  left: -75px !important;
  //}
}

.wizardbox {
  background: #eee;
  overflow: hidden;
  border-radius: 3px;
  @include shadow();
  padding: 15px;
  position: relative;
  margin-bottom: 15px;

  &.highlighted {
    background: #fff;
  }

  &.centered {
    position: relative;

    .mainWrapper {
      padding: 0 15%;
    }
  }

  .float-right {
    float: right;
  }

  .step1 {
    .textWrapper {
      float: right;
    }

    img {
      width: 65%;
      float: left;
      margin-right: 10px;
    }

    span {
      display: block;
    }

    .subtitle {
      font-size: 22px;
      line-height: 24px;
      padding: 15px 0;
    }
  }

  .step2 {
    @extend .slider_container;
  }

  .step3 {
    .button-wrapper {
      .right-button {
        float: right;
      }

      .left-button {
        float: left;
      }
    }
  }

  .header {
    font-size: 22px;
    padding-left: 50px;

    &.show {
      border-bottom: 1px solid $lightgrey2;
    }

    .step {
      width: 30px;
      height: 30px;
      position: absolute;
      top: 15px;
      left: 15px;
      line-height: 26px;
      font-size: 20px;
      font-weight: 200;
      text-align: center;
      display: block;
      border-radius: 50px;
      color: $secondary;
      border: 2px solid $secondary;
    }

    .title {
      color: $primary;
      font-weight: 200;
    }
  }

  .main {
    padding: 20px 0;
  }

  @media #{$screen} and (max-width:#{upper-bound($medium-range)}) {
    iframe {
      max-width: 140%;
      margin: 0 -20%;
    }
  }
}

#main-view .titlerow {
  margin-bottom: 15px;
}

.bigbox {
  span.openable {
    &:before {
      content: "\e6ce";
      float: right;
      font-family: 'entypo';
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      -webkit-font-smoothing: antialiased;

      font-size: 1.4em;
      line-height: 1.5;
    }

    &.selected:before {
      content: "\e6cf";
    }
  }
}

.schema-form-array {
  button.close.pull-right {
    float: right;
    margin-top: 20px;
  }

  li sf-decorator {
    display: inline-block;
    margin-right: 10px;
  }

  @media #{$xsmall-only},
  #{$small-only} {
    li sf-decorator {
      width: 90%;
    }
  }

  ol {
    list-style: none;
  }
}

.video-wrap {
  height: 360px;
}

@media #{$xsmall-only}{
  .video-wrap {
      height: 190px;
  }
}

@media #{$xsmall-only},
#{$small-only} {
  .dropzone-container {
    .dropzone-inner-container {
      .drag-and-drop-label {
        span {
          display: block;
          width: 100%;
          line-height: 1.1;
          margin-top: .5rem;
        }
      }
    }
  }

  .wizardbox {
    &.highlighted {
      .header {
        padding-bottom: .5rem;
      }
    }

    &.centered {
      .mainWrapper {
        padding: 0 5%;

        .video-wrap {
          width: 110%;
          margin-left: -5%;
        }
      }
    }

    .header {
      font-size: 19px;
    }

    .step2 .jslider {
      margin-bottom: 45px;

      .jslider-label {
        top: 20px;
        left: 0;

        &.jslider-label-to {
          right: 0;
          left: auto;
        }
      }
    }
  }
}

@media #{$medium-up} {
  .circletext {
    .circle {
      width: 130px;
      height: 130px;
      font-size: 30px;
    }
  }

  .bigbox {
    span.openable {
      &:before {
        font-size: 2em;
        line-height: 1;
      }
    }
  }
}
