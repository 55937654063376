#meineakte-steuerfrei {
  .meineakte--component {
    width: 14rem;
    height: 12rem;
    background-color: #fff;
    margin-bottom: 1rem;
    margin-right: 1rem;
    padding-top: 1rem;
    text-align: center;
    float: left;

    &.inactive {
      opacity: .8;
    }

    i{
      font-size: 4rem;
    }

    .title {
      text-align: center;
      overflow-x: hidden;
      padding-left: 5px;
      padding-right: 5px;
      height: 1.5rem;
      width: 100%;
      text-overflow: ellipsis;
    }

    p{
      font-size: small;
      margin-top: 1rem;
    }

  }
  .gehaltsdaten {
    @media #{$xsmall-only}, #{$small-only} {
      @include mobile-table;
    }

    .currency {
      text-align: right;
      padding-right: 50%;
      display: block;
      margin-right: rem-calc(-10);
    }

    input {
      text-align: right;

      &.button {
        // input height src from foundation
        height: ($input-font-size + ($form-spacing * 1.5) - rem-calc(1));
        line-height: 0;
      }
    }

    @media #{$xsmall-only}, #{$small-only} {
      .form-group {
        text-align: right;

        input {
          width: 50%;
          display: inline-block;
          margin-right: rem-calc(3);

          &.button {
            width: 40%;
            margin-right: 0;
          }
        }
      }

      table {
        td {
          &:last-child {
            padding-left: 0;
            width: 100%;
          }
        }
      }
    }
  }
}
