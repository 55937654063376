.landing-header {
  z-index: 10;

  .content-wrapper {
    max-width: rem-calc(1170);
    margin: 0 auto;
  }

  .top-bar {
    padding: 0;
    margin: 0 !important;

    .font-icon {
      font-size: rem-calc(20);
    }
  }

  .left-small {
    position: static;
    margin-right: rem-calc(-15);
  }

  a.logo {
    padding-left: 0;
  }

  .login {
    border: rem-calc(2) solid #fff;
    border-radius: rem-calc(3);
    padding: rem-calc(6) rem-calc(18);
  }
}

/**
 * Needed to the sticky sidebar menu
 */
.main-section {
  height:100vh;
  overflow-y:scroll;
  -webkit-overflow-scrolling: touch;
  .imprint {
    max-width: 800px;
    margin-top: 25px;
    margin-left: auto;
    margin-right: auto;
  }
}
.landing {

  .white,
  .white .font-icon {
    color: #fff;
  }

  h2, h3, h4 {
    color: $primary;
    font-weight: bold;
  }

  .button.secondary {
    background-color: #FFCC01;
    color: #000;
  }
  .relative {
    position: relative;
  }

  .uc-embedding-container {

    .uc-embedding-wrapper {
      min-height: calc(100% - 16px);
    }
  }

  @media #{$medium-up} {

    .height-control {
      height: 100%;
      position: absolute;
      right: 0px;
      top: 0px;
      bottom: 0px;
      padding-bottom: 4rem;
      .panel {
        height: 100%;
      }
    }
  }

  .panel {
    border: 0;

    &.callout {
      @include panel($primary);
      border: 0;
    }

    &.register {
      @include panel(rgba(lighten($primary, 5%), .75));
      border: 0;
      margin: 0 0 rem-calc(60);
      padding: 3rem 2rem;
      h3 {
        margin-bottom: 1.5rem;
      }
    }

    &.no-margin {
      margin: 0;
    }

    $caret-size: 12px;

    &.point {
      position: relative;

      &:before {
        content: '';
        width: 0;
        height: 0;
        display: none;
        border: rem-calc($caret-size) solid #f2f2f2;
        position: absolute;
      }

      &.callout:before {
        border-color: $primary;
      }

      &.toLeft:before,
      &.toRight:before {
        top: 50%;
        margin-top: rem-calc(-$caret-size);
      }

      &.toLeft:before {
        left: rem-calc(($caret-size*-2)+1);
        border-left-color: transparent;
        border-top-color: transparent;
        border-bottom-color: transparent;
      }

      &.toRight:before {
        right: rem-calc(($caret-size*-2)+1);
        border-right-color: transparent;
        border-top-color: transparent;
        border-bottom-color: transparent;
      }

      &.toDown:before {
        left: 50%;
        bottom: rem-calc(($caret-size*-2)+1);
        margin-left: rem-calc(-$caret-size);
        border-left-color: transparent;
        border-right-color: transparent;
        border-bottom-color: transparent;
      }

      &.toDown.special:before {
        left: 32%;
      }
    }

    h3 {
      font-weight: 400;
      font-size: 2rem;
      margin-top: rem-calc(20);
    }

    p {
      line-height: rem-calc(24);
      font-size: 1.2rem;

      &.small-attention {
        line-height: rem-calc(19);
        font-size: .8rem;
      }
    }
  }

  .register {

    fieldset {
      margin: 0;
      padding: 0;
      border-radius: rem-calc(5);
      overflow: hidden;
    }

    .columns {
      position: static;
    }

    .centered {
      text-align: center;
      margin-top: 2rem;

      p {
        text-align: left;
        margin-bottom: 0;
      }
    }

    input[type="text"],
    input[type="email"] {
      margin: 0;
      border: 0;

      border-radius: 0 !important;
      box-shadow: 0 0 0 rem-calc(1) rgba($primary, .2);

      &.ng-invalid.ng-dirty {
        border-bottom: 2px solid $red;
      }
    }

    input[type="submit"] {
      margin: rem-calc(15) 0 0;
    }
  }

  .stretch-content {
    display: table;
    width: 100%;

    & > * {
      display: table-cell;
      vertical-align: top;
      @include clearfix();
    }
  }

  .section-title {
    margin: rem-calc(80) 0 rem-calc(40);
    font-size: rem-calc(21);
    text-align: center;
  }

  .section-subtitle {
    text-align: center;
  }

  .section-wrapper {

    &:first-child {
      margin-top: rem-calc(-64);
      padding-top: rem-calc(64)*2;
      background: url(/assets/img/landing/bg-landingpage.jpg) no-repeat center center;
      background-size: cover;
    }

    &:nth-child(2) {
      background-color: #B1CBC5;
      background: -webkit-linear-gradient(#B1CBC5, #C4D2C6);
      background: linear-gradient(#B1CBC5, #C4D2C6);
      padding-bottom: rem-calc(50);
    }

    &:nth-child(3) {
      background-color: #D2DDD4;
      background: linear-gradient(#D2DDD4, #DADED1);
    }

    &:nth-child(4) {
      padding-top: rem-calc(60);
      background: url(/assets/img/landing/landing-asset8.jpg) no-repeat center center;
      background-size: cover;
      position: relative;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 0;
        background: url(/assets/img/landing/mask.png) repeat;
        opacity: .5;
      }
    }

    & > .row {
      max-width: rem-calc(1170);
      margin: 0 auto;
    }
  }

  .video-container {
    margin: 0 auto rem-calc(60);
    padding-left: 6rem;

    .video {
      position: relative;
      padding-bottom: 280px;
      height: 0;
      overflow: hidden;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }

  .product-used-by {
    width: 100%;
    overflow: hidden;
    height: rem-calc(60);
    background: $primary;

    .row {
          /* height: 100%; */
    /* max-width: 78rem; */
    /* min-width: 78rem; */
    // white-space: nowrap;
    /* display: table; */
    // vertical-align: middle;
    // white-space: nowrap;
    overflow-x: auto;
      // height: 100%;
      max-width: rem-calc(1170);
      // min-width: rem-calc(1170);
      white-space: nowrap;
      // display: table;
      vertical-align: middle;
    }

    .plogo {
      opacity: .5;
      display: table-cell;
      vertical-align: middle;

      img {
        max-height: 35%;
        margin-right: rem-calc(60);
      }
    }

    .pplogo {
      display: inline-block;
      width: rem-calc(120);
      height: rem-calc(36);
      margin: rem-calc(12) 1%;
      background: center 0 no-repeat;
      text-indent: -100%;
      overflow: hidden;
      // opacity: .6;

      &:nth-child(3),
      &:nth-child(5) {
        width: rem-calc(200);
      }

      // &:hover {
      //   background-position: center -36px;
      // }

      // &:first-child:hover {
      //   background-position: center -51px;
      // }

    }
  }

  .service-list {
    list-style: none;
    font-size: rem-calc(13);
    margin: 0 0 rem-calc(80);

    h5 {
      font-size: rem-calc(13);
      margin: 0 0 rem-calc(10);
      color: $primary
    }

    p {
      font-size: rem-calc(12);
      line-height: rem-calc(18);
      margin-bottom: 0;
    }

    .list-item {
      position: relative;
      padding: rem-calc(7) rem-calc(7) rem-calc(7) rem-calc(83);
      border-radius: rem-calc(3);

      &:hover {
        background-color: #F4F6F1;
      }
    }

    .icon {
      position: absolute;
      top: rem-calc(15);
      left: rem-calc(15);

      width: rem-calc(53);
      height: rem-calc(53);
    }
  }

  .pricing-table {
    width: 80%;
    overflow: hidden;
    border-radius: rem-calc(3);
    border: 0;
    margin: 0 auto rem-calc(60);
    @include radius();

    .title {
      font-size: rem-calc(24);
    }

    .description{
      font-size: rem-calc(17);
    }

    small {
      display: block;
      font-size: rem-calc(12);
      margin-top: rem-calc(10);
    }

    .price {
      padding-top: rem-calc(50);
      font-size: rem-calc(50);
      font-weight: 600;
    }

    .title {
      min-height: rem-calc(78);
    }

    .currency {
      font-size: rem-calc(33);
      margin-left: rem-calc(8);
    }

    & > * {
      border-bottom: 0;
      background: $primary;
      color: #fff;
    }
  }

  .landing-footer {
    padding: 2rem 0;
    // background-color: #A7B7B2;

    a {
      color: $anchor-font-color;
    }

    img {
      max-width: rem-calc(150);
    }

    p.copyright {
      margin-bottom: 0;
    }

    .content-wrapper {
      max-width: rem-calc(1170);
      margin: 0 auto;
    }

    .footer-links {
      list-style: none;
      margin: rem-calc(15) 0 0;
    }

    .font-icon {
      color: $icon-color-light;
      font-size: 1.2rem;
      display: inline-block;
      vertical-align: middle;
    }
  }

  .tabbable {
    .tabs {
      padding-bottom: 0;

      .bigtab a {
        font-size: 1.5rem;
        padding: 1.0rem 2.2rem;
      }

      dd + dd {
        margin-left: rem-calc(2);
      }
    }

    .tabs-content {
      margin-top: rem-calc(2);

      .content {
        overflow: auto;
      }
    }
  }

  .tabpage-header {
    padding: rem-calc(10);

    .button {
      margin: 0;
    }
  }

  .button.bordered {
    border: rem-calc(1) solid $link-color;
    border-radius: 0.2rem;
    color: $link-color;
    background-color: transparent;
    float: right;

    &:hover {background-color: transparent;}
  }

  @media #{$medium-up} {

    .panel.point:before {
      display: block;
    }
  }

  @media #{$xsmall-only}, #{$small-only} {

    .section-wrapper {
      padding-left: rem-calc(15);
      padding-right: rem-calc(15);
    }

    .video-container {
      padding: 0;
    }

    .stretch-content > * {
      display: block;
    }

    .product-used-by {
      box-sizing: content-box;
      margin: 0 rem-calc(-15);
      padding: 0 rem-calc(15);
    }

    .tabbable {
      .tabs {
        .bigtab a {
          font-size: 1.25rem;
        }
      }
    }
  }
}

.landing-overlay {
  transition: opacity .3s ease-in-out;
  opacity: 1;
  z-index: 100;
  padding: rem-calc(120) 0 0;

  &.overlay {
    position: fixed;
    overflow: auto;
    background-color: rgba(#000,0);
  }

  &.hidden {
    opacity: 0;
    z-index: -1;
  }

  .panel.callout {
    @include panel(rgba(lighten($primary, 5%), .95));
    border: rem-calc(1) solid rgba(#fff,.2);
    padding: rem-calc(80) rem-calc(110);
    margin: 0;
  }

  .icon-close {
    color: #fff;
    font-size: 2.4rem;
    display: inline-block;
    position: relative;
    top: rem-calc(-50);
    right: rem-calc(-80);
    cursor: pointer;
  }

  .pagination {
    font-weight: 500;
    margin: rem-calc(40) auto 0;
    text-align: center;

    li {
      float: none;
      display: inline-block;
      margin-bottom: 0;

      a {
        width: rem-calc(35);
        line-height: rem-calc(30);
        color: #fff;
        font-size: 1.2rem;
        border-radius: 100px;
        border: rem-calc(2) solid transparent;
        padding: 0;

        &:hover,
        &.active {
          color: #fff;
          border-color: #fff;
          background-color: transparent;
        }
      }
    }
  }

  .prev,
  .next {
    position: absolute;
    top: 50%;
    font-size: 1.5rem;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);

    .font-icon {

      &,
      &:hover {
        color: #fff;
      }
    }
  }

  .prev {
    left: rem-calc(40);
  }

  .next {
    right: rem-calc(40);
    }

  h3 {
    font-size: 1.8rem;
    font-weight: 400;
  }

  p {
    font-size: 1.3rem;
    font-weight: 400;
  }

  .no-list-item {
    list-style-type: none;
    margin-left: 0px;
  }

  li {
    font-size: 1.1rem;
    line-height: 1.4rem;
    margin-bottom: rem-calc(15);
  }

  @media #{$xsmall-only}, #{$small-only} {
    // TODO: needs scroll fix inside of the overlay!
    top: 0;
    left: 0;
    padding: 0;

    .panel.callout {
      padding: rem-calc(40) rem-calc(55);
    }

    .icon-close {
      top: rem-calc(-25);
      right: rem-calc(-40);
    }

    .prev,
    .next {
      position: fixed;
    }

    .prev {
      left: rem-calc(15);
    }

    .next {
      right: rem-calc(15);
    }
  }
}

.landing-logo {
  height: rem-calc(20);
}

@media #{$xsmall-only}, #{$small-only} {
  .landing {
    .panel {
      &.register {
        .white {
          display: block;
          width: 100%;
          text-align: left;
          margin-bottom: .5rem;
        }
      }
    }
  }
}

.toc {
  h2 {
    font-size: 1.4rem;
    margin-bottom: .8rem;
  }
  h3 {
    margin-bottom: 1rem;
  }
}
