.cashback-details {
  @media #{$medium-up} {
    .video-container {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 100%;
      width: 100%;
    }
  }

  .textarea-container textarea {
    height: 100px;
  }

  .text-row {
    margin-bottom: 15px;
  }

  .text-row-bold {
    margin-bottom: 15px;

    label {
      font-weight: bold;
    }
  }

  .small-text label {
    font-size: 12px;
    color: #A4ACB1;
  }

  .checkbox label span {
    float: right;
    display: block;
    width: calc(100% - 40px);
  }

  .button-container {
    margin-top: 40px;
    text-align: center;
    border-top: 1px solid $lightgrey;
    padding-top: 20px;

    input {
      -webkit-appearance: none;
      -moz-appearance: none;
      border-radius: 0;
      border-style: solid;
      border-width: 0;
      cursor: pointer;
      font-family: $font-family-sans-serif;
      font-weight: 300;
      line-height: normal;
      margin: 0 0 0.73333rem;
      position: relative;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      padding: 0.73333rem 1.46667rem 0.8rem 1.46667rem;
      font-size: 1rem;
      background-color: $primary;
      border-color: #002f44;
      color: #FFFFFF;
      transition: background-color 300ms ease-out;
    }
  }

  .abbinder {
    padding: 1.25rem;

    h3 {
      font-size: 1.1rem;
    }

    p {
      font-size: .9rem;
    }

    img {
      max-width: 90%;
      margin: 0 5%;

      @media #{$small-up} {
        margin: 0 0 0 10%;
      }
    }
  }
}
