#auswahl {
  .pagetitle {
    color: $primary
  }
}

.grayscale {

  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);

}

.bausteine-quiz {
  -webkit-transition: transform .3s ease-in-out;
  transition: transform .3s ease-in-out;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  position: static;

  &.hidden {
    -webkit-transform: translate3d(-105%, 0, 0);
    transform: translate3d(-105%, 0, 0);
    position: absolute;
    left: -100%;
  }
}

.question {

  &__title {
    margin: rem-calc(60) 0;
  }

  &__skip {
    margin: rem-calc(60) 0 rem-calc(10);

    .font-icon {
      color: $icon-color-dark;
      margin-left: rem-calc(5);
      vertical-align: middle;
      line-height: 0;
    }
  }
}

.mini-nav {
  list-style: none;
  padding: 0;
  margin: 0;

  &__item {
    width: rem-calc(10);
    height: rem-calc(10);
    margin-right: rem-calc(5);
    display: inline-block;
    background-color: $link-color;

    &:not(.active) {
      cursor: pointer;
      background-color: rgba($link-color, .5);
    }
  }
}