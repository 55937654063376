button:hover,
button:focus {
  &.mfp-close {
    background-color: transparent;
  }
}

.mfp-full-screen {
  .mfp-content,
  .mfp-iframe-scaler {
    padding-top: 0;
    height: 100%;
  }
}

.ngdialog.magnific-theme-vsp {
  padding-bottom: 10%;
  padding-top: 10%;
}

.ngdialog.magnific-theme-vsp .ngdialog-content {
  background: transparent;
  color: #444;
  font-family: 'Helvetica Neue',sans-serif;
  font-size: 1.1em;
  line-height: 1.5em;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 30px;
  max-width: 940px;
}

.ngdialog.magnific-theme-vsp .ngdialog-content h1,
.ngdialog.magnific-theme-vsp .ngdialog-content h2,
.ngdialog.magnific-theme-vsp .ngdialog-content h3,
.ngdialog.magnific-theme-vsp .ngdialog-content h4,
.ngdialog.magnific-theme-vsp .ngdialog-content h5,
.ngdialog.magnific-theme-vsp .ngdialog-content h6,
.ngdialog.magnific-theme-vsp .ngdialog-content p,
.ngdialog.magnific-theme-vsp .ngdialog-content ul,
.ngdialog.magnific-theme-vsp .ngdialog-content li {
  color: inherit;
}

.ngdialog.magnific-theme-vsp .ngdialog-close {
  padding: 0 10px;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
}

.ngdialog.magnific-theme-vsp .ngdialog-close:before {
  color: #eee;
  content: "\00D7";
  background: transparent;
  font-size: 26px;
}

.ngdialog.magnific-theme-vsp .ngdialog-close:hover:before,
.ngdialog.magnific-theme-vsp .ngdialog-close:active:before {
  color: #aaa;
}

.ngdialog.magnific-theme-vsp .ngdialog-message {
  margin-bottom: .5em;
}

.ngdialog.magnific-theme-vsp .ngdialog-input {
  margin-bottom: 1em;
}

.ngdialog.magnific-theme-vsp .ngdialog-input textarea,
.ngdialog.magnific-theme-vsp .ngdialog-input input[type="text"],
.ngdialog.magnific-theme-vsp .ngdialog-input input[type="password"],
.ngdialog.magnific-theme-vsp .ngdialog-input input[type="email"],
.ngdialog.magnific-theme-vsp .ngdialog-input input[type="url"] {
  background: #f0f0f0;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  margin: 0 0 .25em;
  min-height: 2.5em;
  padding: .25em .67em;
  width: 100%;
}

.ngdialog.magnific-theme-vsp .ngdialog-input textarea:focus,
.ngdialog.magnific-theme-vsp .ngdialog-input input[type="text"]:focus,
.ngdialog.magnific-theme-vsp .ngdialog-input input[type="password"]:focus,
.ngdialog.magnific-theme-vsp .ngdialog-input input[type="email"]:focus,
.ngdialog.magnific-theme-vsp .ngdialog-input input[type="url"]:focus {
  box-shadow: inset 0 0 0 2px rgba(0,0,0,0.2);
  outline: none;
}

.ngdialog.magnific-theme-vsp .ngdialog-buttons:after {
  clear: both;
  content: '';
  display: table;
}

.ngdialog.magnific-theme-vsp .ngdialog-button {
  border: 0;
  cursor: pointer;
  float: right;
  font-family: inherit;
  font-size: .8em;
  letter-spacing: .1em;
  line-height: 1em;
  margin: 0 0 0 .5em;
  padding: .75em 2em;
  text-transform: uppercase;
}

.ngdialog.magnific-theme-vsp .ngdialog-button:focus {
  -webkit-animation: ngdialog-pulse 1.1s infinite;
  animation: ngdialog-pulse 1.1s infinite;
  outline: none;
}

@media (max-width: 568px) {
  .ngdialog.magnific-theme-vsp .ngdialog-button:focus {
    -webkit-animation: none;
    animation: none;
  }
}

.ngdialog.magnific-theme-vsp .ngdialog-button.ngdialog-button-primary {
  background: #3288e6;
  color: #fff;
}

.ngdialog.magnific-theme-vsp .ngdialog-button.ngdialog-button-secondary {
  background: #e0e0e0;
  color: #777;
}
